import React from 'react';

const Blurb2_1B = () =>
  <div>
    <p>
      Physical health is fundamental to daily living. Awareness and support that promotes physical health is
      a first step towards healthy outcomes across the lifespan.
    </p>
    <p>
      Childhood is an important period for physical and cognitive development. Ongoing monitoring of your
      child&apos;s physical health, including their hearing, vision, mobility, and diet, is important
      to your child’s overall wellbeing. Children with FASD may experience more physical health needs
      at earlier ages than might typically be expected – this means that continuing to look out for
      even unexpected needs is important. As your child continues to grow and develop, it will also
      be important they be provided with developmentally appropriate information on sexual health
      education, pregnancy, and healthy relationships.
    </p>
  </div>;

export default Blurb2_1B;
