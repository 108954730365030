import React from 'react';

const Blurb3_3D = () =>
  <div>
    <p>
      You have reported experiencing one of more disrupted attachment experiences. For individuals with FASD
      attachment bonds may be formed in unexpected ways. When people make sense of or interact in the world
      differently due to brain-based differences, they may not always detect interpersonal cues as expected.
      For this reason, it can be very helpful to have support learning to identify your cues and ways to respond
      that make sense for you. To help with this, accessing support that focuses on your relationship(s) with
      the central people in your life, like a partner, family member, or friend, can improve your sense of
      safety in relationships and your social environment. You may choose to explore therapy that helps build
      healthy relationships in adulthood. Even in circumstances where attachment is disrupted, it is possible
      to re-establish secure and strong relationships at all developmental periods. Intervention is important
      for moving towards healthy attachment and relationships throughout your adult life.
    </p>
  </div>;

export default Blurb3_3D;
