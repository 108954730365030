import React from 'react';

const Blurb2_5C = () =>
  <div>
    <p>
      Sensory abilities include the five senses of smell, sight, taste, touch, and sound. Our senses help
      us regulate and respond to sensory information, like pain, temperature, and textures. Sometimes
      individuals with FASD experience sensory input differently due brain-based differences. Lights
      may be too bright, sounds too loud, and textures unpleasant. They may not experience pain in
      the same ways – sometimes it may hurt more than expected and other times not as much as expected.
      This can even create safety concerns – especially in cold weather. With targeted intervention
      from clinicians, such as occupational therapists and physical therapists, sensory development
      and functioning can be supported, safety planning can be considered, and improvement is possible.
      Awareness of motor and sensory needs can also help us to increase understanding in your teen’s
      network of support and identify environmental strategies and accommodations to facilitate reduced
      stress and increase access to strengths.
    </p>
  </div>;

export default Blurb2_5C;
