import React from 'react';

const Blurb7_2D = () =>
  <div>
    <p>
      Individuals with FASD may experience variable skills, with challenges potentially including difficulties
      setting healthy personal boundaries, reading social cues, completing activities of daily living, and both
      home and community safety concerns. Supportive adults can assist in developing adaptive skills that are
      important in daily life and can help to support interdependence throughout the lifespan.
    </p>
  </div>;

export default Blurb7_2D;
