import React from 'react';

const Blurb6_4E = () =>
  <div>
    <p>
      Identity is an important ingredient for supporting individual autonomy and well-being across the lifespan.
      You/your child having a strong sense of identity, as it makes sense for you/them can create opportunities
      to support strengths throughout the lifespan. For children, encouraging their identity development early
      on can foster ongoing growth that will provide valuable support to address areas of need.
    </p>
  </div>;

export default Blurb6_4E;
