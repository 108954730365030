import React from 'react';

const Blurb4_4E = () =>
  <div>
    <p>
      It will be important to continue to support your/your child’s social skills across relationships and
      social settings. Planning ahead and considering supported opportunities for them to build their skills
      is encouraged. Identifying other domains such as community engagement where you/your child can practice
      these skills and foster related abilities will be important to developing and building on strengths.
    </p>
  </div>;

export default Blurb4_4E;
