import React from 'react';

const Blurb7_1D = () =>
  <div>
    <p>
      As individuals grow and transition throughout the lifespan, new skills are required to meet growing
      needs for personal autonomy and choice. Individuals with FASD demonstrate a considerable range of
      abilities and interpersonal qualities that inform the types of interpersonal supports they require.
      This balance between autonomy, interpersonal and structural support is often referred to as interdependence,
      which can look different for every individual.
    </p>
    <p>
      Community engagement and adaptive functioning are two central components of interdependence. The desire
      to belong to a community is a natural part of life; having a sense of belonging is a positive influence
      on well-being. The joy of community integration refers to a sense of physical and psychological belonging
      to a community and involves having a social network, access to medical and social services, and autonomy.
      Adaptive skills are practical, everyday skills that are based on developmental, social, and cultural norms.
      Examples of adaptive skills include self-care, personal hygiene, money management, and social functioning.
      Everyone experiences their own range of adaptive functioning strengths and needs, and your adaptive skills
      may be stronger in some areas than others.
    </p>
  </div>;

export default Blurb7_1D;
