import React from 'react';

const Blurb5_6D = () =>
  <div>
    Education and learning, or academics, were identified as an area of difficulty for you. When academics
    are challenging, you may experience difficulties with problem solving, complex ideas, reasoning,
    language delays, academic achievement, and memory. With appropriate supports in your work or learning
    environments are put in place – you too can improve your ability to complete education and learning in
    a meaningful way that makes sense for you.
  </div>;

export default Blurb5_6D;
