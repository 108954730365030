import React from 'react';

const Blurb4_4C = () =>
  <div>
    <p>
      It will be important to continue to support your teen&apos;s social skills, particularly as they begin to
      transition to adulthood and post-secondary education. Identifying other domains of healthy functioning
      such as community engagement where your teen can practice these skills and foster related abilities will
      be important to developing and building on strengths.
    </p>
  </div>;

export default Blurb4_4C;
