import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import '../../../../assets/stylesheets/pages/report.scss';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Variables from '../components/Variables';
import Blurb11_1A from '../../../blurbs/11_ParentingSkills/Blurb11_1A';
import Blurb11_1B from '../../../blurbs/11_ParentingSkills/Blurb11_1B';
import Blurb11_1C from '../../../blurbs/11_ParentingSkills/Blurb11_1C';
import Blurb11_1D from '../../../blurbs/11_ParentingSkills/Blurb11_1D';
import Blurb11_1E from '../../../blurbs/11_ParentingSkills/Blurb11_1E';
import Blurb11_2A from '../../../blurbs/11_ParentingSkills/Blurb11_2A';
import Blurb11_2B from '../../../blurbs/11_ParentingSkills/Blurb11_2B';
import Blurb11_2C from '../../../blurbs/11_ParentingSkills/Blurb11_2C';
import Blurb11_2D from '../../../blurbs/11_ParentingSkills/Blurb11_2D';
import Blurb11_2E from '../../../blurbs/11_ParentingSkills/Blurb11_2E';
import Blurb11_3A from '../../../blurbs/11_ParentingSkills/Blurb11_3A';
import Blurb11_3B from '../../../blurbs/11_ParentingSkills/Blurb11_3B';
import Blurb11_3C from '../../../blurbs/11_ParentingSkills/Blurb11_3C';
import Blurb11_3D from '../../../blurbs/11_ParentingSkills/Blurb11_3D';
import Blurb11_3E from '../../../blurbs/11_ParentingSkills/Blurb11_3E';

const ParentingSkills = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {employment} = useSelector(state => state.forms[FORMS.EXPERIENCE.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const variables = {diagnosis, employment, age};

  return <section>
    <SectionTitle>Parenting Skills</SectionTitle>
    <Variables variableObject={variables}/>
    <Card
      title='Things To Know'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_1A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_1B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_1C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_1D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_1E/>}
      </div>
    </Card>
    <Card
      title='Things To Do Now'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_2A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_2B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_2C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_2D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_2E/>}
      </div>
    </Card>
    <Card
      title='Things To Plan For'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_3A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_3B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_3C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_3D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb11_3E/>}
      </div>
    </Card>
  </section>;
};

export default ParentingSkills;
