import React from 'react';
import ROUTES from '../../../routes';
import {generatePath, Link} from 'react-router-dom';
import FORMS from '../../../redux/constants';
import Compass from '../../../assets/Images/Compass.png';
import CompassLogo from '../../../assets/Images/Compass-logo.png';
import MetaHelmet from '../../components/MetaHelmet';
import instructions from '../../../locales/instructions';

const Home = () => <>
  <MetaHelmet
    title='CanFASD: COMPASS Report Generator'/>
  <div className='text-center'>
    <div className='margin-bottom-2'>
      <img
        alt='Compass Logo'
        className='align-center'
        src={CompassLogo}/>
    </div>

    <div className='grid-x align-center margin-bottom-2'>
      <div className='small-6 medium-2 cell'>
        <img
          alt='Compass Logo'
          className='align-center'
          src={Compass}/>
      </div>
    </div>

    <p className='lead font-bold margin-bottom-2'>COMPASS Report Generator</p>
    <p>
      {instructions.welcome1}
    </p>
    <p>
      {instructions.welcome2}
    </p>
    <div className='grid-x align-center'>
      <Link
        className='cell medium-2 large button success'
        to={generatePath(ROUTES.FORMS, {url: FORMS.UNIQUE_BRAIN.url})}>
          Start
      </Link>
    </div>
  </div></>;

export default Home;
