import React from 'react';

const Blurb5_6A = () =>
  <div>
    <p>
      Academics were identified as an area of difficulty for your child. When academic areas are impacted,
      your child may experience difficulties with problem solving, complex ideas, reasoning, language, academic
      achievement, and difficulty with memory. In other words, skills important to learning and responding to
      challenges within educational (and other) settings. Understanding the unique areas of difficulty present
      for your child is the first step. From there working collaboratively with educators and other systems of
      support may facilitate implementation of well suited environmental supports as well as exploration of
      intervention. In this way your child may be supported in functioning at their best while simultaneously
      being supporting in growing some of their skills – within reasonable expectations for them.
    </p>
  </div>;

export default Blurb5_6A;
