import React from 'react';

const Blurb5_13C = () =>
  <div>
    <p>
      Your teen has been referred for specialized support related to speech and language development.
      Speech language pathologists work with a variety of clients and can help with many areas of speech
      and language including speech and language delays, fluency disorders, cognitive communication, voice,
      swallowing, fluency, and other related disorders. It will be important for your child to work with a
      speech and language specialist who is FASD-informed.
    </p>
  </div>;

export default Blurb5_13C;
