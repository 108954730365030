import React from 'react';
import FORMS from '../../redux/constants';
import PropTypes from 'prop-types';
import instructions from '../../locales/instructions';

const TearoffTextBox = ({handleFormChange, formData, tearoffType}) =>
  <div className='grid-x grid-margin-x align-middle padding-bottom-1'>
    <div className='cell'>
      <label
        className='form-field'
        htmlFor={tearoffType}>{FORMS.TEAROFFS.fields[tearoffType].title}</label>
      <p>
        {instructions[tearoffType]}
      </p>
    </div>
    <div className='cell'>
      <textarea
        className='margin-bottom-0'
        id={tearoffType}
        maxLength='3000'
        onChange={event => handleFormChange({...formData, [tearoffType]: event.target.value})}
        rows='2'
        value={formData[tearoffType]}/>
    </div>
  </div>;

export default TearoffTextBox;

TearoffTextBox.propTypes = {
  formData: PropTypes.object.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  tearoffType: PropTypes.string.isRequired
};
