import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const OtherDiagnosisForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);

  const dispatch = useDispatch();
  const otherDiagnosis = FORMS.OTHER_DIAGNOSIS.fields;
  const fieldOptions = FORMS.OTHER_DIAGNOSIS.options;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] !== '');
  const isFormComplete = Object.keys(otherDiagnosis).filter(key => key !== 'otherdiagnosis').every(field => checkedFields.includes(field)) &&
    (formData.otherdiagnosis && (formData.otherdiagnosis === '0' || formData.otherdiagnosis === '1')
      ? formData.otherdiagnosis_specify.length > 0 : true);

  const handleFormChange = updatedFormData => {
    if (formData.otherdiagnosis !== updatedFormData.otherdiagnosis) {
      updatedFormData[FORMS.OTHER_DIAGNOSIS.fields.otherdiagnosis.specify_other.id] = FORMS.OTHER_DIAGNOSIS.fields.otherdiagnosis.specify_other.value;
    }

    dispatch(submitForm(FORMS.OTHER_DIAGNOSIS.id, updatedFormData));
  };

  return (
    <>
      <h2 className='form-header'>{FORMS.OTHER_DIAGNOSIS.header}</h2>
      <div className='form-content'>
        <div className='lead group-name'>Other diagnosis</div>
        <form>
          <label className='form-field padding-bottom-2 note'>Note: Assessment did not have to occur at this clinic</label>
          <div className='grid-x grid-margin-x show-for-medium'>
            <div className='small-12 medium-4 large-3 cell'/>
            {
              Object.values(fieldOptions).map(fieldOption =>
                <div
                  className='auto cell text-center'
                  key={fieldOption}>
                  <label className='form-field'>{fieldOption}</label>
                </div>)
            }
          </div>

          <div className='padding-bottom-1'>
            {Object.keys(otherDiagnosis).map(diagnosisKey =>
              <div
                className='grid-x grid-margin-x align-middle padding-bottom-1'
                key={diagnosisKey} >
                <div className='small-12 medium-4 large-3 cell'>
                  <label className='form-field'>{otherDiagnosis[diagnosisKey].title}</label>
                </div>
                <div className='cell medium-8 large-9 align-self-stretch'>
                  <div className='form-field-value grid-x small-up-2 medium-up-3 padding-bottom-1 height-100 align-middle round-bottom-borders round-top-borders'>
                    {Object.keys(fieldOptions).map(optionKey =>
                      <div
                        className='auto cell text-center'
                        key={optionKey}>
                        <input
                          checked={formData[diagnosisKey] === optionKey}
                          className='margin-right-0'
                          id={`answer_${optionKey}`}
                          key={optionKey}
                          name={diagnosisKey}
                          onChange={event => handleFormChange({...formData, [diagnosisKey]: event.target.value})}
                          type='radio'
                          value={optionKey}
                        />
                      </div>)}
                  </div>
                </div>
              </div>)}
            {
              formData.otherdiagnosis && formData.otherdiagnosis !== '2' && <>
                <label
                  className='form-field'
                  htmlFor={FORMS.OTHER_DIAGNOSIS.fields.otherdiagnosis.specify_other.id}>{FORMS.OTHER_DIAGNOSIS.fields.otherdiagnosis.specify_other.title}</label>
                <input
                  id={FORMS.OTHER_DIAGNOSIS.fields.otherdiagnosis.specify_other.id}
                  onChange={event => handleFormChange({...formData, [FORMS.OTHER_DIAGNOSIS.fields.otherdiagnosis.specify_other.id]: event.target.value})}
                  type='text'
                  value={formData[FORMS.OTHER_DIAGNOSIS.fields.otherdiagnosis.specify_other.id]}/>
              </>
            }
          </div>
        </form>
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

OtherDiagnosisForm.propTypes = {
  formIndex: PropTypes.number
};

export default OtherDiagnosisForm;
