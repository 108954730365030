import React from 'react';

const Blurb8_12 = () =>
  <div>
    <p>
      Targeted intervention support for substance use has been recommended. Treatment for substance use looks
      different for everyone. It will be important to access treatment services that are FASD-informed and provide
      wrap around services including managing symptoms of withdrawal, building positive coping skills, and
      maintaining progress made during treatment. Considering your own goals for treatment and areas of strength
      will allow you to work with treatment specialists to tailor your treatment to your own unique needs
      and circumstances.
    </p>
  </div>;

export default Blurb8_12;
