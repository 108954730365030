import React from 'react';

const Blurb5_2C = () =>
  <div>
    <p>
      To help build your teen’s capacity in the school setting, access to FASD education for your teen’s
      teachers and other school supports may help to build upon educator skills to create well suited
      environments. The Canada FASD Research Network offers free online FASD resources and training: <a href='https://estore.canfasd.ca/foundations-in-fasd'>https://estore.canfasd.ca/foundations-in-fasd</a>
    </p>
    <p>
      Providing access to information regarding your teen’s unique needs may further facilitate the
      identification of approaches best suited to your teen. A shared understanding and consistent
      communication between home and school can help ensure aligned and well-informed supports are
      implemented. This also creates space to highlight areas of strength, and creatively consider
      opportunities for success within the school setting that not only help support your teen’s
      learning and academic achievement, but also foster positive feelings about themselves and
      their capacity for personal growth within the school setting.
    </p>
  </div>;

export default Blurb5_2C;
