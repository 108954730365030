import React from 'react';

const Blurb8_4A = () =>
  <div>
    <p>
      Your child’s assessment team recommended accessing mental health services. Mental health services can
      include individualized therapy, group therapy, medication, and/or family intervention. It will be important
      to ensure your child’s mental health care provider is registered with local professional bodies and is
      knowledgeable in FASD informed interventions that will work for your child based on their strengths and needs.
    </p>
  </div>;

export default Blurb8_4A;
