import React from 'react';
import MyUniqueNeeds from './Sections/MyUniqueNeeds';
import Health from './Sections/Health';
import AttachmentsAndRelationships from './Sections/AttachmentsAndRelationships';
import Cover from './Sections/Cover';
import SocialCompetency from './Sections/SocialCompetency';
import EducationAndSkillbuilding from './Sections/EducationAndSkillbuilding';
import Identity from './Sections/Identity';
import Independence from './Sections/Independence';
import MentalHealthAndRegulation from './Sections/MentalHealthAndRegulation';
import CommunityTearoff from './Sections/CommunityTearoff';
import EducationTearoff from './Sections/EducationTearoff';
import ClinicianTearoff from './Sections/ClinicianTearoff';
import Employment from './Sections/Employment';
import Housing from './Sections/Housing';
import ParentingSkills from './Sections/ParentingSkills';
import AdultTearoff from './Sections/AdultTearoff';
import JusticeTearoff from './Sections/JusticeTearoff';
import NoDiagnosisTearoff from './Sections/NoDiagnosisTearoff';
import ROUTES from '../../../routes';
import {useSelector} from 'react-redux';
import MetaHelmet from '../../components/MetaHelmet';
import FORMS from '../../../redux/constants';

const Report = () => {
  const formProgress = useSelector(state => state.formProgress);
  const isFormCompleted = Object.values(formProgress).every(value => value === true);
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS_SELECTORS.id]);

  if (!isFormCompleted) {
    window.location.replace(ROUTES.ROOT);
  }

  return <div className='report'>
    <MetaHelmet
      title='Report'/>
    <Cover/>
    <MyUniqueNeeds/>
    <Health/>
    <AttachmentsAndRelationships/>
    <SocialCompetency/>
    <EducationAndSkillbuilding/>
    <Identity/>
    <Independence/>
    <MentalHealthAndRegulation/>
    <Employment/>
    <Housing/>
    <ParentingSkills/>
    {formData.community_tearoff && <CommunityTearoff/>}
    {formData.education_tearoff && <EducationTearoff/>}
    {formData.clinician_tearoff && <ClinicianTearoff/>}
    {formData.adult_adolescent_tearoff && <AdultTearoff/>}
    {formData.justice_tearoff && <JusticeTearoff/>}
    {formData.no_diagnosis_tearoff && <NoDiagnosisTearoff/>}
  </div>;
};

export default Report;
