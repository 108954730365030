import React from 'react';

const Blurb2_2A = () =>
  <div>
    <p>
      It will be important to monitor and follow-up with any primary health care providers (e.g., doctor,
      nurse practitioner) as your child grows through developmental stages and reaches major milestones.
    </p>
  </div>;

export default Blurb2_2A;
