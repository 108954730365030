import React from 'react';

const Blurb11_2E = () =>
  <div>
    <p>
      Parenting requires a wide range of skills, including problem-solving, empathy, patience, stress management,
      communication, flexibility, autonomy, and adaptability. These skills help parents raise children with love
      and care, promote their development, and provide them with safe and supportive environments to thrive in.
      Promoting healthy parents is an important ingredient to family stability and positive relationships among
      family members.
    </p>
    <p>
      If the time comes and you/your child with FASD becomes a parent, it will be important to consider which
      supports will bet fit your/their needs so you/they can experience success throughout their parenting
      journey. Working to understand your/your child’s needs through regular conversations and check-ins
      with trusted supports can make sure they are receiving help that make sense for them and promote a
      healthy parent-child relationship.
    </p>
  </div>;

export default Blurb11_2E;
