import React from 'react';
import {resetApp} from '../../redux/actions/app';
import ROUTES from '../../routes';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {config} from '../../config';

const Topbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleFormReset = () => {
    // eslint-disable-next-line no-alert
    if (confirm('Are you sure you want to reset the entire form?') === true) {
      dispatch(resetApp());
      navigate(ROUTES.ROOT);
    }
  };

  return <div className='top-bar margin-bottom-3'>
    <div className='grid-container width-100'>
      <div className='grid-x grid-margin-x align-middle'>
        <div className='auto cell'>
          <ul className='menu'>
            <li className='menu-text'>CanFASD</li>
          </ul>
        </div>
        {config.app.version && <div className='shrink cell'>
          <div className='text-right line-height-1 help-text margin-0'>
            <small>
              Version Date:<br/>
              {new Date(config.app.version).toLocaleString()}
            </small>
          </div>
        </div>}
        <div className='shrink cell'>
          {location.pathname !== ROUTES.ROOT && <ul className='menu'>
            <li>
              <button
                className='button margin-0'
                onClick={handleFormReset}
                type='button'
              >
                Reset Form
              </button>
            </li>
          </ul>}
        </div>
      </div>
    </div>
  </div>;
};

export default Topbar;
