import React from 'react';

const Blurb10_10 = () =>
  <div>
    <p>
      When already living independently, it is important to acknowledge the skills and strengths that
      are being used to support successful housing. Noticing when things are working helps us learn what
      works, and as is said, ‘if it’s not broken, don’t fix it’! Celebrating our successes is important
      in creating motivation to continue engaging in helpful supports.
    </p>
    <p>
      Because no one stays the same, it is also important to continue to monitor your/your child’s evolving
      strengths and needs to ensure that things are still working, evolving supports to better meet needs,
      and continue building on strengths.
    </p>
  </div>;

export default Blurb10_10;
