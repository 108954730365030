import IdentificationForm from './app/pages/Form/IdentificationForm';
import DiagnosisDateForm from './app/pages/Form/DiagnosisDateForm';
import GenderIdentityForm from './app/pages/Form/GenderIdentityForm';
import AssessmentForm from './app/pages/Form/AssessmentForm';
import ReferralDateForm from './app/pages/Form/ReferralDateForm';
import ReferralSourceForm from './app/pages/Form/ReferralSourceForm';
import ReferralReasonForm from './app/pages/Form/ReferralReasonForm';
import LivingSituationForm from './app/pages/Form/LivingSituationForm';
import ParentDiagnosisForm from './app/pages/Form/ParentDiagnosisForm';
import SiblingDiagnosisForm from './app/pages/Form/SiblingDiagnosisForm';
import PrenatalAlcoholForm from './app/pages/Form/PrenatalAlcoholForm';
import PalpebralFissureLengthForm from './app/pages/Form/PalpebralFissureLengthForm';
import SentinelFeaturesForm from './app/pages/Form/SentinelFeaturesForm';
import IQScoreForm from './app/pages/Form/IQScoreForm';
import BrainDomainAccessmentForm from './app/pages/Form/BrainDomainAccessmentForm';
import DiagnosisForm from './app/pages/Form/DiagnosisForm';
import OtherFeaturesForm from './app/pages/Form/OtherFeaturesForm';
import OtherDiagnosisForm from './app/pages/Form/OtherDiagnosisForm';
import MedicalHistoryForm from './app/pages/Form/MedicalHistoryForm';
import MedicationsForm from './app/pages/Form/MedicationsForm';
import OtherCurrentMedicationForm from './app/pages/Form/OtherCurrentMedicationForm';
import SubstanceForm from './app/pages/Form/SubstanceForm';
import TreatmentAccessForm from './app/pages/Form/TreatmentAccessForm';
import ExperienceForm from './app/pages/Form/ExperienceForm';
import DateOfBirthForm from './app/pages/Form/DateOfBirthForm';
import EthnicityForm from './app/pages/Form/EthnicityForm';
import OtherPrenatalExposureForm from './app/pages/Form/OtherPrenatalExposureForm';
import OtherFactorsForm from './app/pages/Form/OtherFactorsForm';
import PalpebralFissureNormsForm from './app/pages/Form/PalpebralFissureNormsForm';
import ScreeningToolForm from './app/pages/Form/ScreeningToolForm';
import FORMS from './redux/constants';
import PhiltrumSmoothnessForm from './app/pages/Form/PhiltrumSmoothnessForm';
import LipThinnessForm from './app/pages/Form/LipThinnessForm';
import DiagnosticSchemaForm from './app/pages/Form/DiagnosticSchemaForm';
import SexForm from './app/pages/Form/SexForm';
import UniqueBrain from './app/pages/Form/UniqueBrain';
import TearOffForm from './app/pages/Form/TearOffForm';
import RecommendationsForm from './app/pages/Form/RecommendationsForm';
import TearoffsSelectionForm from './app/pages/Form/TearoffsSelectionForm';

const ROUTES = {
  ROOT: '/',
  REPORT: '/report',
  FORMS: '/form/:url'
};

export const FORM_ROUTES = [
  {
    url: FORMS.UNIQUE_BRAIN.url,
    title: 'Unique Brain',
    component: UniqueBrain
  },
  {
    url: FORMS.TEAROFFS_SELECTORS.url,
    title: 'Tearoffs Selector',
    component: TearoffsSelectionForm
  },
  {
    url: FORMS.IDENTIFICATION.url,
    title: 'Identification',
    component: IdentificationForm
  },
  {
    url: FORMS.DIAGNOSIS.url,
    title: 'Diagnosis',
    component: DiagnosisForm
  },
  {
    url: FORMS.ASSESSMENT.url,
    title: 'Assessment',
    component: AssessmentForm
  },
  {
    url: FORMS.REFERRAL_DATE.url,
    title: 'Referral Date',
    component: ReferralDateForm
  },
  {
    url: FORMS.REFERRAL_SOURCE.url,
    title: 'Referral Source',
    component: ReferralSourceForm
  },
  {
    url: FORMS.REFERRAL_REASON.url,
    title: 'Referral Reason',
    component: ReferralReasonForm
  },
  {
    url: FORMS.SCREENING_TOOL.url,
    title: 'Screening Tool',
    component: ScreeningToolForm
  },
  {
    url: FORMS.DIAGNOSIS_DATE.url,
    title: 'Diagnosis Date',
    component: DiagnosisDateForm
  },
  {
    url: FORMS.SEX.url,
    title: 'Sex',
    component: SexForm
  },
  {
    url: FORMS.GENDER_IDENTITY.url,
    title: 'Gender Identity',
    component: GenderIdentityForm
  },
  {
    url: FORMS.DATE_OF_BIRTH.url,
    title: 'Date Of Birth',
    component: DateOfBirthForm
  },
  {
    url: FORMS.ETHNICITY.url,
    title: 'Ethnicity',
    component: EthnicityForm
  },
  {
    url: FORMS.CURRENT_LIVING_SITUATION.url,
    title: 'Living Situation',
    component: LivingSituationForm
  },
  {
    url: FORMS.PARENT_DIAGNOSIS.url,
    title: 'Parent Diagnosis',
    component: ParentDiagnosisForm
  },
  {
    url: FORMS.SIBLING_DIAGNOSIS.url,
    title: 'Sibling Diagnosis',
    component: SiblingDiagnosisForm
  },
  {
    url: FORMS.PRENATAL_ALCOHOL_EXPOSURE.url,
    title: 'Prenatal Alcohol',
    component: PrenatalAlcoholForm
  },
  {
    url: FORMS.OTHER_PRENATAL_EXPOSURES.url,
    title: 'Other Prenatal Exposure',
    component: OtherPrenatalExposureForm
  },
  {
    url: FORMS.OTHER_FACTORS.url,
    title: 'Other Factors',
    component: OtherFactorsForm
  },
  {
    url: FORMS.PALPEBRAL_FISSURE_NORMS.url,
    title: 'Palpebral Fissure Norms',
    component: PalpebralFissureNormsForm
  },
  {
    url: FORMS.PALPEBRAL_FISSURE_LENGTH.url,
    title: 'Palpebral Fissure Length',
    component: PalpebralFissureLengthForm
  },
  {
    url: FORMS.PHILTRUM_SMOOTHNESS.url,
    title: 'Philtrum Smoothness',
    component: PhiltrumSmoothnessForm
  },
  {
    url: FORMS.LIP_THINNESS.url,
    title: 'Lip Thinness',
    component: LipThinnessForm
  },
  {
    url: FORMS.SENTINEL_FEATURES.url,
    title: 'Sentinel Features',
    component: SentinelFeaturesForm
  },
  {
    url: FORMS.BRAIN_DOMAIN_ASSESSMENT.url,
    title: 'Brain Domain Accessment',
    component: BrainDomainAccessmentForm
  },
  {
    url: FORMS.IQ_SCORE.url,
    title: 'IQ Score',
    component: IQScoreForm
  },
  {
    url: FORMS.DIAGNOSTIC_SCHEMA.url,
    title: 'Diagnostic Schema',
    component: DiagnosticSchemaForm
  },
  {
    url: FORMS.OTHER_FEATURES.url,
    title: 'Other Features',
    component: OtherFeaturesForm
  },
  {
    url: FORMS.OTHER_DIAGNOSIS.url,
    title: 'Other Diagnosis',
    component: OtherDiagnosisForm
  },
  {
    url: FORMS.MEDICAL_HISTORY.url,
    title: 'Medical History',
    component: MedicalHistoryForm
  },
  {
    url: FORMS.MEDICATIONS.url,
    title: 'Medications',
    component: MedicationsForm
  },
  {
    url: FORMS.OTHER_CURRENT_MEDICATION.url,
    title: 'Other Current Medication',
    component: OtherCurrentMedicationForm
  },
  {
    url: FORMS.SUBSTANCE.url,
    title: 'Substance',
    component: SubstanceForm
  },
  {
    url: FORMS.TREATMENT_ACCESS.url,
    title: 'Treatment Access',
    component: TreatmentAccessForm
  },
  {
    url: FORMS.EXPERIENCE.url,
    title: 'Experience',
    component: ExperienceForm
  },
  {
    url: FORMS.RECOMMENDATIONS.url,
    title: 'Recommendations',
    component: RecommendationsForm
  },
  {
    url: FORMS.TEAROFFS.url,
    title: 'TearOff',
    component: TearOffForm
  }
];

export default ROUTES;
