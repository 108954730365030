import React from 'react';

const Blurb8_3D = () =>
  <div>
    <p>
      Assessment findings suggested that you may experience challenges related to self-regulation.
      This means that you may have difficulties managing your behaviour, emotions, and attention in
      your environments. Troubles regulating can impact behaviours, focus, memory, and relationships.
      Individuals with FASD, like you, sometimes require more specific interventions to support self-regulation
      across settings. Sometimes general strategies for self-regulation may help, such as mindfulness activities
      or physical activity but you may also benefit from individualized, targeted programming. It will be important
      for you to work with a mental health professional to build self-regulation strategies that work for you.
    </p>
  </div>;

export default Blurb8_3D;
