import React from 'react';

const Blurb8_1B = () =>
  <div>
    <p>
      Mental health is fundamental to daily living. Mental health refers to emotional and psychological
      well-being, and emotion regulation. Children with FASD are more likely to experience mental health needs
      and may have more difficulty regulating their emotions than might be typically expected- this means that
      ongoing monitoring of your child’s mental health is important. As your child continues to grow and develop,
      it will be important that they be provided with developmentally appropriate information and support that
      promotes mental health and regulation.
    </p>
  </div>;

export default Blurb8_1B;
