import React from 'react';

const Blurb7_251 = () =>
  <div>
    <p>
      Decision making is a necessary skill across the lifespan. Offering developmentally appropriate
      opportunities for choice with support helps to foster interdependence. The assessment team recommended
      exploring the possibility of a personal directive for personal care or healthcare decisions. A personal
      directive is a legal document that allows a person to appoint someone to make decisions on their behalf
      should they become unable to do so, due to illness, injury, or disability. Individuals with FASD can
      benefit from a personal directive to inform their decision-making and support their right to have a
      developmentally appropriate say in decisions made at times when they are unable to choose for themselves.
      Choosing a trusted person who is FASD-informed is important in ensuring appropriate planning for future
      decisions.
    </p>
  </div>;

export default Blurb7_251;
