import React from 'react';

const Blurb6_4C = () =>
  <div>
    <p>
      Identity is an important ingredient for supporting individual autonomy and well-being across the lifespan.
      Supporting your teen with FASD to navigate identity development can help them to recognize and build upon
      strengths and can highlight their areas of skill that can be leveraged to support areas of need. Encouraging
      your teen’s identity development can foster ongoing growth that will provide valuable support during period
      of change or transitions, such as beginning pre- and elementary school.
    </p>
  </div>;

export default Blurb6_4C;
