import React from 'react';

const Blurb8_5B = () =>
  <div>
    <p>
      Your child’s assessment team indicated that they may be experiencing difficulties in their mental health.
      Mental health difficulties can affect a child’s internal experience of emotions and thoughts, or external
      behaviours and relationships. They can also include physical symptoms, such as headaches or difficulty
      sleeping. Some challenges may be less visible to others, such as depression or anxiety, whereas others
      may impact behaviour and interactions with others, and may be more visible, such ADHD or conduct disorder.
      It is important to seek FASD-informed mental health care for your child to identify and support any specific
      mental health challenges.
    </p>
  </div>;

export default Blurb8_5B;
