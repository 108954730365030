import React from 'react';

const Blurb1_2D = () =>
  <div>
    <p>
      Your support team expressed concern about your ability to manage your daily needs and behaviours.
      The information gathered at the time of your FASD assessment was used to develop an intervention
      plan designed to support your unique difficulties and strengths.
    </p>
    <p>
      Prenatal alcohol exposure (PAE) impacts to the brains and bodies of people in different ways. Your assessment
      indicated that you have Fetal Alcohol Spectrum Disorder (FASD) with Sentinel Facial Features. Some
      individuals with FASD, like you, have distinct physical features that are unique to PAE. However,
      these features do not necessarily provide information about your unique brain- or body-based needs.
      Through individualized, ongoing supports individuals with FASD can experience success and achieve
      healthy outcomes.
    </p>
  </div>;

export default Blurb1_2D;
