import React from 'react';

const Blurb5_16E = () =>
  <div>
    <p>
      Education is an important part of development that informs goal planning and achievement throughout
      childhood, adolescence, and adulthood. As you/your child continues their education journey, it will
      be important for educators to consider their unique strengths, needs, and goals when developing an
      individualized and supportive education plan. They may benefit from adaptations to programming that
      allow for more active participation in “hands on experiences,” time to process and engage with content,
      and opportunities to share their knowledge with others. In this way they can remain engaged at school
      and build skills to support their academic achievement.
    </p>
  </div>;

export default Blurb5_16E;
