import FORMS from '../constants';

const progressInitialstate = {};

for (const formKey of Object.keys(FORMS)) {
  progressInitialstate[formKey] = false;
}

const formProgress = (state = progressInitialstate, action) => {
  const formIds = Object.keys(FORMS);

  if (formIds.includes(action.type)) {
    return {...state, ...{[action.type]: action.payload}};
  }

  return state;
};

export default formProgress;
