import React from 'react';

const Blurb6_1D = () =>
  <div>
    <p>
      Identity is an important aspect of our self-concept that distinguishes a person’s unique qualities,
      values, and goals. Identity develops over the lifespan, but times of important life changes and
      transitions, such as transitions into and through adulthood, can have a big impact on how we see
      ourselves. Identity is informed and shaped by many factors, including education/career goals,
      disabilities, spiritual experiences, and self-expression including gender and sexual identities.
      This can result in a broad spectrum of potential identity strengths and needs that are often woven
      together in unique ways for each person.
    </p>
    <p>
      How you see yourself matters – developing your identity in a way that helps you to appreciate who
      you all, including all of your strengths and needs, helps to support success in many parts of your
      life. As an individual with FASD you can experience success and purpose in your life, and can
      contribute meaningfully to your community just like any adult.
    </p>
  </div>;

export default Blurb6_1D;
