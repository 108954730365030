import React from 'react';

const Blurb1_2E = () =>
  <div>
    <p>
      The information gathered at the time of your/your child’s FASD assessment resulted in the support team
      expressing concern about your/your child’s ability to manage daily needs and behaviours. This information
      was also used to develop an intervention plan designed to support your/your child’s unique difficulties
      and strengths.
    </p>
    <p>
      Prenatal alcohol exposure (PAE) impacts to the brains and bodies of people in different ways. Your
      assessment indicated that you/your child have/has Fetal Alcohol Spectrum Disorder (FASD) with Sentinel
      Facial Features. Some individuals with FASD, like you/your child, have distinct physical features that
      are unique to PAE. However, these features do not necessarily provide information about you/your child’s
      unique brain- or body-based needs. Through individualized, ongoing supports individuals with FASD can
      experience success and achieve healthy outcomes.
    </p>
  </div>;

export default Blurb1_2E;
