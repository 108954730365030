import React from 'react';
import MetaHelmet from '../../components/MetaHelmet';

const NoRoute = () => <>
  <MetaHelmet
    title='Page Not Found (404)'/>
  <section>
    <h1>Page Not Found (404)</h1>
    <div className='lead'>Sorry, the page you are looking for cannot be found.</div>
  </section>
</>;

export default NoRoute;
