import React from 'react';

const Blurb14_6A = () =>
  <div>
    <p>
      Some individuals with FASD share similarities in the areas where further supports would be helpful.
      Some people do not experience as much difficulty and some have strengths in their intellectual
      functioning, language abilities, and academic skills. These areas are sometimes identified in
      school-based testing, and may be quite visible. However, having these skills, and the ability
      to consistently use them are two different things. Findings from your client’s assessment suggest
      that they may experience difficulties in their application skills. This includes struggling to pay
      attention, finding it difficult to use the ‘brain brake pedal’ and slowing down to think about
      something, and problems consistently or effectively ‘changing lanes,’ and thinking flexibly.
      Problem-solving and planning may also be more of a challenge. Although your client has many
      strengths and areas where less support is needed, it may also be more difficult for them to
      put that knowledge into action, for brain-based reasons. Difficulties in these areas will add
      to difficulty controlling emotions when it becomes more challenging to meet expectations for
      daily functioning and social activities.
    </p>
    <p>
      For individuals with this pattern of needs, it may be easy for others to misinterpret the difficulties
      experienced as unwillingness to follow instructions. For example, sometimes your client may not follow
      instructions even if they remember them. We might think they are doing it on purpose, or see this as
      oppositional behaviour. Frustration and discouragement can be easily misinterpreted as anger or avoidance.
      The first step is understanding, so that support and acceptance is experienced from others. By understanding
      that brain-based difficulties are impacting your client’s ability to use their skills in everyday ways,
      we can change our approach to support them better. This can help them grow and succeed in their own way.
      All individuals seek to connect meaningfully with others and find purpose in daily activities, so it is
      important to honour and promote opportunities for success and acceptance.
    </p>
    <p>
      In short, through a shared understanding of each individual’s unique brain, we can provide
      individualized, proactive support, so that success and healthy outcomes are achieved. The following
      report has been prepared to provide additional details regarding how we might move together towards
      healthy outcomes by nurturing strengths and addressing needs through well suited supports and services.
    </p>
  </div>;

export default Blurb14_6A;
