import React from 'react';

const Blurb17_1B = () =>
  <div>
    <p>
      The Canadian guidelines for diagnosing FASD highlight 10 brain domains to consider during the
      diagnostic process. This includes academic achievement, attention, cognitions, language, memory,
      neuroanatomy, executive functioning, adaptive behaviour, motor skills, and affect regulation. Each
      person has their own unique areas of strengths and needs across these 10 brain domains. At this time,
      your child’s pattern of strengths and needs does not indicate that they meet criteria for a diagnosis
      of Fetal Alcohol Spectrum Disorder. This means that your child’s functioning is not considered to be
      impacted by prenatal alcohol exposure.
    </p>
    <p>
      It is important to know that the functioning school age children, due to their young age, early
      developmental stage, and ongoing brain development, is likely to evolve over time. This means that
      some of your child’s areas of strength and need may adapt, change, and evolve throughout their life.
      It will be important that your child’s functioning continue to be monitored as they grow, develop,
      and age and continue to meet major milestones. It is recommended that your child undergo future
      assessments to understand their unique areas of strength and need and to ensure the achievement
      of healthy outcomes throughout the lifespan.
    </p>
    <p>
      The first step to achieving healthy outcomes is understanding, so that this child feels support
      and acceptance, regardless of diagnosis. Through a shared understanding of their unique brain we
      can provide individualized, proactive support so that they can experience success and achieve
      healthy outcomes.
    </p>
  </div>;

export default Blurb17_1B;
