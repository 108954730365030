import React from 'react';

const Blurb8_8A = () =>
  <div>
    <p>
      Trauma occurs when an individual experiences a distressing or disturbing event. Traumatic events or
      experiences can exist in many forms, including physical, emotional, or psychological and can include
      an actual or perceived threat to a person’s safety and wellbeing. Trauma can also include intergenerational
      experiences that are passed down through family systems. Everyone experiences trauma differently, but the
      impact of trauma can be significant and long-lasting and affects a person’s mental, emotional, and physical
      health. Your child’s experiences of trauma can have long term effects including impacts to mental health,
      social functioning, academic achievement, self-regulation, and caregiver relationships. Therapy that is
      trauma informed can be helpful for your child to navigate the impact that trauma can have on their overall
      mental wellness.
    </p>
  </div>;

export default Blurb8_8A;
