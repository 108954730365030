import React from 'react';

const Blurb6_2E = () =>
  <div>
    <p>
      To help foster identity development, it is important for you/your child to explore unique
      interests, beliefs, and values. Conversations, modeling, and relationships with others can all
      be important facets of identity exploration that can lead to greater self-awareness, insight,
      and understanding. A shared understanding of your/your child’s unique identity among supports
      can ensure they are well informed and equipped to address challenges and build on strengths
      to support your/your child’s identity development as you/they continue to grow and develop
      throughout the lifespan.
    </p>
  </div>;

export default Blurb6_2E;
