import React from 'react';

const Blurb2_7B = () =>
  <div>
    <p>
        Sleep difficulties were reported for your child. Children with FASD often have difficulty sleeping well.
        In turn, this can impact mood, behaviour, and learning. As you support your child’s sleep, working with
        a physician and/or psychologist can help to identify additional resources to support healthy sleep. A
        consistent nighttime routine is important and can improve overall functioning and wellbeing.
        Dr. Hanlon-Dearman and colleagues have developed a Better Days/Night program for children with
        FASD. Information about the program and resources can be found here:
      <a href='https://ndd.betternightsbetterdays.ca/'>https://ndd.betternightsbetterdays.ca/</a>
    </p>
  </div>;

export default Blurb2_7B;
