import React from 'react';

const Blurb8_3B = () =>
  <div>
    <p>
      Assessment findings suggested that your child has demonstrated behaviors that indicate some
      challenges related to self-regulation. This means that they may struggle to effectively manage
      their behaviour, emotions, and attention in response to their environment. Troubles regulating
      can impact a child’s behaviours, focus, and relationships. Many self-regulation strategies and
      supports may be useful such as including drinking water, chewing gum, fidget toys, or doodling.
      Co-creating strategies together with your child can be helpful. They may also benefit from
      individualized, targeted programming, as well as environmental supports including clear structure
      and routine. Learn more about helping your child self-regulate with this resource: <a href='https://canfasd.ca/2020/09/07/supporting-self-regulation-for-back-to-school/'>
      https://canfasd.ca/2020/09/07/supporting-self-regulation-for-back-to-school/</a>
    </p>
  </div>;

export default Blurb8_3B;
