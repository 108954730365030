import React from 'react';

const Blurb1_3E = () =>
  <div>
    <p>
      The information gathered at the time of your/your child’s FASD assessment resulted in the support team
      expressing concern about you/your child’s ability to manage daily needs and behaviours. This information
      was also used to develop an intervention plan designed to support your/your child’s unique difficulties
      and strengths.
    </p>
    <p>
      Prenatal alcohol exposure (PAE) can affect the brains and bodies of people in different ways. Your
      needs indicate that you/your child have/has Fetal Alcohol Spectrum Disorder (FASD) without Sentinel
      Facial Features. This means that while some individuals may have physical features that are unique
      to PAE, other individuals like you/your child, do not. These features do not necessarily provide
      information about you/your child’s unique brain- or body-based needs. Through individualized, ongoing
      supports, individuals with FASD can experience success and achieve healthy outcomes. Through
      individualized, ongoing supports, individuals with FASD can experience success and achieve healthy
      outcomes.
    </p>
  </div>;

export default Blurb1_3E;
