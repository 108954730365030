import React from 'react';

const Blurb11_2C = () =>
  <div>
    <p>
      Parenting requires a wide range of skills, including problem-solving, empathy, patience, stress
      management, communication, flexibility, autonomy, and adaptability. These skills help parents raise
      children with love and care, promote their development, and provide them with safe and supportive
      environments to thrive in. Promoting healthy parents is an important ingredient to family stability
      and positive relationships among family members.
    </p>
    <p>
      If the time comes and your teen with FASD becomes a parent, it will be important to consider which
      supports will bet fit their needs so they can experience success throughout their parenting journey.
      Working with your teen to understand their needs through regular conversations and check-ins can help
      make sure they are receiving supports that make sense for them and promote a healthy parent-child
      relationship.
    </p>
  </div>;

export default Blurb11_2C;
