import React from 'react';
import CheckBox from '../../../components/CheckBox';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const OtherFeaturesForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.OTHER_FEATURES.id]);

  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    if (!updatedFormData.associatedfeatures___6) {
      updatedFormData[FORMS.OTHER_FEATURES.fields.associatedfeatures___6.specify_other.id] = FORMS.OTHER_FEATURES.fields.associatedfeatures___6.specify_other.value;
    }

    dispatch(submitForm(FORMS.OTHER_FEATURES.id, updatedFormData));
  };

  const otherAssociatedFeatures = FORMS.OTHER_FEATURES.fields;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] === true);
  const isFormComplete = checkedFields.length > 0 &&
    (formData.associatedfeatures___6 ? formData.associatedfeatures_specify.length > 0 : true);

  return (
    <>
      <h2 className='form-header'>{FORMS.OTHER_FEATURES.header}</h2>
      <div className='form-content'>
        <form>
          <div className='grid-x grid-margin-x'>
            <div className='small-12 medium-4 large-3 cell'>
              <label className='form-field'>Other Associated Features <span>(Please check all that apply)</span></label>
            </div>
            <div className='cell medium-8 large-9'>
              <div className='form-field-value-rounded-borders padding-bottom-1'>
                <div className='grid-x small-up-1 medium-up-3'>
                  {Object.entries(otherAssociatedFeatures).map(([featurekey, featureValues]) =>
                    <CheckBox
                      checked={formData[featurekey]}
                      className='cell'
                      key={featurekey}
                      onChange={() => handleFormChange({...formData, [featurekey]: !formData[featurekey]})}
                      value={featureValues.title}/>)}
                </div>
                {
                  formData.associatedfeatures___6 && <>
                    <label
                      htmlFor={FORMS.OTHER_FEATURES.fields.associatedfeatures___6.specify_other.id}>{FORMS.OTHER_FEATURES.fields.associatedfeatures___6.specify_other.title}</label>
                    <input
                      className='margin-bottom-0'
                      id={FORMS.OTHER_FEATURES.fields.associatedfeatures___6.specify_other.id}
                      onChange={event => handleFormChange({...formData, [FORMS.OTHER_FEATURES.fields.associatedfeatures___6.specify_other.id]: event.target.value})}
                      type='text'
                      value={formData[FORMS.OTHER_FEATURES.fields.associatedfeatures___6.specify_other.id]}/>
                  </>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

OtherFeaturesForm.propTypes = {
  formIndex: PropTypes.number
};

export default OtherFeaturesForm;
