import React from 'react';

const Blurb4_2E = () =>
  <div>
    <p>
      Individuals with FASD often have difficulties with social skills because brain-based differences can make it
      more challenging to regulate emotions, read social cues, and interact positively with others in social
      settings. This can be stressful for individuals with FASD who often value connections and relationships - like
      we all do. With appropriate interventions and skill building opportunities, individuals with FASD can
      interact with peers appropriately, make and maintain friendships, and begin to regulate their emotions
      across social contexts. Your/your child’s relational strengths, which may include the ability to reciprocate
      affection, friendliness, or the desire to please others can be leveraged in the continued development of
      your/your child’s social skills.
    </p>
  </div>;

export default Blurb4_2E;
