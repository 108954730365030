import React from 'react';
import CheckBox from '../../../components/CheckBox';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const PalpebralFissureNormsForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.PALPEBRAL_FISSURE_NORMS.id]);
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    if (!updatedFormData.fissurenorms___3) {
      updatedFormData[FORMS.PALPEBRAL_FISSURE_NORMS.fields.fissurenorms___3.specify_other.id] = FORMS.PALPEBRAL_FISSURE_NORMS.fields.fissurenorms___3.specify_other.value;
    }

    dispatch(submitForm(FORMS.PALPEBRAL_FISSURE_NORMS.id, updatedFormData));
  };

  const palpebralFissureNorms = FORMS.PALPEBRAL_FISSURE_NORMS.fields;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] === true);
  const isFormComplete = checkedFields.length > 0 &&
    (formData.fissurenorms___3 ? formData.fissurenorms.length > 0 : true);

  return (
    <>
      <h2 className='form-header'>{FORMS.PALPEBRAL_FISSURE_NORMS.header}</h2>
      <div className='form-content'>
        <form>
          <div className='grid-x grid-margin-x'>
            <div className='small-12 medium-4 large-3 cell'>
              <label className='form-field'>Palpebral fissure norms used:</label>
            </div>
            <div className='cell medium-8 large-9'>
              <div className='form-field-value-rounded-borders padding-bottom-1'>
                <div className='grid-x small-up-1 medium-up-2'>
                  {Object.entries(palpebralFissureNorms).map(([normKey, normValues]) =>
                    <CheckBox
                      checked={formData[normKey]}
                      className='cell'
                      key={normKey}
                      onChange={() => handleFormChange({...formData, [normKey]: !formData[normKey]})}
                      value={normValues.title}/>)}
                </div>
                {
                  formData.fissurenorms___3 && <>
                    <label
                      htmlFor={FORMS.PALPEBRAL_FISSURE_NORMS.fields.fissurenorms___3.specify_other.id}>Please specify</label>
                    <input
                      className='margin-bottom-0'
                      id={FORMS.PALPEBRAL_FISSURE_NORMS.fields.fissurenorms___3.specify_other.id}
                      onChange={event => handleFormChange({...formData, [FORMS.PALPEBRAL_FISSURE_NORMS.fields.fissurenorms___3.specify_other.id]: event.target.value})}
                      type='text'
                      value={formData[FORMS.PALPEBRAL_FISSURE_NORMS.fields.fissurenorms___3.specify_other.id]}/>
                  </>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

PalpebralFissureNormsForm.propTypes = {
  formIndex: PropTypes.number
};

export default PalpebralFissureNormsForm;
