import React from 'react';
import PropTypes from 'prop-types';

const Card = ({children, title}) =>
  <div className='card display-block'>
    <div className='card-divider subheader h4 font-bold'>
      {title}
    </div>
    <div className='card-section secondary'>
      {children}
    </div>
  </div>;

Card.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired
};

export default Card;
