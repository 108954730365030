import React from 'react';

const Blurb5_1E = () =>
  <div>
    <p>
      The education system in Canada aims to provide academic and applied supports to inform development and
      prepare children and teens for adulthood. In adulthood, there are many ways to seek out education
      opportunities including participating in training for jobs and taking courses to learn new skills
      such as a trade. Regardless of age, schools or other education based settings
      also provide a setting for students to pursue many other important goals, like social and identity
      development, and employment planning. A  variety of strengths and difficulties may emerge in
      education settings for people with FASD, in ways that are not always easy to predict or understand.
      This can make it difficult to get ahead of challenges and nurture strengths, and therefore requires
      individualized, collaborative, and goal-oriented approaches to support.
    </p>
    <p>
      Collaboration between educational staff, families caregivers, or other supports is important to
      establish a shared understanding of strengths and difficulties noted throughout this report. Through
      meaningful collaboration, strategies are co-created and geared towards achieving desired goals, success
      is reinforced and replicated, and supports are adapted as needed. Students with FASD will often require
      much more comprehensive planning that connects learning settings, and home resources.
    </p>
  </div>;

export default Blurb5_1E;
