import React from 'react';

const Blurb6_2B = () =>
  <div>
    <p>
      To help foster your child’s identity development, provide them with opportunities to explore their
      unique interests, beliefs, and values. Conversations, modeling, and play can all be important facets
      of identity exploration in childhood that can lead to greater self-awareness, insight, and understanding.
      A shared understanding of your child’s unique identity among supports can ensure they are well informed
      and equipped to address challenges and build on strengths as they continue to grow and develop throughout
      childhood and into adolescence.
    </p>
  </div>;

export default Blurb6_2B;
