import React from 'react';

const Blurb11_3E = () =>
  <div>
    <p>
      Like any aspect of life, should you/your child become a parent it will be important for to monitor
      your/their parenting journey to make sure that the services put in place help you/them develop your/their
      parenting skills. Potential supports to consider include in-home services, advocacy, counselling, education,
      and pregnancy programs that leverage and support your/your child’s unique strengths and needs. As you/your
      child become more confident and able to self-advocate for yourself/themselves and your/their children,
      environmental supports can be reduced.
    </p>
    <p>
      Some people with FASD experience difficulties with self-regulation. Though rewarding, raising children
      can also be unpredictable and stressful, making it that harder to control emotions and behaviour when
      self-regulation is already difficult. Parents with FASD may benefit from building skills to help stay
      calm under pressure, while also being flexible and supportive in response to changing circumstances.
      Self-care for yourself/themselves as a parent with FASD is necessary so that you/they can provide the
      best care possible for your children. This might look like specific mental health and parenting support
      for yourself/themselves, accessing caregiver support groups, taking time for self-care, or developing
      some hobbies you can do with your/their children.
    </p>
  </div>;

export default Blurb11_3E;
