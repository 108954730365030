import React from 'react';

const Blurb8_5D = () =>
  <div>
    <p>
      Your assessment team indicated that you may be experiencing mental health difficulties. These difficulties
      can affect your internal experience of emotions and thoughts, or your external behaviours and relationships.
      They can also include you experiencing physical symptoms, such as headaches or difficulty sleeping. Some
      challenges may be less visible to others, such as depression or anxiety, whereas others may impact behaviour
      and interactions with others, and may be more visible, such ADHD or conduct disorder. It is important for
      you to seek FASD-informed mental health care to identify and support any specific mental health challenges.
    </p>
  </div>;

export default Blurb8_5D;
