import React from 'react';

const Blurb12_1E = () =>
  <div>
    <p>
      The desire to belong to a community is a natural part of life. Engaging with community can positively
      influence well-being and provide a sense of belonging at all developmental stages. Community engagement
      can also provide a positive way for people with FASD to build relationships, develop social skills,
      participate in enjoyed activities, and find a role within the community that fosters feelings of
      safety and security. Service providers, like yourself, are highly skilled in their ability to find
      or co-create unique opportunities for their clients with FASD to leverage their strengths, set goals,
      and fulfil their desire to be a part of their community. As you know, this will look different in
      different regions and communities.
    </p>
    <p>
      There are very few people who do not, in some capacity, receive supports from others in their daily
      life. The degree of support needed varies across individuals and life stages, and may include help
      with decision-making, self-care, hygiene, money management, and/or social functioning. Helping the
      client you are supporting to access community resources, such as social and recreational activities,
      can foster a sense of belonging and connection. Helping your client and/or their family/caregiver(s)
      in fostering a stable, caring, and dependable environment can enhance their sense of safety and
      connection to others. Creating opportunities to access developmentally appropriate community supports
      with increasing autonomy as they continue to grow and develop is key. Your client, like all people
      with FASD, also possesses many interpersonal strengths that can be leveraged in helping to meet
      diverse community support needs across the lifespan
    </p>
  </div>;

export default Blurb12_1E;
