import React from 'react';

const Blurb9_3 = () =>
  <div>
    <p>
      Your/your teen’s assessment team identified employment as an area of challenge for you/your teen. This
      means you may have struggled to identify, obtain, and/or maintain meaningful work in an environment that
      fits well with your unique areas of strengths and abilities. The type of employment best suited to each
      person can differ depending on their values, interests, and goals - learning what it is that suits your
      unique areas of strengths and needs will be helpful in determining employment that is best suited to you.
      The tools described through this chapter may be helpful in supporting your/your teen’s employment success.
    </p>
  </div>;

export default Blurb9_3;
