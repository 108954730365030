import React from 'react';

const Blurb7_3D = () =>
  <div>
    <p>
      Interdependence is an integral part of daily living throughout the lifespan and is composed of skills
      related to community engagement and adaptive functioning. There are very few people who do not, in some
      capacity, receive supports from others in their daily life. Skills including decision-making, self-care,
      hygiene, money management, and social functioning all inform the level of interdependence an individual
      requires. Individuals with FASD demonstrate a wide range of strengths and needs related to interdependence
      throughout the lifespan. Supports that allow you to navigate your community and use your daily living
      skills are important to building your interdependence and leveraging your own strengths.
    </p>
    <p>
      As people grow and develop into adulthood they begin to seek greater autonomy and independence. Ongoing
      support, including coaching, peer mentorship, and decision-making guides will be necessary to meet your
      need for greater choice and autonomy while still providing developmentally appropriate assistance.
    </p>
  </div>;

export default Blurb7_3D;
