import React from 'react';

const Blurb5_2E = () =>
  <div>
    <p>
      To help build capacity in the school setting, access to FASD education for teachers and other school
      supports may help to build upon educator skills to create well suited environments. The Canada FASD
      Research Network offers free online FASD resources and training: <a href='https://estore.canfasd.ca/foundations-in-fasd'>https://estore.canfasd.ca/foundations-in-fasd</a>
    </p>
    <p>
      Providing access to information regarding your/your child’s unique needs may further facilitate the
      identification of approaches best suited to them. A shared understanding among supports can ensure
      they are well informed and equipped to address challenges and build on strengths to support learning,
      academic achievement, and/or training.
    </p>
  </div>;

export default Blurb5_2E;
