import ReactGA from 'react-ga4';
import {config} from '../../config';

ReactGA.initialize(config.google.analytics.measurementID, {
  anonymizeIp: true,
  testMode: process.env.NODE_ENV === 'test',
  gaOptions: {
    anonymizeIp: true,
    cookieFlags: 'SameSite=None;Secure'
  }
});

const trackEvent = action => {
  ReactGA.event({category: 'Redux Action', action}); // Track event to google analytics
};

// eslint-disable-next-line no-unused-vars
const middleware = () => next => action => {
  const {type} = action;
  ReactGA.set({anonymizeIp: true});

  if (!type.includes('persist/')) {
    trackEvent(type);
  }

  return next(action);
};

export default middleware;
