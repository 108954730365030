import React from 'react';

const Blurb3_4C = () =>
  <div>
    <p>
      During the assessment there was indication that your teen may have experienced past trauma. Exposure to
      traumatic experiences can influence your teen’s attachment to others, including how they perceive
      themselves and others, and their relationships with caregivers. Attachment-based therapy can help
      adolescents with FASD to develop healthy coping skills, and support attachment relationships, family
      cohesion and overall wellbeing.
    </p>
  </div>;

export default Blurb3_4C;
