import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const LivingSituationForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.CURRENT_LIVING_SITUATION.id]);
  const isFormComplete = formData.living !== '' &&
    (formData.living === FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.condition ? formData.living_specify.length > 0 : true) &&
    (formData.living === FORMS.CURRENT_LIVING_SITUATION.fields.living_familyspecify.condition
      ? formData.living_familyspecify.length > 0 : true);
  const dispatch = useDispatch();
  const livingSituationOptions = FORMS.CURRENT_LIVING_SITUATION.fields.living.options;

  const handleFormChange = updatedFormData => {
    if (formData.living !== updatedFormData.living) {
      updatedFormData.living_familyspecify = FORMS.CURRENT_LIVING_SITUATION.fields.living_familyspecify.value;
    }

    if (formData.living === FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.condition &&
      updatedFormData.living !== FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.condition) {
      updatedFormData.living_specify = FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.value;
    }

    dispatch(submitForm(FORMS.CURRENT_LIVING_SITUATION.id, updatedFormData));
  };

  return (
    <>
      <h2 className='form-header'>{FORMS.CURRENT_LIVING_SITUATION.header}</h2>
      <div className='form-content'>
        <form>
          <div className='grid-x grid-margin-x'>
            <div className='small-12 medium-4 large-3 cell'>
              <label className='form-field'>{FORMS.CURRENT_LIVING_SITUATION.fields.living.title}</label>
            </div>
            <div className='cell medium-8 large-9'>
              <div className='form-field-value-rounded-borders'>
                <div className='grid-x small-up-1 medium-up-2'>
                  {Object.keys(livingSituationOptions).map(optionKey =>
                    <RadioButton
                      checked={formData.living === optionKey}
                      className='cell'
                      key={optionKey}
                      label={livingSituationOptions[optionKey]}
                      onChange={event => handleFormChange({...formData, living: event.target.value})}
                      value={optionKey}/>)}
                </div>
                {
                  formData.living === FORMS.CURRENT_LIVING_SITUATION.fields.living_familyspecify.condition && <>
                    <label
                      htmlFor={FORMS.CURRENT_LIVING_SITUATION.fields.living_familyspecify.id}>{FORMS.CURRENT_LIVING_SITUATION.fields.living_familyspecify.title}</label>
                    <input
                      id={FORMS.CURRENT_LIVING_SITUATION.fields.living_familyspecify.id}
                      onChange={event => handleFormChange({...formData, [FORMS.CURRENT_LIVING_SITUATION.fields.living_familyspecify.id]: event.target.value})}
                      type='text'
                      value={formData[FORMS.CURRENT_LIVING_SITUATION.fields.living_familyspecify.id]}/>
                  </>
                }
                {
                  formData.living === FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.condition && <>
                    <label
                      htmlFor={FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.id}>{FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.title}</label>
                    <input
                      id={FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.id}
                      onChange={event => handleFormChange({...formData, [FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.id]: event.target.value})}
                      type='text'
                      value={formData[FORMS.CURRENT_LIVING_SITUATION.fields.living_specify.id]}/>
                  </>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

LivingSituationForm.propTypes = {
  formIndex: PropTypes.number
};

export default LivingSituationForm;
