import React from 'react';

const Blurb2_10 = () =>
  <div>
    <p>
      An assessment of how auditory information is processed suggests that hearing difficulties may be present
      for you/your child. It is necessary to provide ongoing auditory accommodations and supports to foster
      healthy auditory functioning and accessibility needs.
    </p>
  </div>;

export default Blurb2_10;
