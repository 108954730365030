import React from 'react';

const Blurb3_10D = () =>
  <div>
    <p>
      Healthy attachment and relationships are an important ingredient for supporting intervention efforts for
      adults with FASD. Attachment and relationships are informed by skills, abilities, and experiences across
      many different areas of life. Supporting families to navigate stress and areas of need, listening to
      caregivers, and engaging family, are all important in leveraging and maintaining stability while fostering
      your strengths and supporting your needs. Families are excellent sources of information related to
      strengths and resiliency factors that may be incorporated in success planning.
    </p>
    <p>
      Ongoing support such as respite, daycare, or mental health supports such as therapy, alongside ongoing
      monitoring will be important in informing supports regarding your attachment and relationships. It will
      be helpful to establish an understanding of the role that family and caregivers play in your life, now
      and in the future, as you get older. One important question for you and your support providers to consider
      is: What are your goals for continued interactions or support? A conversation around this question will
      encourage familial cohesion and support that is best suited for you and your family or caregivers.
    </p>
  </div>;

export default Blurb3_10D;
