import React from 'react';

const Blurb7_23D = () =>
  <div>
    <p>
      The assessment team recommended that supports to increase your safety may be helpful for you.
    </p>
    <p>
      Safety is essential for both physical and mental well-being. It will be important for you to foster
      a sense of safety through trusting, consistent relationships, and making sure you have access to
      necessities including food and shelter. Creating stable, caring, and dependable environments at
      home or work will enhance your sense of safety, connectedness, and autonomy as you access supports
      and build your independence.
    </p>
  </div>;

export default Blurb7_23D;
