import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const ReferralSourceForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.REFERRAL_SOURCE.id]);
  const isFormComplete = formData.ref_source !== '' &&
    (formData.ref_source === FORMS.REFERRAL_SOURCE.fields.ref_source.specify_other.condition
      ? formData.ref_sourcespecify.length > 0 : true);
  const dispatch = useDispatch();
  const {ref_source} = FORMS.REFERRAL_SOURCE.fields;

  const handleFormChange = updatedFormData => {
    if (formData.ref_source !== updatedFormData.ref_source) {
      updatedFormData.ref_sourcespecify = ref_source.specify_other.value;
    }

    dispatch(submitForm(FORMS.REFERRAL_SOURCE.id, updatedFormData));
  };

  return (

    <>
      <h2 className='form-header'>{FORMS.REFERRAL_SOURCE.header}</h2>
      <div className='form-content'>
        <form>
          <div className='grid-x grid-margin-x'>
            <div className='small-12 medium-4 large-3 cell'>
              <label className='form-field'>{FORMS.REFERRAL_SOURCE.fields.ref_source.title}</label>
            </div>
            <div className='cell medium-8 large-9'>
              <div className='form-field-value-rounded-borders'>
                {Object.keys(ref_source.options).map(optionKey =>
                  <RadioButton
                    checked={formData.ref_source === optionKey}
                    key={optionKey}
                    label={ref_source.options[optionKey]}
                    onChange={() => handleFormChange({...formData, ref_source: optionKey})}
                    value={optionKey}
                  />)}
                {
                  formData.ref_source === ref_source.specify_other.condition && <>
                    <label
                      htmlFor={ref_source.specify_other.id}>
                      {ref_source.specify_other.title}
                    </label>
                    <input
                      id={ref_source.specify_other.id}
                      onChange={event => handleFormChange({...formData, ref_sourcespecify: event.target.value})}
                      type='text'
                      value={formData.ref_sourcespecify}/>
                  </>
                }
              </div>
            </div>
          </div>
        </form>
      </div>

      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

ReferralSourceForm.propTypes = {
  formIndex: PropTypes.number
};

export default ReferralSourceForm;
