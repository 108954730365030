import React, {useEffect} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import ROUTES, {FORM_ROUTES} from '../../routes';
import FORMS from '../../redux/constants';
import {useDispatch} from 'react-redux';
import {updateProgress} from '../../redux/actions/formProgress';
import PropTypes from 'prop-types';
import {saveToRedCap} from '../../redux/actions/forms';

export const FormNav = ({formIndex, canSubmit}) => {
  const canGoBack = formIndex > 0;
  const canGoForward = Boolean(formIndex < FORM_ROUTES.length - 1 && canSubmit);
  const canSeeReport = formIndex === FORM_ROUTES.length - 1 && canSubmit;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formKeysArray = Object.keys(FORMS);
  const formKey = formKeysArray[formIndex];
  const completedFormsPercentage = Math.ceil((formIndex + 1) / Object.keys(FORMS).length * 100);

  const handlePrevious = () => {
    navigate(generatePath(ROUTES.FORMS, {url: FORM_ROUTES[formIndex - 1].url}));
  };

  const handleNext = () => {
    const nextForm = FORM_ROUTES[formIndex + 1];
    navigate(generatePath(ROUTES.FORMS, {url: nextForm.url}));
  };

  const handleShowReport = () => {
    dispatch(saveToRedCap());
    navigate(ROUTES.REPORT);
  };

  useEffect(() => {
    dispatch(updateProgress(formKey, canGoForward || canSeeReport));
  }, [canGoForward]);

  return (
    <>
      <div
        aria-valuemax='100'
        aria-valuemin='0'
        aria-valuenow={`${completedFormsPercentage}`}
        className='progress margin-bottom-2'
        role='progressbar'
        style={{width: '100%'}}>
        <span
          className='progress-meter'
          style={{width: `${completedFormsPercentage}%`}}>
          <span className='progress-meter-text'>{`${completedFormsPercentage}%`}</span>
        </span>
      </div>
      <div className='grid-x grid-margin-x align-center-middle padding-bottom-1'>
        <div className='shrink cell'>
          <button
            className='button'
            disabled={!canGoBack}
            onClick={handlePrevious}
            type='button'
          >
        Previous
          </button>
        </div>
        <div className='shrink cell'>
          <p>Question {`${formIndex + 1}`} of {`${Object.keys(FORMS).length}`}</p>
        </div>
        <div className='shrink cell'>
          <button
            className='button'
            disabled={!canGoForward && !canSeeReport}
            onClick={canSeeReport ? handleShowReport : handleNext}
            type='button'
          >
            {canSeeReport ? 'Show Report' : 'Next'}
          </button>
        </div>
      </div>
    </>
  );
};

FormNav.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  formIndex: PropTypes.number
};

FormNav.propTypes = {
  formIndex: PropTypes.number
};
