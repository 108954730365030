import React from 'react';
import SectionTitle from '../components/SectionTitle';
import '../../../../assets/stylesheets/pages/report.scss';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Blurb3_1A from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_1A';
import Blurb3_1B from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_1B';
import Blurb3_1C from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_1C';
import Blurb3_1D from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_1D';
import Blurb3_1E from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_1E';
import Blurb3_2A from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_2A';
import Blurb3_2B from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_2B';
import Blurb3_2C from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_2C';
import Blurb3_2D from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_2D';
import Blurb3_2E from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_2E';
import Blurb3_3A from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_3A';
import Blurb3_3B from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_3B';
import Blurb3_3C from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_3C';
import Blurb3_3D from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_3D';
import Blurb3_3E from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_3E';
import Blurb3_4A from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_4A';
import Blurb3_4B from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_4B';
import Blurb3_4C from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_4C';
import Blurb3_4D from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_4D';
import Blurb3_4E from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_4E';
import Blurb3_5A from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_5A';
import Blurb3_5B from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_5B';
import Blurb3_5C from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_5C';
import Blurb3_5D from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_5D';
import Blurb3_5E from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_5E';
import Blurb3_6A from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_6A';
import Blurb3_6B from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_6B';
import Blurb3_6C from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_6C';
import Blurb3_6D from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_6D';
import Blurb3_6E from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_6E';
import Blurb3_7 from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_7';
import Blurb3_8 from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_8';
import Blurb3_9 from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_9';
import Blurb3_10A from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_10A';
import Blurb3_10B from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_10B';
import Blurb3_10C from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_10C';
import Blurb3_10D from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_10D';
import Blurb3_10E from '../../../blurbs/3_AttachmentAndRelationships/Blurb3_10E';
import Variables from '../components/Variables';
import Card from '../components/Card';

const AttachmentsAndRelationships = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const {attachment} = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);
  const {living} = useSelector(state => state.forms[FORMS.CURRENT_LIVING_SITUATION.id]);
  const {factors___0, factors___1} = useSelector(state => state.forms[FORMS.OTHER_FACTORS.id]);
  const {associatedfeatures___3} = useSelector(state => state.forms[FORMS.OTHER_FEATURES.id]);
  const {respite, daycare, familycounselling} = useSelector(state => state.forms[FORMS.RECOMMENDATIONS.id]);

  const variables = {diagnosis, age, attachment, living, factors___0, factors___1,
    associatedfeatures___3, familycounselling, respite, daycare};

  return <section>
    <SectionTitle>Attachments And Relationships</SectionTitle>
    <Variables variableObject={variables}/>
    <Card title='Things to Know'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_1A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_1B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_1C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_1D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_1E/>}
      </div>
    </Card>
    <Card title='Things to Do Now'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_2A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_2B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_2C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_2D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_2E/>}
      </div>
      <div>
        {attachment === '1' || factors___1 === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_3A/>
        }
        {attachment === '1' || factors___1 === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_3B/>
        }
        {attachment === '1' || factors___1 === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_3C/>
        }
        {attachment === '1' || factors___1 === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_3D/>
        }
        {attachment === '1' || factors___1 === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_3E/>
        }
      </div>
      <div>
        {associatedfeatures___3 === '3' || factors___0 === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_4A/>
        }
        {associatedfeatures___3 === '3' || factors___0 === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_4B/>
        }
        {associatedfeatures___3 === '3' || factors___0 === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_4C/>
        }
        {associatedfeatures___3 === '3' || factors___0 === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_4D/>
        }
        {associatedfeatures___3 === '3' || factors___0 === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_4E/>
        }
      </div>
      <div>
        {respite === '1' && age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_5A/>}
        {respite === '1' && age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_5B/>}
        {respite === '1' && age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_5C/>}
        {respite === '1' && age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_5D/>}
        {respite === '1' && age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_5E/>}
        {daycare === '1' && age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_6A/>}
        {daycare === '1' && age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_6B/>}
        {daycare === '1' && age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_6C/>}
        {daycare === '1' && age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_6D/>}
        {daycare === '1' && age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_6E/>}
      </div>
      <div>
        {familycounselling === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_7/>}
      </div>
      <div>
        {living === '0' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_8/> }
        {living === '8' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_9/> }
      </div>
    </Card>
    <Card title='Things to Plan For'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_10A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_10B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_10C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_10D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb3_10E/>}
      </div>
    </Card>
  </section>;
};

export default AttachmentsAndRelationships;
