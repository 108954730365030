import React from 'react';

const Blurb8_2A = () =>
  <div>
    <p>
      Nurturing mental health and wellbeing may look different for each child and can shift as children grow.
      You can support your child’s mental health by proactively monitoring for challenges. Doing so allows you
      to proactively identify possible needs or concerns early and respond effectively. Additional ways to
      promoting wellness include creating experiences of positive emotionality, reducing stress, and fostering
      well-suited environments for children with FASD. It will be important to monitor and follow-up with any
      mental health professionals and supports as your child grows through developmental stages and reaches
      major milestones. Here is a short guide to supporting mental wellbeing in individuals with FASD from
      CanFASD: <a href='https://canfasd.ca/2020/05/08/mental-health-for-individuals-with-fasd/'>
      https://canfasd.ca/2020/05/08/mental-health-for-individuals-with-fasd/</a>
    </p>
  </div>;

export default Blurb8_2A;
