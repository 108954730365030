import React from 'react';

const Blurb6_2D = () =>
  <div>
    <p>
      To help foster your identity development, engage with opportunities to explore your unique interests,
      beliefs, and values. Conversations, modeling, and relationships with others including mentors can all
      be important facets of identity exploration in adolescence that can lead to greater self-awareness,
      insight, and understanding. A shared understanding of your unique identity among supports can ensure
      they are well informed and equipped to address challenges and build on strengths to support your identity
      development as you continue to grow and adapt to new environments during your adult life.
    </p>
  </div>;

export default Blurb6_2D;
