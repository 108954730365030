import React from 'react';

const Blurb1_3C = () =>
  <div>
    <p>
      Your teen’s support team expressed concern about their ability to manage daily needs and behaviours.
      The information gathered at the time of your teen’s FASD assessment was used to develop an intervention
      plan designed to support your teen’s unique difficulties and strengths.
    </p>
    <p>
      Prenatal alcohol exposure (PAE) can affect the brains and bodies of people in different ways. Your teen’s
      needs indicate that they have Fetal Alcohol Spectrum Disorder (FASD) without Sentinel Facial Features.
      This means that while some individuals may have physical features that are unique to PAE, other individuals
      like your teen, do not. These features do not necessarily provide information about your teen’s unique
      brain- or body-based needs. Through individualized, ongoing supports, individuals with FASD can experience
      success and achieve healthy outcomes.
    </p>
  </div>;

export default Blurb1_3C;
