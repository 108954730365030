import React from 'react';

const Blurb2_11 = () =>
  <div>
    <p>
      Difficulties in visual functioning were identified. This may include difficulties seeing or processing
      information visually. Providing necessary visual supports and accommodations will help support overall
      physical well-being and accessibility needs.
    </p>
  </div>;

export default Blurb2_11;
