import React from 'react';

const Blurb10_9C = () =>
  <div>
    <p>
      Housing is a lifelong need, with related supports, accommodations, and strategies changing as we
      continue to grow and develop. To help your teen prepare for obtaining and maintain safe and
      affordable housing, it is important to identify their unique strengths and needs, recognize when
      they need supports, and create opportunities to safely practice necessary skills in a safe environment,
      as they seek greater independence in the transition to adulthood.
    </p>
  </div>;

export default Blurb10_9C;
