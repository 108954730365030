import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import '../../../../assets/stylesheets/pages/report.scss';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Blurb10_1A from '../../../blurbs/10_Housing/Blurb10_1A';
import Blurb10_1B from '../../../blurbs/10_Housing/Blurb10_1B';
import Blurb10_1C from '../../../blurbs/10_Housing/Blurb10_1C';
import Blurb10_1D from '../../../blurbs/10_Housing/Blurb10_1D';
import Blurb10_1E from '../../../blurbs/10_Housing/Blurb10_1E';
import Blurb10_2 from '../../../blurbs/10_Housing/Blurb10_2';
import Blurb10_3 from '../../../blurbs/10_Housing/Blurb10_3';
import Blurb10_4A from '../../../blurbs/10_Housing/Blurb10_4A';
import Blurb10_4B from '../../../blurbs/10_Housing/Blurb10_4B';
import Blurb10_4C from '../../../blurbs/10_Housing/Blurb10_4C';
import Blurb10_4D from '../../../blurbs/10_Housing/Blurb10_4D';
import Blurb10_4E from '../../../blurbs/10_Housing/Blurb10_4E';
import Blurb10_5 from '../../../blurbs/10_Housing/Blurb10_5';
import Blurb10_6 from '../../../blurbs/10_Housing/Blurb10_6';
import Blurb10_7 from '../../../blurbs/10_Housing/Blurb10_7';
import Blurb10_8 from '../../../blurbs/10_Housing/Blurb10_8';
import Blurb10_9A from '../../../blurbs/10_Housing/Blurb10_9A';
import Blurb10_9B from '../../../blurbs/10_Housing/Blurb10_9B';
import Blurb10_9C from '../../../blurbs/10_Housing/Blurb10_9C';
import Blurb10_9D from '../../../blurbs/10_Housing/Blurb10_9D';
import Blurb10_9E from '../../../blurbs/10_Housing/Blurb10_9E';
import Blurb10_10 from '../../../blurbs/10_Housing/Blurb10_10';
import Blurb10_11 from '../../../blurbs/10_Housing/Blurb10_11';
import Variables from '../components/Variables';

const Housing = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {living} = useSelector(state => state.forms[FORMS.CURRENT_LIVING_SITUATION.id]);
  const {helpliving, assistedhousing} = useSelector(state => state.forms[FORMS.EXPERIENCE.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const variables = {diagnosis, living, helpliving, assistedhousing, age};

  return <section>
    <SectionTitle>Housing</SectionTitle>
    <Variables variableObject={variables}/>
    <Card
      title='Things To Know'>
      <div>
        {age === '1' && (living >= '1' && living <= '6' || living === '7') &&
        (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_1A/>}
        {age === '2' && (living >= '1' && living <= '6' || living === '7') &&
        (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_1B/>}
        {age === '3' && (living >= '1' && living <= '6' || living === '7') &&
        (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_1C/>}
        {age === '4' && (living >= '1' && living <= '6' || living === '7') &&
        (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_1D/>}
        {age === '4' && (living >= '1' && living <= '6' || living === '7') &&
        (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_1E/>}
        {living === '8' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_2/>}
        {living === '0' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_3/>}
      </div>
    </Card>
    <Card
      title='Things To Do Now'>
      <div>
        {age === '1' && (living >= '1' && living <= '6' || living === '7') &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_4A/>}
        {age === '2' && (living >= '1' && living <= '6' || living === '7') &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_4B/>}
        {age === '3' && (living >= '1' && living <= '6' || living === '7') &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_4C/>}
        {age === '4' && (living >= '1' && living <= '6' || living === '7') &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_4D/>}
        {age === '4' && (living >= '1' && living <= '6' || living === '7') &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_4E/>}
      </div>
      <div>
        {helpliving === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_5/>}
      </div>
      <div>
        {assistedhousing === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_6/>}
      </div>
      <div>
        {living === '8' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_7/>}
        {living === '0' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_8/>}
      </div>
    </Card>
    <Card
      title='Things To Plan For'>
      <div>
        {age === '1' && (living >= '1' && living <= '6' || living === '7') &&
        (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_9A/>}
        {age === '2' && (living >= '1' && living <= '6' || living === '7') &&
        (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_9B/>}
        {age === '3' && (living >= '1' && living <= '6' || living === '7') &&
        (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_9C/>}
        {age === '4' && (living >= '1' && living <= '6' || living === '7') &&
        (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_9D/>}
        {age === '4' && (living >= '1' && living <= '6' || living === '7') &&
        (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_9E/>}
      </div>
      <div>
        {living === '8' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_10/>}
        {living === '0' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb10_11/>}
      </div>
    </Card>
  </section>;
};

export default Housing;
