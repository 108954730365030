import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import '../../../../assets/stylesheets/pages/report.scss';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Blurb4_1A from '../../../blurbs/4_SocialCompetency/Blurb4_1A';
import Blurb4_1B from '../../../blurbs/4_SocialCompetency/Blurb4_1B';
import Blurb4_1C from '../../../blurbs/4_SocialCompetency/Blurb4_1C';
import Blurb4_1D from '../../../blurbs/4_SocialCompetency/Blurb4_1D';
import Blurb4_1E from '../../../blurbs/4_SocialCompetency/Blurb4_1E';
import Blurb4_2A from '../../../blurbs/4_SocialCompetency/Blurb4_2A';
import Blurb4_2B from '../../../blurbs/4_SocialCompetency/Blurb4_2B';
import Blurb4_2C from '../../../blurbs/4_SocialCompetency/Blurb4_2C';
import Blurb4_2D from '../../../blurbs/4_SocialCompetency/Blurb4_2D';
import Blurb4_2E from '../../../blurbs/4_SocialCompetency/Blurb4_2E';
import Blurb4_3A from '../../../blurbs/4_SocialCompetency/Blurb4_3A';
import Blurb4_3B from '../../../blurbs/4_SocialCompetency/Blurb4_3B';
import Blurb4_3C from '../../../blurbs/4_SocialCompetency/Blurb4_3C';
import Blurb4_3D from '../../../blurbs/4_SocialCompetency/Blurb4_3D';
import Blurb4_3E from '../../../blurbs/4_SocialCompetency/Blurb4_3E';
import Blurb4_4A from '../../../blurbs/4_SocialCompetency/Blurb4_4A';
import Blurb4_4B from '../../../blurbs/4_SocialCompetency/Blurb4_4B';
import Blurb4_4C from '../../../blurbs/4_SocialCompetency/Blurb4_4C';
import Blurb4_4D from '../../../blurbs/4_SocialCompetency/Blurb4_4D';
import Blurb4_4E from '../../../blurbs/4_SocialCompetency/Blurb4_4E';
import Variables from '../components/Variables';

const SocialCompetency = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {ref_reason___6} = useSelector(state => state.forms[FORMS.REFERRAL_REASON.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);

  const variables = {diagnosis, ref_reason___6, age};

  return <section>
    <SectionTitle>Social Competency</SectionTitle>
    <Variables variableObject={variables}/>
    <Card
      title='Things To Know'>
      {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_1A/>}
      {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_1B/>}
      {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_1C/>}
      {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_1D/>}
      {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_1E/>}
    </Card>
    <Card
      title='Things To Do Now'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_2A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_2B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_2C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_2D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_2E/>}
      </div>
      <div>
        {ref_reason___6 === '1' && age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_3A/>}
        {ref_reason___6 === '1' && age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_3B/>}
        {ref_reason___6 === '1' && age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_3C/>}
        {ref_reason___6 === '1' && age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_3D/>}
        {ref_reason___6 === '1' && age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_3E/>}
      </div>
    </Card>
    <Card
      title='Things To Plan For'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_4A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_4B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_4C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_4D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb4_4E/>}
      </div>
    </Card>
  </section>;
};

export default SocialCompetency;
