import React from 'react';

const Blurb3_4B = () =>
  <div>
    <p>
      During the assessment there was indication that your child may have experienced past trauma. Exposure to
      traumatic experiences can influence your child’s attachment to others, including how they perceive themselves
      and others, and their relationships with caregivers. Attachment-based therapy can help children with FASD
      to develop healthy coping skills, and support attachment relationships, family cohesion and overall wellbeing.
    </p>
  </div>;

export default Blurb3_4B;
