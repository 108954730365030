import React from 'react';

const Blurb3_4E = () =>
  <div>
    <p>
      During the assessment there was indication that you may have experienced trauma. Exposure to traumatic
      experiences can influence attachment to others, including how you/your child perceive themselves and
      others, and their relationships with caregivers and other people in your life. Attachment-based therapy
      can help you/your child develop healthy coping skills, and support attachment relationships, family
      cohesion and overall wellbeing.
    </p>
  </div>;

export default Blurb3_4E;
