import React from 'react';

const Blurb8_5E = () =>
  <div>
    <p>
      Your/your child’s assessment team indicated that they/you may be experiencing difficulties with mental
      health. Mental health difficulties can affect a person’s internal experience of emotions and thoughts,
      or external behaviours and relationships. They can also include physical symptoms, such as headaches or
      difficulty sleeping. Some challenges may be less visible to others, such as depression or anxiety, whereas
      others may impact behaviour and interactions with others, and may be more visible, such ADHD or conduct
      disorder. It is important to seek FASD-informed mental health care for you/your child to identify and
      support any specific mental health challenges.
    </p>
  </div>;

export default Blurb8_5E;
