import React from 'react';
import SectionTitle from '../components/SectionTitle';
import '../../../../assets/stylesheets/pages/report.scss';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Variables from '../components/Variables';
import Card from '../components/Card';

const AdultTearoff = () => {
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const variable = {age, diagnosis};
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS.id]);

  if (formData.adult_adolescent_tearoff) {
    return <section>
      <SectionTitle>Adult/Adolescent Tear Off Sheet</SectionTitle>
      <Variables variableObject={variable}/>
      <Card title='My Unique Needs'>
        <p>
          {formData.adult_adolescent_tearoff}
        </p>
      </Card>
    </section>;
  }

  return null;
};

export default AdultTearoff;
