import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import NoRoute from './app/pages/404';
import ROUTES from './routes';
import Home from './app/pages/Home';
import {BaseForm} from './app/pages/Form/BaseForm';
import Report from './app/pages/Report';
import {useIdleTimer} from 'react-idle-timer';
import {resetApp} from './redux/actions/app';
import {useDispatch} from 'react-redux';
import TIMEOUT from './constants/timeout';
import Topbar from './app/components/Topbar';

const App = function() {
  const [remaining, setRemaining] = useState(0);
  const dispatch = useDispatch();

  const {getRemainingTime, isPrompted} = useIdleTimer({
    timeout: TIMEOUT.INTERVAL * 1000,
    onIdle: () => {
      dispatch(resetApp());
      window.location.replace('/');
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return <main>
    <BrowserRouter>
      <Topbar/>
      <div className='grid-container'>
        <Routes>
          <Route
            element={<Home/>}
            path={ROUTES.ROOT}/>
          <Route
            element={<Report remaining={remaining}/>}
            path={ROUTES.REPORT}
          />
          <Route
            element={<BaseForm remaining={remaining}/>}
            path={ROUTES.FORMS}
          />
          <Route
            element={<NoRoute/>}
            path='*'
          />
        </Routes>
      </div>
    </BrowserRouter>
  </main>;
};

export default App;
