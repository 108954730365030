import React from 'react';

const Blurb8_13D = () =>
  <div>
    <p>
      Adults with FASD may require ongoing mental health and regulation support. This looks different for
      everyone and should be based on each person’s unique needs. Doing so helps make sure you are working
      toward a level of mental wellbeing and goals that makes sense for you. You may find it helpful to speak
      to important people in your life or your supportive networks. They can help you plan ahead and have
      opportunities to engage in therapy or build skills that will support you in coping with your unique
      mental health needs.
    </p>
  </div>;

export default Blurb8_13D;
