import React from 'react';

const Blurb2_7D = () =>
  <div>
    <p>
      You reported difficulties with sleep. People with FASD often have difficulty sleeping well. In
      turn, this can impact mood, behaviour and learning. To support your sleep, working with a
      physician and/or psychologist can help to identify additional resources to support healthy
      sleep. Cognitive behavioural therapy for insomnia (CBT-I) may be helpful. A consistent nighttime
      routine, decreasing screentime, and engaging in relaxing activities before bed are important and
      can improve overall functioning and wellbeing.
    </p>
  </div>;

export default Blurb2_7D;
