import React from 'react';
import CheckBox from '../../../components/CheckBox';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const OtherFactorsForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.OTHER_FACTORS.id]);
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    if (!updatedFormData.factors___3) {
      updatedFormData[FORMS.OTHER_FACTORS.fields.factors___3.specify_other.id] = FORMS.OTHER_FACTORS.fields.factors___3.specify_other.value;
    }

    dispatch(submitForm(FORMS.OTHER_FACTORS.id, updatedFormData));
  };

  const otherFactors = FORMS.OTHER_FACTORS.fields;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] === true);
  const isFormComplete = checkedFields.length > 0 &&
    (formData.factors___3 ? formData.factors_specify.length > 0 : true);

  return (

    <>
      <h2 className='form-header'>{FORMS.OTHER_FACTORS.header}</h2>
      <div className='form-content'>
        <form>
          <div className='grid-x grid-margin-x'>
            <div className='small-12 medium-4 large-3 cell'>
              <label className='form-field'>Other factors <span className='font-italic'>(Please check all that apply)</span></label>
            </div>
            <div className='cell medium-8 large-9'>
              <div className='form-field-value-rounded-borders padding-bottom-1'>
                <div className='grid-x small-up-1 medium-up-2'>
                  {Object.entries(otherFactors).map(([factorKey, factorValues]) =>
                    <CheckBox
                      checked={formData[factorKey]}
                      className='cell'
                      key={factorKey}
                      onChange={() => handleFormChange({...formData, [factorKey]: !formData[factorKey]})}
                      value={factorValues.title}/>)}
                </div>
                {
                  formData.factors___3 && <>
                    <label
                      htmlFor={FORMS.OTHER_FACTORS.fields.factors___3.specify_other.id}>{FORMS.OTHER_FACTORS.fields.factors___3.specify_other.title}</label>
                    <input
                      className='margin-bottom-0'
                      id={FORMS.OTHER_FACTORS.fields.factors___3.specify_other.id}
                      onChange={event => handleFormChange({...formData, [FORMS.OTHER_FACTORS.fields.factors___3.specify_other.id]: event.target.value})}
                      type='text'
                      value={formData[FORMS.OTHER_FACTORS.fields.factors___3.specify_other.id]}/>
                  </>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

OtherFactorsForm.propTypes = {
  formIndex: PropTypes.number
};

export default OtherFactorsForm;
