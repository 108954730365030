import React from 'react';

const Blurb5_14D = () =>
  <div>
    <p>
      You have been referred for specialized support related to occupational therapy. Occupational therapists
      can work with individuals with FASD, their caregiver(s), and family to identify and address functioning
      with day-to-day activities and support their success in school, work, home, and community contexts.
    </p>
  </div>;

export default Blurb5_14D;
