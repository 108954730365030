import React from 'react';

const Blurb2_3 = () =>
  <div>
    <p>
      Supports related to medications have been recommended. Medications may reduce the impact of physical
      and/or mental health needs and allow for greater engagement with learning, socializing, and other
      opportunities for growth.
    </p>
    <p>
      Through regular communication with a primary health care provider, it will be important to determine
      whether medications are working as intended, monitor for side effects, and adapt proactively through
      developmental periods. Because it can sometimes be difficult, even for care providers, to
      find the best suited medication for an individual with FASD, Dr. Mansfield Mela has produced
      a tool to help guide conversations and decision-making regarding medication
      use: <a href='https://canfasd.ca/algorithm/'>https://canfasd.ca/algorithm/</a>
    </p>
  </div>;

export default Blurb2_3;
