import React from 'react';

const Blurb2_6C = () =>
  <div>
    <p>
      Motor difficulties were reported as an area of challenge for your teen. Motor skills are required
      for our daily activities. Gross motor skills help us to walk, play sports, and exercise, while
      fine motor skills help us tie our shoes, draw, and button our shirts. Difficulties with motor
      skills can impact a teen’s ability to complete common everyday tasks – like writing their name
      or playing a sport. Motor skills can vary greatly from one person to another.. Some tasks may
      come easily, like running, while others may be difficult, like painting or riding a bike.
    </p>
    <p>
      Awareness of your teen’s motor needs can also help others working with your child to identify
      supports or accommodations in your child’s environments such as at home and at school.
      In this way, stress is reduced and access to strengths is increased. Motor functioning
      can be hard to predict sometimes. Observation can help us to understand and then provide
      appropriate support. Caregivers can be an excellent source of information in describing
      what their teen can and cannot do. With targeted intervention from clinicians, such as
      occupational therapists and physical therapists, motor development and functioning can
      be supported, and improvement is possible.
    </p>
  </div>;

export default Blurb2_6C;
