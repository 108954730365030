import React from 'react';

const Blurb1_3A = () =>
  <div>
    <p>
      Early intervention is important to support your child’s daily needs and behaviours. The information gathered
      at the time of your child’s FASD assessment was used to develop an intervention plan designed to support your
      child’s unique difficulties and strengths.
    </p>
    <p>
      Prenatal alcohol exposure (PAE) can affect the brains and bodies of people in different ways. Your child’s
      needs indicate that they have Fetal Alcohol Spectrum Disorder (FASD) without Sentinel Facial Features. This
      means that while some individuals may have physical features that are unique to PAE, other individuals like
      your child, do not. These features do not necessarily provide information about your child’s unique brain- or
      body-based needs. Through individualized, ongoing supports, individuals with FASD can experience success and
      achieve healthy outcomes.
    </p>
  </div>;

export default Blurb1_3A;
