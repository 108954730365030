import React from 'react';

const Blurb5_13D = () =>
  <div>
    <p>
      You have been referred for specialized support related to speech and language. Speech language pathologists
      work with a variety of clients and can help with many areas of speech and language including fluency
      disorders, cognitive communication, voice, swallowing, fluency, and other related disorders. It will
      be important for you to work with a speech and language specialist who is FASD-informed.
    </p>
  </div>;

export default Blurb5_13D;
