import React from 'react';

const Blurb5_1D = () =>
  <div>
    <p>
      Education is not limited to the school years alone. There are many ways adults can seek out education
      opportunities including participating in training for jobs and taking courses to learn new skills
      such as a trade. For adults with FASD, like you, it is possible to learn and grow outside of your
      school years. Finding opportunities for learning that allow you to reach your goals and establish
      purpose in your daily life can be important for your overall wellbeing.
    </p>
  </div>;

export default Blurb5_1D;
