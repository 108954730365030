import React from 'react';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';

const MetaHelmet = ({title, description}) =>
  <Helmet prioritizeSeoTags>
    <link
      href={window.location.href}
      rel='Canonical'/>
    <title>{title}</title>
    {description && <meta
      content={description}
      name='description'/>}
    <meta
      content={window.location.href}
      property='og:url'/>
    <meta
      content={title}
      property='og:title'/>
    {description && <meta
      content={description}
      property='og:description'/>}
    <meta
      content={window.location.href}
      property='twitter:url'/>
    <meta
      content={title}
      property='twitter:title'/>
    {description && <meta
      content={description}
      property='twitter:description'/>}
  </Helmet>;

export default MetaHelmet;

MetaHelmet.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired
};
