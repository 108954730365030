import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import forms from './forms';
import formProgress from './formProgress';
import {APP_RESET} from '../constants';

const createRootReducer = history => {
  const rootReducerFn = combineReducers({
    router: connectRouter(history),
    forms,
    formProgress
  });

  return (state, action) => {
    if (action.type !== APP_RESET) {
      return rootReducerFn(state, action);
    }

    return rootReducerFn({}, action);
  };
};

export default createRootReducer;
