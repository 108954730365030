import React from 'react';

const Blurb5_12E = () =>
  <div>
    <p>
      Your/your child’s assessment team has recommended that you/your child receive educational re-assessment
      as they progress through the education system. Targeted intervention support to address challenges with
      learning and academic achievement is possible with up to date understanding of areas of strengths and needs.
    </p>
  </div>;

export default Blurb5_12E;
