import React from 'react';

const Blurb4_2D = () =>
  <div>
    <p>
      Adults with FASD often have difficulties interacting socially with others because of brain-based differences
      that can make it more difficult to regulate emotions, read social cues, and interact with others. This can be
      stressful because adults with FASD, like everyone, often value connections and relationships. With specialized
      intervention and consistent social interactions, you can improve your social skills, make and maintain
      friendships, and become better at regulating emotions across social contexts. Your relational strengths
      which may include the ability to reciprocate affection, friendliness, and the desire to please others may
      be leveraged in the development of social skills.
    </p>
  </div>;

export default Blurb4_2D;
