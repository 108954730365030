import React from 'react';

const Blurb10_2 = () =>
  <div>
    <p>Independent living can look different for everyone. Some people can access housing and complete daily
      living tasks with little to no support. For others, specific assistance may be needed to ensure they
      can maintain safe housing. Individuals with FASD may benefit from access supports such as housing and
      financial assistance, along with daily living supports, such as reminders around paying rent and bills,
      and assistance with daily tasks such as buying groceries, cooking, and cleaning. It may be helpful to talk
      to your local FASD clinic or advocacy group to find out what resources are available in your area.
    </p>
  </div>;

export default Blurb10_2;
