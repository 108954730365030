import React from 'react';

const Blurb1_1 = () =>
  <div>
    <p>
      Welcome! A specialized FASD assessment provides information about diagnosis, unique areas of strength and
      difficulty, and recommendations for support. This COMPASS report aims to support planning and communicating
      information from this assessment using the Towards Healthy Outcomes framework, a model that helps organize
      recommendations according to developmental needs across the lifespan. For more information the Towards Healthy
      Outcomes document can be found at: <a href='https://canfasd.ca/publications/towards-healthy-outcomes/'>
      https://canfasd.ca/publications/towards-healthy-outcomes/</a>. If you have questions about this report,
      please get in touch with your FASD support team.
    </p>
  </div>;

export default Blurb1_1;
