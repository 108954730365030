import React from 'react';

const Blurb1_4B = () =>
  <div>
    <p>
      Early and tailored intervention is important to support your child’s daily needs and behaviours.
      The information gathered at the time of your child’s FASD assessment was used to develop an
      intervention plan designed to support your child’s unique difficulties and strengths.
    </p>
    <p>
      Based on the assessment results, your child did not meet diagnostic criteria for Fetal Alcohol Spectrum
      Disorder (FASD). Individuals who do not meet the diagnostic criteria for FASD but have confirmed prenatal
      alcohol exposure (PAE) and early signs of developmental concerns can be designated as at risk for
      neurodevelopmental disorder and FASD, associated with prenatal alcohol exposure. This is not a diagnosis
      of FASD. This designation is meant to support early intervention and justifies a multidisciplinary
      re-assessment at a future point, such as at an older age (e.g., school age) to determine if the diagnostic
      criteria for FASD are met. Although the COMPASS report focuses on those diagnosed with FASD,
      the information provided is useful for supporting healthy outcomes for all children. Through individualized,
      ongoing supports, individuals can experience success and achieve healthy outcomes.
    </p>
  </div>;

export default Blurb1_4B;
