import React from 'react';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import SectionTitle from '../components/SectionTitle';
import Variables from '../components/Variables';
import Blurb16_1B from '../../../blurbs/16_JusticeTearoff/Blurb16_1B';
import Blurb16_1C from '../../../blurbs/16_JusticeTearoff/Blurb16_1C';
import Blurb16_1D from '../../../blurbs/16_JusticeTearoff/Blurb16_1D';
import Blurb16_1E from '../../../blurbs/16_JusticeTearoff/Blurb16_1E';
import Blurb16_2B from '../../../blurbs/16_JusticeTearoff/Blurb16_2B';
import Blurb16_2C from '../../../blurbs/16_JusticeTearoff/Blurb16_2C';
import Blurb16_2D from '../../../blurbs/16_JusticeTearoff/Blurb16_2D';
import Blurb16_2E from '../../../blurbs/16_JusticeTearoff/Blurb16_2E';
import Blurb16_3B from '../../../blurbs/16_JusticeTearoff/Blurb16_3B';
import Blurb16_3C from '../../../blurbs/16_JusticeTearoff/Blurb16_3C';
import Blurb16_3D from '../../../blurbs/16_JusticeTearoff/Blurb16_3D';
import Blurb16_3E from '../../../blurbs/16_JusticeTearoff/Blurb16_3E';
import Blurb16_4B from '../../../blurbs/16_JusticeTearoff/Blurb16_4B';
import Blurb16_4C from '../../../blurbs/16_JusticeTearoff/Blurb16_4C';
import Blurb16_4D from '../../../blurbs/16_JusticeTearoff/Blurb16_4D';
import Blurb16_4E from '../../../blurbs/16_JusticeTearoff/Blurb16_4E';
import Blurb16_5E from '../../../blurbs/16_JusticeTearoff/Blurb16_5E';
import Blurb16_5B from '../../../blurbs/16_JusticeTearoff/Blurb16_5B';
import Blurb16_5C from '../../../blurbs/16_JusticeTearoff/Blurb16_5C';
import Blurb16_5D from '../../../blurbs/16_JusticeTearoff/Blurb16_5D';
import Blurb16_6B from '../../../blurbs/16_JusticeTearoff/Blurb16_6B';
import Blurb16_6C from '../../../blurbs/16_JusticeTearoff/Blurb16_6C';
import Blurb16_6D from '../../../blurbs/16_JusticeTearoff/Blurb16_6D';
import Blurb16_6E from '../../../blurbs/16_JusticeTearoff/Blurb16_6E';
import Blurb16_7B from '../../../blurbs/16_JusticeTearoff/Blurb16_7B';
import Blurb16_7D from '../../../blurbs/16_JusticeTearoff/Blurb16_7D';
import Blurb16_7C from '../../../blurbs/16_JusticeTearoff/Blurb16_7C';
import Blurb16_7E from '../../../blurbs/16_JusticeTearoff/Blurb16_7E';
import Blurb16_8B from '../../../blurbs/16_JusticeTearoff/Blurb16_8B';
import Blurb16_8C from '../../../blurbs/16_JusticeTearoff/Blurb16_8C';
import Blurb16_8D from '../../../blurbs/16_JusticeTearoff/Blurb16_8D';
import Blurb16_8E from '../../../blurbs/16_JusticeTearoff/Blurb16_8E';
import Blurb16_9B from '../../../blurbs/16_JusticeTearoff/Blurb16_9B';
import Blurb16_9C from '../../../blurbs/16_JusticeTearoff/Blurb16_9C';
import Blurb16_9D from '../../../blurbs/16_JusticeTearoff/Blurb16_9D';
import Blurb16_9E from '../../../blurbs/16_JusticeTearoff/Blurb16_9E';
import Card from '../components/Card';

const JusticeTearoff = () => {
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {legalaid, familycourt, civilcourt} = useSelector(state => state.forms[FORMS.RECOMMENDATIONS.id]);
  const {offender, custody, specialcourts, regularcourts, incarceration} = useSelector(state => state.forms[FORMS.EXPERIENCE.id]);
  const {ref_reason___2} = useSelector(state => state.forms[FORMS.REFERRAL_REASON.id]);
  const variable = {age, diagnosis, ref_reason___2, legalaid, familycourt, civilcourt, offender,
    custody, specialcourts, regularcourts, incarceration};
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS.id]);

  return (
    <section>
      <SectionTitle>Justice Tear Off Sheet</SectionTitle>
      <Variables variableObject={variable}/>
      <Card title='Justice'>
        <div>
          {age === '2' && (ref_reason___2 === '1' || legalaid === '1' || offender === '1' ||
            custody === '1' || familycourt === '1' || specialcourts === '1' || regularcourts === '1' ||
            civilcourt === '1' || incarceration === '1') && <Blurb16_1B/>}
          {age === '3' && (ref_reason___2 === '1' || legalaid === '1' || offender === '1' ||
            custody === '1' || familycourt === '1' || specialcourts === '1' || regularcourts === '1' ||
            civilcourt === '1' || incarceration === '1') && <Blurb16_1C/>}
          {age === '4' && (ref_reason___2 === '1' || legalaid === '1' || offender === '1' ||
            custody === '1' || familycourt === '1' || specialcourts === '1' || regularcourts === '1' ||
            civilcourt === '1' || incarceration === '1') && <Blurb16_1D/>}
          {age === '5' && (ref_reason___2 === '1' || legalaid === '1' || offender === '1' ||
            custody === '1' || familycourt === '1' || specialcourts === '1' || regularcourts === '1' ||
            civilcourt === '1' || incarceration === '1') && <Blurb16_1E/>}
        </div>
        {
          formData.justice_tearoff && <p>
            {formData.justice_tearoff}
          </p>
        }
        <div>
          {ref_reason___2 === '1' && age === '2' && <Blurb16_2B/>}
          {ref_reason___2 === '1' && age === '3' && <Blurb16_2C/>}
          {ref_reason___2 === '1' && age === '4' && <Blurb16_2D/>}
          {ref_reason___2 === '1' && age === '5' && <Blurb16_2E/>}
        </div>
        <div>
          {legalaid === '1' && age === '2' && <Blurb16_3B/>}
          {legalaid === '1' && age === '3' && <Blurb16_3C/>}
          {legalaid === '1' && age === '4' && <Blurb16_3D/>}
          {legalaid === '1' && age === '5' && <Blurb16_3E/>}
        </div>
        <div>
          {offender === '1' && age === '2' && <Blurb16_4B/>}
          {offender === '1' && age === '3' && <Blurb16_4C/>}
          {offender === '1' && age === '4' && <Blurb16_4D/>}
          {offender === '1' && age === '5' && <Blurb16_4E/>}
        </div>
        <div>
          {custody === '1' || familycourt === '1' && age === '2' && <Blurb16_5B/>}
          {custody === '1' || familycourt === '1' && age === '3' && <Blurb16_5C/>}
          {custody === '1' || familycourt === '1' && age === '4' && <Blurb16_5D/>}
          {custody === '1' || familycourt === '1' && age === '5' && <Blurb16_5E/>}
        </div>
        <div>
          {specialcourts === '1' && age === '2' && <Blurb16_6B/>}
          {specialcourts === '1' && age === '3' && <Blurb16_6C/>}
          {specialcourts === '1' && age === '4' && <Blurb16_6D/>}
          {specialcourts === '1' && age === '5' && <Blurb16_6E/>}
        </div>
        <div>
          {regularcourts === '1' && age === '2' && <Blurb16_7B/>}
          {regularcourts === '1' && age === '3' && <Blurb16_7C/>}
          {regularcourts === '1' && age === '4' && <Blurb16_7D/>}
          {regularcourts === '1' && age === '5' && <Blurb16_7E/>}
        </div>
        <div>
          {civilcourt === '1' && age === '2' && <Blurb16_8B/>}
          {civilcourt === '1' && age === '3' && <Blurb16_8C/>}
          {civilcourt === '1' && age === '4' && <Blurb16_8D/>}
          {civilcourt === '1' && age === '5' && <Blurb16_8E/>}
        </div>
        <div>
          {incarceration === '1' && age === '2' && <Blurb16_9B/>}
          {incarceration === '1' && age === '3' && <Blurb16_9C/>}
          {incarceration === '1' && age === '4' && <Blurb16_9D/>}
          {incarceration === '1' && age === '5' && <Blurb16_9E/>}
        </div>
      </Card>
    </section>
  );
};

export default JusticeTearoff;
