import React from 'react';

const Blurb1_2A = () =>
  <div>
    <p>
      Early intervention is important to support your child’s daily needs and behaviours. The information gathered
      at the time of your child’s FASD assessment was used to develop an intervention plan designed to support your
      child’s unique difficulties and strengths.
    </p>
    <p>
      Prenatal alcohol exposure (PAE) impacts to the brains and bodies of people in different ways. Your child’s
      assessment indicated that they have Fetal Alcohol Spectrum Disorder (FASD) with Sentinel Facial Features.
      Some individuals with FASD, like your child, have distinct physical features that are unique to PAE. However,
      these features do not necessarily provide information about your child’s unique brain- or body-based needs.
      Through individualized, ongoing supports individuals with FASD can experience success and achieve healthy
      outcomes.
    </p>
  </div>;

export default Blurb1_2A;
