import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import CheckBox from '../../../components/CheckBox';
import PropTypes from 'prop-types';

const ReferralReasonForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.REFERRAL_REASON.id]);

  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    if (formData[FORMS.REFERRAL_REASON.fields.ref_reason___11.specify_other.condition] && !updatedFormData.ref_reason___11) {
      updatedFormData.ref_reasonspecify = FORMS.REFERRAL_REASON.fields.ref_reason___11.specify_other.value;
    }

    dispatch(submitForm(FORMS.REFERRAL_REASON.id, updatedFormData));
  };

  const referralReasons = FORMS.REFERRAL_REASON.fields;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] === true);
  const isFormComplete = checkedFields.length > 0 && (formData.ref_reason___11 ? formData.ref_reasonspecify : true);

  return (

    <>
      <h2 className='form-header'>{FORMS.REFERRAL_REASON.header}</h2>
      <div className='form-content'>
        <form>
          <div className='grid-x grid-margin-x'>
            <div className='small-12 medium-4 large-3 cell'>
              <label className='form-field'>Reason for Referral</label>
            </div>
            <div className='cell medium-8 large-9'>
              <div className='form-field-value-rounded-borders'>
                <div className='padding-bottom-1'>
                  {Object.entries(referralReasons).map(([reasonKey, reasonValues]) =>
                    <CheckBox
                      checked={formData[reasonKey]}
                      key={reasonKey}
                      onChange={() => handleFormChange({...formData, [reasonKey]: !formData[reasonKey]})}
                      value={reasonValues.title}/>)}
                </div>
                {
                  formData.ref_reason___11 && <>
                    <label
                      htmlFor={FORMS.REFERRAL_REASON.fields.ref_reason___11.specify_other.id}>
                      {FORMS.REFERRAL_REASON.fields.ref_reason___11.specify_other.title}
                    </label>
                    <input
                      id={FORMS.REFERRAL_REASON.fields.ref_reason___11.specify_other.id}
                      onChange={event => handleFormChange({...formData, [FORMS.REFERRAL_REASON.fields.ref_reason___11.specify_other.id]: event.target.value})}
                      type='text'
                      value={formData.ref_reasonspecify}/>
                  </>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

ReferralReasonForm.propTypes = {
  formIndex: PropTypes.number
};

export default ReferralReasonForm;
