import React from 'react';

const Blurb3_3A = () =>
  <div>
    <p>
      Your child has encountered one or more disrupted attachment experiences. Early childhood is an important
      time for intervention and can foster healthy attachment and relationship outcomes in adolescence and
      adulthood. Even in circumstances where attachment is disrupted, it is possible to re-establish secure
      and strong relationships at all developmental periods.
    </p>
    <p>
      Providing support that specifically focuses on your child’s relationship with their caregiver(s) may be
      an effective way to help your child learn to read and respond to cues. This targeted support can also
      help identify appropriate ways to respond to your child’s cues based on their individual needs and in
      ways that make sense to them.
    </p>
    <p>
      Early behavioural interventions, such as parent-child interaction therapy (PCIT), that focus on helping
      caregivers understand their child’s unique brain, balance child and caregiver needs, and adjust behaviours
      can support the development of more secure attachment bonds between children with FASD and their caregiver(s).
    </p>
  </div>;

export default Blurb3_3A;
