import React from 'react';

const Blurb10_4D = () =>
  <div>
    <p>
      When planning to find and keep safe and affordable housing, it may be helpful for you, your family/caregiver(s),
      and service providers to work together. This collaboration can help identify and prioritize your own unique
      housing needs, preferences, and goals. It is important to consider a variety of factors such as your unique
      strengths and needs, life experiences, and social supports to help create safe, secure, and supportive
      housing environments.
    </p>
  </div>;

export default Blurb10_4D;
