import React from 'react';

const Blurb5_2D = () =>
  <div>
    <p>
      Providing access to information regarding your unique needs may further facilitate the identification
      of approaches best suited to you in any learning environment you may find yourself in. This may include
      advocating or sharing FASD education resources for your instructors and other learning supports to build
      educator skills and create well suited environment in which you can learn and develop skills. The Canada
      FASD Research Network offers free online FASD resources and training: <a href='https://estore.canfasd.ca/foundations-in-fasd'>https://estore.canfasd.ca/foundations-in-fasd</a>
    </p>
  </div>;

export default Blurb5_2D;
