import React from 'react';

const Blurb7_23C = () =>
  <div>
    <p>
     The assessment team recommended that supports to increase your teen’s safety may be helpful.
    </p>
    <p>
      Safety is essential for both physical and mental well-being. It will be important to help your teen
      foster a sense of safety through trusting, consistent relationships and safety supports in their daily
      life. Creating a stable, caring, and dependable environment for your teen will enhance their sense of
      safety, connectedness, and autonomy as they grow and develop over time.
    </p>
  </div>;

export default Blurb7_23C;
