import React from 'react';

const Blurb11_3C = () =>
  <div>
    <p>
      Should your teen become a parent, or if they are already a parentlike any aspect of life, it will be
      important to support their parenting journey and help ensure that the services put in place help them
      develop their parenting skills. Potential supports to consider include in-home services, advocacy,
      counselling, education, and pregnancy programs that leverage and support their unique strengths and needs.
      If your teen’s sense of self-advocacy as a parent and as an advocate for their children are encouraged early
      on, environmental supports can be reduced.
    </p>
    <p>
      Individuals with FASD often have difficulties with self-regulation. Though rewarding, raising children can
      also be unpredictable and stressful, making it that harder to control emotions and behaviour when
      self-regulation is already difficult. Parents with FASD may benefit from building skills to help stay
      calm under pressure, while also being flexible and supportive in response to changing circumstances. It
      will also be helpful to encourage self-care for your teen with FASD throughout their parenting journey, so
      they are able to provide the best possible care for their children. This may look like specific mental health
      and parenting supports for themselves, access to support groups, engaging in self-care activities, or finding
      hobbies they can do with their children.
    </p>
  </div>;

export default Blurb11_3C;
