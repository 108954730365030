import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import PropTypes from 'prop-types';
import MonthYear from '../../../components/MonthYear';
import {FormNav} from '../../../components/FormNav';

const ReferralDateForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.REFERRAL_DATE.id]);
  const isFormComplete = formData.ref_yr !== '' && formData.ref_mth !== '';
  const dispatch = useDispatch();
  const referralYearOptions = FORMS.REFERRAL_DATE.fields.ref_yr.options;
  const referralMonthOptions = FORMS.REFERRAL_DATE.fields.ref_mth.options;

  const handleFormChange = dateVal => {
    dispatch(submitForm(FORMS.REFERRAL_DATE.id, {...formData, ref_mth: dateVal.ref_mth, ref_yr: dateVal.ref_yr}));
  };

  return (
    <>
      <h2 className='form-header'>{FORMS.REFERRAL_DATE.header}</h2>
      <div className='form-content'>
        <MonthYear
          formData={formData}
          monthId='ref_mth'
          monthOptions={referralMonthOptions}
          onChange={handleFormChange}
          title='Date of referral'
          yearId='ref_yr'
          yearOptions={referralYearOptions}
        />
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

ReferralDateForm.propTypes = {
  formIndex: PropTypes.number
};

export default ReferralDateForm;
