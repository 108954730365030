import React from 'react';

const Blurb2_9 = () =>
  <div>
    <p>
      Physical or structural differences present since birth were identified. This may add to difficulties
      experienced in daily functioning. It will be important to address treatment and support options with
      a primary care provider.
    </p>
  </div>;

export default Blurb2_9;
