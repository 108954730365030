import React from 'react';

const Blurb10_8 = () =>
  <div>
    <p>
      Living independently, when individuals are capable, often leads to increased feelings of autonomy, choice,
      control, freedom, and equality. Independent living can look different for everyone. Some people are able
      to access housing and complete daily living tasks with little to no Support. For others, more directive
      supports may be necessary to ensure that safe housing is maintained. Independent living can be supported
      in various ways, such as through helping to develop skills related to time management, personal self-care,
      food preparation, and financial knowledge.
    </p>
  </div>;

export default Blurb10_8;
