import React from 'react';

const Blurb4_3C = () =>
  <div>
    <p>
      The assessment team indicated that your teen was referred for social skills difficulties. There are many
      approaches you can take to support your child to improve their social abilities. Directly teaching social
      skills through modeling, conversations, and instruction, can be a protective factor as your teen navigates
      potential negative peer influences and establishes long lasting, prosocial friendships.
    </p>
  </div>;

export default Blurb4_3C;
