import React from 'react';

const Blurb4_3E = () =>
  <div>
    <p>
      The assessment team indicated that you/your child were/was referred for social skills supports. For children
      and teens, Directly teaching social skills through modeling, conversations, and instruction, can be a
      protective factor as your child navigates potential negative peer influences and establishes long lasting,
      prosocial friendships. For adults, mentorship programming has been identified as one way to assist with
      social skill development for adults with FASD, in a relational way, often while engaging in desired
      activities. Doing so can serve as a protective factor and support you in building positive friendships.
    </p>
  </div>;

export default Blurb4_3E;
