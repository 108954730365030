import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = ({label, value, onChange, checked, className}) =>
  <div className={className}>
    <div className='grid-x margin-bottom-1'>
      <div className='shrink cell'><input
        checked={checked}
        id={`answer_${label}`}
        onChange={onChange}
        type='radio'
        value={value}
      />
      </div>
      <div className='auto cell'>
        <label
          className='margin-right-1'
          htmlFor={`answer_${label}`}>{label}</label>
      </div>
    </div>
  </div>;

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default RadioButton;
