import React from 'react';

const Blurb2_7E = () =>
  <div>
    <p>
      Sleep difficulties were reported for you/your child. People with FASD often have difficulty sleeping
      well. In turn, this can impact mood, behaviour and learning. To support sleep, working with a physician
      and/or psychologist can help to identify additional resources to support healthy sleep. A consistent
      nighttime routine, decreasing screentime, and engaging in relaxing activities before bed are important
      and can improve overall functioning and wellbeing.
    </p>
    <p>
      For children, Dr. Hanlon-Dearman and colleagues have developed a Better Days/Night program for
      children with FASD. Information about the program and resources can be found here: <a href='https://ndd.betternightsbetterdays.ca/'>https://ndd.betternightsbetterdays.ca/</a>
    </p>
    <p>
      For adolescents and adults, cognitive behavioural therapy for insomnia (CBT-I) may be helpful.
    </p>
  </div>;

export default Blurb2_7E;
