import React from 'react';

const Blurb11_1C = () =>
  <div>
    <p>
      Parenting may be in the future for your teen, or they may already be a parent. Raising a child through
      parenting can be both rewarding and challenging and requires consistency, strength, and resilience. Many
      parents need support, including parents with FASD. With appropriate supports, all people with FASD can
      be successful and experience rewards in their role as a parent.
    </p>
  </div>;

export default Blurb11_1C;
