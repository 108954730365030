import React from 'react';

const Blurb4_3A = () =>
  <div>
    <p>
      The assessment team indicated that your child was referred for social skills difficulties. There are many
      approaches you can take to support your child to improve their social abilities. Directly teaching social
      skills through modelling, conversations and play in early childhood can act as a protective factor and may
      also foster overall-wellbeing.
    </p>
  </div>;

export default Blurb4_3A;
