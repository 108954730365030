import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import getKeyByValue from '../../../utils/getKeyByValue';

const MedicalHistoryForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.MEDICAL_HISTORY.id]);
  const dispatch = useDispatch();
  const medicalHistoryFields = FORMS.MEDICAL_HISTORY.fields;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] !== '');
  const isFormComplete = Object.keys(medicalHistoryFields).filter(key => key !== 'medicalother').every(field => checkedFields.includes(field)) &&
    formData.growth_specify.length > 0 &&
    (formData.neurological === '1' ? formData.neurological_specify.length > 0 : true) &&
    (formData.mental === '1' ? formData.mental_specify.length > 0 : true) &&
    (formData.headneck === '1' ? formData.headneck_specify.length > 0 : true) &&
    (formData.cardiovascular === '1' ? formData.cardiovascular_specify.length > 0 : true) &&
    (formData.respiratory === '1' ? formData.respiratory_specify.length > 0 : true) &&
    (formData.endocrine === '1' ? formData.endocrine_specify.length > 0 : true) &&
    (formData.musculoskeletal === '1' ? formData.musculoskeletal_specify.length > 0 : true) &&
    (formData.infectiousdiseases === '1' ? formData.infectiousdiseases_specify.length > 0 : true) &&
    (formData.medicalother === '1' ? formData.medicalother_specify.length > 0 : true);

  const handleFormChange = (updatedFormData, fieldId) => {
    if (fieldId &&
      medicalHistoryFields[fieldId].specify_other &&
      formData[fieldId] !== updatedFormData[fieldId]) {
      updatedFormData[medicalHistoryFields[fieldId].specify_other.id] = medicalHistoryFields[fieldId].specify_other.value;
    }

    dispatch(submitForm(FORMS.MEDICAL_HISTORY.id, updatedFormData));
  };

  const renderOptions = (optionsObject, fieldKey) => {
    if (optionsObject[2]) {
      return <>
        <div
          className='cell text-center'
          key='2'>
          <input
            checked={formData[fieldKey] === '2'}
            className='margin-right-0'
            id='answer_2'
            name={fieldKey}
            onChange={event => handleFormChange({...formData, [fieldKey]: event.target.value}, fieldKey)}
            type='radio'
            value='2'
          />
        </div>
        <div
          className='cell text-center'
          key='1'>
          <input
            checked={formData[fieldKey] === '1'}
            className='margin-right-0'
            id='answer_1'
            name={fieldKey}
            onChange={event => handleFormChange({...formData, [fieldKey]: event.target.value}, fieldKey)}
            type='radio'
            value='1'
          />
        </div>
      </>;
    }

    return Object.keys(optionsObject).map(optionKey => <div
      className='cell text-center'
      key={optionKey}>
      <input
        checked={formData[fieldKey] === optionKey}
        className='margin-right-0'
        id={`answer_${optionKey}`}
        name={fieldKey}
        onChange={event => handleFormChange({...formData, [fieldKey]: event.target.value}, fieldKey)}
        type='radio'
        value={optionKey}
      />
    </div>);
  };

  const renderSpecifyField = specifyObject => <div
    className='small-12'
    key={specifyObject.id}>
    <label
      htmlFor={specifyObject.id}>{specifyObject.title}</label>
    <input
      id={specifyObject.id}
      onChange={event => handleFormChange({...formData, [specifyObject.id]: event.target.value})}
      type='text'
      value={formData[specifyObject.id]}/>
  </div>;

  const canRenderSpecifyField = (object, fieldKey) => {
    if (object.condition) {
      return formData[fieldKey] === getKeyByValue(medicalHistoryFields[fieldKey].options, 'Yes') && renderSpecifyField(object);
    }

    return renderSpecifyField(object);
  };

  return (
    <>
      <h2 className='form-header'>{FORMS.MEDICAL_HISTORY.header}</h2>
      <div className='form-content'>
        <form>
          <label className='form-field padding-bottom-2 note'>Note: Please select at least one/all that apply.</label>
          <div className='grid-x grid-margin-x show-for-medium'>
            <div className='small-12 medium-4 large-3 cell'/>
            {
              Object.values(medicalHistoryFields.growth.options).map(fieldOption =>
                <div
                  className='auto cell text-center'
                  key={fieldOption}>
                  <label className='form-field'>{fieldOption}</label>
                </div>)
            }
          </div>
          <div className='padding-bottom-1'>
            {Object.keys(medicalHistoryFields).map(fieldKey =>
              <div
                className='grid-x grid-margin-x padding-bottom-1'
                key={fieldKey} >
                <div className='small-12 medium-4 large-3 cell'>
                  <label className='form-field'>{medicalHistoryFields[fieldKey].title}</label>
                </div>
                <div className='cell medium-8 large-9 align-self-stretch'>
                  <div className='form-field-value grid-x small-up-2 padding-bottom-1 height-100 align-middle round-bottom-borders round-top-borders'>
                    {
                      renderOptions(medicalHistoryFields[fieldKey].options, fieldKey)
                    }
                    {medicalHistoryFields[fieldKey].specify_other &&
                      canRenderSpecifyField(medicalHistoryFields[fieldKey].specify_other, fieldKey)}
                  </div>
                </div>
              </div>)}
          </div>
        </form>
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

MedicalHistoryForm.propTypes = {
  formIndex: PropTypes.number
};

export default MedicalHistoryForm;
