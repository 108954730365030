import React from 'react';

const Blurb1_3D = () =>
  <div>
    <p>
      Your support team expressed concern about your ability to manage your daily needs and behaviours.
      The information gathered at the time of your teen’s FASD assessment was used to develop an intervention
      plan designed to support your unique difficulties and strengths.
    </p>
    <p>
      Prenatal alcohol exposure (PAE) can affect the brains and bodies of people in different ways. Your needs
      indicate that you have Fetal Alcohol Spectrum Disorder (FASD) without Sentinel Facial Features. This
      means that while some individuals may have physical features that are unique to PAE, other individuals
      like you, do not. These features do not necessarily provide information about your unique brain- or
      body-based needs. Through individualized, ongoing supports, individuals with FASD can experience success
      and achieve healthy outcomes. Through individualized, ongoing supports, individuals with FASD can experience
      success and achieve healthy outcomes.
    </p>
  </div>;

export default Blurb1_3D;
