import React from 'react';

const Blurb5_3D = () =>
  <div>
    <p>
      Your intellectual functioning has been assessed and identified as an area of need. This means that
      compared with others your age, how you learn, reason, use language, and solve problems may be
      different, and this may in turn impact experiences of success in learning. To support your learning,
      ongoing intervention, modified programming, and appropriate accommodations and strategies, will be
      important. Identified needs in this area may also be part of an individualized support plan where unique
      learning needs and accommodations are outlined.
    </p>
  </div>;

export default Blurb5_3D;
