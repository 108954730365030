import React from 'react';

const Blurb2_7C = () =>
  <div>
    <p>
      Sleep difficulties were reported for your teen. People with FASD often have difficulty sleeping well.
      In turn, this can impact mood, behaviour, and learning. As you support your teen’s sleep, working with
      a physician and/or psychologist can help to identify additional resources to support healthy sleep.
      Cognitive behavioural therapy for insomnia (CBT-I) may be helpful and has been adapted for adolescents.
      A consistent nighttime routine is important and can improve overall functioning and wellbeing.
    </p>
  </div>;

export default Blurb2_7C;
