import React from 'react';

const Blurb7_22C = () =>
  <div>
    <p>
      Your teen’s assessment team recommended that accessing community resources such as coaching may
      be helpful for your teen. Coaching provides the opportunity for teens to develop a sense of
      community belonging and connection to others, while also continuing to support growth of
      adaptive skills. Learning by doing is often relationally based and can draw on this common
      area of strength among people with FASD.  Community engagement through social and recreational
      activities can also support your teen’s abilities to create and maintain social relationships
      and regulate their feelings.
    </p>
  </div>;

export default Blurb7_22C;
