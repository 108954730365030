import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import '../../../../assets/stylesheets/pages/report.scss';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Blurb9_1A from '../../../blurbs/9_Employment/Blurb9_1A';
import Blurb9_1B from '../../../blurbs/9_Employment/Blurb9_1B';
import Blurb9_1C from '../../../blurbs/9_Employment/Blurb9_1C';
import Blurb9_1D from '../../../blurbs/9_Employment/Blurb9_1D';
import Blurb9_1E from '../../../blurbs/9_Employment/Blurb9_1E';
import Blurb9_2A from '../../../blurbs/9_Employment/Blurb9_2A';
import Blurb9_2B from '../../../blurbs/9_Employment/Blurb9_2B';
import Blurb9_2C from '../../../blurbs/9_Employment/Blurb9_2C';
import Blurb9_2D from '../../../blurbs/9_Employment/Blurb9_2D';
import Blurb9_2E from '../../../blurbs/9_Employment/Blurb9_2E';
import Blurb9_4A from '../../../blurbs/9_Employment/Blurb9_4A';
import Blurb9_3 from '../../../blurbs/9_Employment/Blurb9_3';
import Blurb9_4B from '../../../blurbs/9_Employment/Blurb9_4B';
import Blurb9_4C from '../../../blurbs/9_Employment/Blurb9_4C';
import Blurb9_4D from '../../../blurbs/9_Employment/Blurb9_4D';
import Blurb9_4E from '../../../blurbs/9_Employment/Blurb9_4E';
import Variables from '../components/Variables';

const Employment = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {employment} = useSelector(state => state.forms[FORMS.EXPERIENCE.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const variables = {diagnosis, employment, age};

  return <section>
    <SectionTitle>Employment</SectionTitle>
    <Variables variableObject={variables}/>
    <Card
      title='Things To Know'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_1A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_1B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_1C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_1D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_1E/>}
      </div>
    </Card>
    <Card
      title='Things To Do Now'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_2A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_2B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_2C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_2D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_2E/>}
      </div>
      <div>
        {employment === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_3/>}
      </div>
    </Card>
    <Card
      title='Things To Plan For'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_4A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_4B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_4C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_4D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb9_4E/>}
      </div>
    </Card>
  </section>;
};

export default Employment;
