import React from 'react';

const Blurb4_2A = () =>
  <div>
    <p>
      Children with FASD often have difficulties with social skills because brain-based differences can make it
      more challenging to regulate emotions, read social cues, and interact positively with others in social
      settings. This can be stressful for children with FASD who often value connections and relationships - like
      we all do. With appropriate interventions, children with FASD can work towards building their social skills
      to help them engage with their peers appropriately, make friends, and explore new social settings and
      environments. Your child’s relational strengths which may include the ability to reciprocate affection,
      friendliness, and the desire to please others may be leveraged in the development of social skills.
    </p>
  </div>;

export default Blurb4_2A;
