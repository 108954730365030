import React from 'react';

const Blurb10_9E = () =>
  <div>
    <p>
      Housing is a lifelong need, with related supports, accommodations, and strategies changing across the
      lifespan. Identifying your/your child’s unique areas of strengths and needs, recognizing when supports
      are needed, and creating opportunities to safely practice appropriate skills will be important to ensuring
      that you/your child are able to prepare for, obtain, and maintain safe, affordable housing.
    </p>
  </div>;

export default Blurb10_9E;
