import React from 'react';

const Blurb7_23B = () =>
  <div>
    <p>
      The assessment team recommended that supports to increase your child’s safety may be helpful.
    </p>
    <p>
      Safety is essential for both physical and mental well-being. To help your child feel safe you can
      provide trustworthy and consistent relationships and safety measures in their daily activities.
      Creating a stable, caring, and dependable environment for your child will help enhance their sense
      of safety, connectedness, and autonomy as they grow and develop over time.
    </p>
  </div>;

export default Blurb7_23B;
