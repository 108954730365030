import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import '../../../../assets/stylesheets/pages/report.scss';
import {useSelector} from 'react-redux';
import Variables from '../components/Variables';
import FORMS from '../../../../redux/constants';
import Blurb13_1A from '../../../blurbs/13_EducationTearoff/Blurb13_1A';
import Blurb13_1B from '../../../blurbs/13_EducationTearoff/Blurb13_1B';
import Blurb13_1C from '../../../blurbs/13_EducationTearoff/Blurb13_1C';
import Blurb13_1D from '../../../blurbs/13_EducationTearoff/Blurb13_1D';
import Blurb13_1E from '../../../blurbs/13_EducationTearoff/Blurb13_1E';
import Blurb13_12A from '../../../blurbs/13_EducationTearoff/Blurb13_12A';
import Blurb13_12B from '../../../blurbs/13_EducationTearoff/Blurb13_12B';
import Blurb13_12C from '../../../blurbs/13_EducationTearoff/Blurb13_12C';
import Blurb13_12D from '../../../blurbs/13_EducationTearoff/Blurb13_12D';
import Blurb13_12E from '../../../blurbs/13_EducationTearoff/Blurb13_12E';
import Blurb13_22A from '../../../blurbs/13_EducationTearoff/Blurb13_22A';
import Blurb13_22B from '../../../blurbs/13_EducationTearoff/Blurb13_22B';
import Blurb13_22C from '../../../blurbs/13_EducationTearoff/Blurb13_22C';
import Blurb13_22D from '../../../blurbs/13_EducationTearoff/Blurb13_22D';
import Blurb13_22E from '../../../blurbs/13_EducationTearoff/Blurb13_22E';
import Blurb13_33A from '../../../blurbs/13_EducationTearoff/Blurb13_33A';
import Blurb13_33B from '../../../blurbs/13_EducationTearoff/Blurb13_33B';
import Blurb13_33C from '../../../blurbs/13_EducationTearoff/Blurb13_33C';
import Blurb13_33D from '../../../blurbs/13_EducationTearoff/Blurb13_33D';
import Blurb13_33E from '../../../blurbs/13_EducationTearoff/Blurb13_33E';

const EducationTearoff = () => {
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {intellectual} = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);
  const {iq} = useSelector(state => state.forms[FORMS.IQ_SCORE.id]);
  const {academic_brain} = useSelector(state => state.forms[FORMS.BRAIN_DOMAIN_ASSESSMENT.id]);
  const {odd, ptsd, conduct, personality, schizophrenia, anxiety, mood, bipolar} = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS.id]);

  const variables = {age, diagnosis, iq, intellectual, academic_brain, odd, ptsd, conduct,
    personality, schizophrenia, anxiety, mood, bipolar};

  return <section>
    <SectionTitle>Education Tear Off Sheet</SectionTitle>
    <Variables variableObject={variables}/>
    <Card
      title='Education'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_1A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_1B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_1C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_1D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_1E/>}
      </div>
      {
        formData.education_tearoff && <p>
          {formData.education_tearoff}
        </p>
      }
      <div>
        {intellectual === '1' || iq === '0' && age === '1' &&
            (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_12A/>}
        {intellectual === '1' || iq === '0' && age === '2' &&
            (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_12B/>}
        {intellectual === '1' || iq === '0' && age === '3' &&
            (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_12C/>}
        {intellectual === '1' || iq === '0' && age === '4' &&
            (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_12D/>}
        {intellectual === '1' || iq === '0' && age === '5' &&
            (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_12E/>}
      </div>
      <div>
        {academic_brain === '1' && age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_22A/>}
        {academic_brain === '1' && age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_22B/>}
        {academic_brain === '1' && age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_22C/>}
        {academic_brain === '1' && age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_22D/>}
        {academic_brain === '1' && age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_22E/>}
      </div>
      <div>
        {(ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' || anxiety === '1' ||
              mood === '1' || bipolar === '1' || odd === '1') && age === '1' &&
            (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_33A/>}
        {(ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' || anxiety === '1' ||
              mood === '1' || bipolar === '1' || odd === '1') && age === '2' &&
            (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_33B/>}
        {(ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' || anxiety === '1' ||
              mood === '1' || bipolar === '1' || odd === '1') && age === '3' &&
            (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_33C/>}
        {(ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' || anxiety === '1' ||
              mood === '1' || bipolar === '1' || odd === '1') && age === '4' &&
            (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_33D/>}
        {(ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' || anxiety === '1' ||
              mood === '1' || bipolar === '1' || odd === '1') && age === '5' &&
            (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb13_33E/>}
      </div>
    </Card>
  </section>;
};

export default EducationTearoff;
