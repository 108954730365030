import React from 'react';

const Blurb4_3D = () =>
  <div>
    <p>
      The assessment team indicated that you were referred for support to further develop your social skills.
      It may be helpful to work with a supportive person who can model appropriate conversations and help you
      navigate new social situations. For instance, mentorship programming has been identified as one way to
      assist with social skill development for adults with FASD, in a relational way, often while engaging in
      desired activities. Doing so can serve as a protective factor as you avoid negative peer influences and
      support you in building positive friendships.
    </p>
  </div>;

export default Blurb4_3D;
