import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const SubstanceForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.SUBSTANCE.id]);
  const dispatch = useDispatch();
  const substances = FORMS.SUBSTANCE.fields;
  const fieldOptions = FORMS.SUBSTANCE.options;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] !== '');
  const isFormComplete = Object.keys(substances).filter(key => key !== 'other_misuses').every(field => checkedFields.includes(field)) &&
    (formData.other_misuses && formData.other_misuses === '1' ? formData.other_misuse_specify.length > 0 : true);

  const handleFormChange = updatedFormData => {
    if (formData.other_misuses !== updatedFormData.other_misuses) {
      updatedFormData[FORMS.SUBSTANCE.fields.other_misuses.specify_other.id] = FORMS.SUBSTANCE.fields.other_misuses.specify_other.value;
    }

    dispatch(submitForm(FORMS.SUBSTANCE.id, updatedFormData));
  };

  return (
    <>
      <h2 className='form-header'>{FORMS.SUBSTANCE.header}</h2>
      <div className='form-content'>
        <div className='lead group-name'>Are any of the following substances currently being used/misused?</div>
        <form>
          <div className='grid-x grid-margin-x show-for-medium'>
            <div className='small-12 medium-4 large-3 cell'/>
            <div className='cell medium-8 large-9'>
              <div className='grid-x small-up-2 medium-up-3 padding-horizontal-1'>
                {
                  Object.values(fieldOptions).map(fieldOption =>
                    <div
                      className='auto cell text-center'
                      key={fieldOption}>
                      <label className='form-field'>{fieldOption}</label>
                    </div>)
                }
              </div>
            </div>
          </div>

          <div className='padding-bottom-1'>
            {Object.keys(substances).map(substanceKey =>
              <div
                className='grid-x grid-margin-x align-middle padding-bottom-1'
                key={substanceKey} >
                <div className='small-12 medium-4 large-3 cell'>
                  <label className='form-field'>{substances[substanceKey].title}</label>
                </div>
                <div className='cell medium-8 large-9 align-self-stretch'>
                  <div className='form-field-value grid-x small-up-2 medium-up-3 padding-bottom-1 height-100 align-middle round-bottom-borders round-top-borders'>
                    {Object.keys(fieldOptions).map(optionKey =>
                      <div
                        className='auto cell text-center'
                        key={optionKey}>
                        <input
                          checked={formData[substanceKey] === optionKey}
                          className='margin-right-0'
                          id={`answer_${optionKey}`}
                          key={optionKey}
                          name={substanceKey}
                          onChange={event => handleFormChange({...formData, [substanceKey]: event.target.value})}
                          type='radio'
                          value={optionKey}
                        />
                      </div>)}
                  </div>
                </div>
              </div>)}
            {
              formData.other_misuses === FORMS.SUBSTANCE.fields.other_misuses.specify_other.condition && <>
                <label
                  className='form-field'
                  htmlFor={FORMS.SUBSTANCE.fields.other_misuses.specify_other.id}>{FORMS.SUBSTANCE.fields.other_misuses.specify_other.title}</label>
                <input
                  id={FORMS.SUBSTANCE.fields.other_misuses.specify_other.id}
                  onChange={event => handleFormChange({...formData, [FORMS.SUBSTANCE.fields.other_misuses.specify_other.id]: event.target.value})}
                  type='text'
                  value={formData[FORMS.SUBSTANCE.fields.other_misuses.specify_other.id]}/>
              </>
            }
          </div>
          <div/>
        </form>
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

SubstanceForm.propTypes = {
  formIndex: PropTypes.number
};

export default SubstanceForm;
