import React from 'react';

const Blurb1_6 = () =>
  <div>
    <p>
      Everyone with FASD is unique, with areas of strength and difficulty that influence their daily functioning.
      By gaining an understanding of each person’s skill sets we are better equipped to work together towards
      healthy outcomes. Shared understandings help identify 1) ways to establish well suited environments, 2)
      supports that may leverage strengths and offset difficulties, and 3) interventions that
      may enhance growth and development for each individual. Combined, this can help to establish
      appropriate expectations and adapt as needed in pursuit of desired goals.
    </p>
  </div>;

export default Blurb1_6;
