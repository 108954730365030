import React from 'react';

const Blurb5_12D = () =>
  <div>
    <p>
      Your assessment team has recommended that you receive educational re-assessment as you progress
      through your education and later learning. Targeted intervention support to address challenges
      with learning and academic achievement is possible with up to date understanding of your unique
      areas of strengths and needs.
    </p>
  </div>;

export default Blurb5_12D;
