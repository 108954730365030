import React from 'react';

const Blurb17D = () =>
  <div>
    <p>
        Some individuals with FASD share similarities in the areas where further supports would be helpful.
        Some people do not experience as much difficulty and some have strengths in their intellectual
        functioning, language abilities, and academic skills. These areas are sometimes identified in
        education or work settings and may be quite visible. However, having these skills, and the ability
        to consistently use them are two different things. Findings from your assessment suggest that you
        may experience difficulties applying your skills. This includes struggling to pay attention,
        finding it difficult to use your ‘brain brake pedal’ and slowing down to think about something,
        and problems consistently or effectively ‘changing lanes,’ and thinking flexibly. Problem-solving
        and planning may also be more of a challenge. Although you have many strengths and areas where
        less support is needed, it may also be more difficult for you to put that knowledge into action,
        for brain-based reasons. Difficulties in these areas will add to difficulty controlling emotions
        when it becomes more challenging for you to meet expectations for daily functioning and social activities.
    </p>
    <p>
        For adults with this pattern of needs, it may be easy for others to misinterpret difficulties experienced
        as unwillingness to follow instructions. For example, sometimes you may not follow instructions even if
        you remember them. Others might think you are doing it on purpose, or see this as oppositional behaviour.
        Frustration and discouragement can be easily misinterpreted as anger or avoidance. The first step is for
        people in your life is understanding, so that support and acceptance is experienced from others. By
        understanding that brain-based difficulties are impacting your ability to use your skills in everyday
        ways, we can change our approach to support them better. This can help you grow and succeed in your
        own way. All people seek to connect meaningfully with others and find purpose in daily activities, so
        it is important to seek out opportunities for you to experience success and experience.
    </p>
    <p>
        In short, through a shared understanding of each person’s unique brain, we can provide individualized,
        proactive support, so that success and healthy outcomes are achieved. The following report has been
        prepared to provide additional details regarding how we might move together towards healthy outcomes
        by nurturing strengths and addressing needs through well suited supports and services.
    </p>
  </div>;

export default Blurb17D;
