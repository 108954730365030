import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import MonthYear from '../../../components/MonthYear';
import PropTypes from 'prop-types';
import {FormNav} from '../../../components/FormNav';

const DateOfBirth = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.DATE_OF_BIRTH.id]);
  const dispatch = useDispatch();
  const birthYearOptions = FORMS.DATE_OF_BIRTH.fields.dob_yr.options;
  const birthMonthOptions = FORMS.DATE_OF_BIRTH.fields.dob_mth.options;
  const isFormComplete = formData.dob_yr !== '' && formData.dob_mth !== '';

  const handleFormChange = dateVal => {
    dispatch(submitForm(FORMS.DATE_OF_BIRTH.id, {...formData, dob_mth: dateVal.dob_mth, dob_yr: dateVal.dob_yr}));
  };

  return (
    <>
      <h2 className='form-header'>{FORMS.DATE_OF_BIRTH.header}</h2>
      <div className='form-content'>
        <MonthYear
          formData={formData}
          monthId='dob_mth'
          monthOptions={birthMonthOptions}
          onChange={handleFormChange}
          title='Date of birth'
          yearId='dob_yr'
          yearOptions={birthYearOptions}
        />
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

DateOfBirth.propTypes = {
  formIndex: PropTypes.number
};

export default DateOfBirth;
