import FORMS from '../constants';

const initialState = {};

for (const formKey of Object.keys(FORMS)) {
  const form = FORMS[formKey];
  initialState[form.id] = {...form.fields};

  for (const fieldKey of Object.keys(form.fields)) {
    initialState[form.id][fieldKey] = form.fields[fieldKey].value;

    if (form.fields[fieldKey].specify_other) {
      initialState[form.id][form.fields[fieldKey].specify_other.id] = form.fields[fieldKey].specify_other.value;
    }

    if (form.fields[fieldKey].precedingIds) {
      // eslint-disable-next-line no-return-assign
      form.fields[fieldKey].precedingIds.forEach(id => initialState[form.id][id] = '');
    }
  }
}

const forms = (state = initialState, action) => {
  const formIds = Object.keys(FORMS).map(formKey => FORMS[formKey].id);

  if (formIds.includes(action.type)) {
    return {...state, ...{[action.type]: action.payload}};
  }

  return state;
};

export default forms;
