import React from 'react';

const Blurb9_4C = () =>
  <div>
    <p>
      If your teen is already employed or seeking employment, they will likely benefit from coaching and
      education surrounding the expectations of their position. You may want to plan to help your teen with
      the practical components of their job. This might include helping them remember when they work (e.g.,
      creating a schedule with them), helping them plan transportation to and from their job, or practicing
      what to do in certain situations. Co-workers and employers that work with your teen would likely benefit
      from education surrounding FASD to help create a positive and safe work environment for your teen. This
      FASD practical guide might be helpful to give to your teen’s employer: <a href='https://drive.google.com/file/d/1MIdXwlXOWOgvPSQwn4TwVHPNbslTLMX3/view'>
      https://drive.google.com/file/d/1MIdXwlXOWOgvPSQwn4TwVHPNbslTLMX3/view</a>
    </p>
  </div>;

export default Blurb9_4C;
