import React from 'react';

const Blurb11_3D = () =>
  <div>
    <p>
      Like any aspect of life, should you become a parent it will be important for you and your supportive
      persons to monitor your parenting journey to make sure that the services put in place help you develop
      your parenting skills. Potential supports to consider include in-home services, advocacy, counselling,
      education, and pregnancy programs that leverage and support your unique strengths and needs. As you become
      more confident and able to self-advocate for yourself and your children, environmental supports can be reduced.
    </p>
    <p>
      Some people with FASD experience difficulties with self-regulation. Though rewarding, raising children
      can also be unpredictable and stressful, making it that harder to control emotions and behaviour when
      self-regulation is already difficult. Parents with FASD may benefit from building skills to help stay
      calm under pressure, while also being flexible and supportive in response to changing circumstances.
      Self-care for yourself as a parent with FASD is necessary so that you can provide the best care possible
      for your children. This might look like specific mental health and parenting support for yourself,
      accessing caregiver support groups, taking time for self-care, or developing some hobbies you can
      do with your children.
    </p>
  </div>;

export default Blurb11_3D;
