import React from 'react';

const Blurb8_1D = () =>
  <div>
    <p>
      Mental health is fundamental to daily living. Mental health refers to emotional and psychological
      well-being, and emotion regulation. People with FASD are more likely to experience mental health
      needs than might typically be expected - this means that ongoing monitoring of your mental health
      is important. It will be important for you to receive appropriate information and support that
      promotes mental health and regulation.
    </p>
  </div>;

export default Blurb8_1D;
