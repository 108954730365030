import React from 'react';

const Blurb8_2D = () =>
  <div>
    <p>
      The skills and supports you will need to nurture your mental health may be different from other adults.
      They may also change over time. You can support your own mental health by keeping an eye out for
      challenges and accessing supports early if concerns come up. This can include connecting with a mental
      health professional. Finding ways to promote wellness can also be helpful and can create experiences of
      positive emotionality. Activities which promote your own wellness may look different for everyone. Some
      adults find that their mental wellness is improved through exercise or relaxing mindfulness activities
      such as yoga or meditation, while others may plan social activities. Here is a short guide to supporting
      mental wellbeing in individuals with FASD from CanFASD: <a href='https://canfasd.ca/2020/05/08/mental-health-for-individuals-with-fasd/'>
      https://canfasd.ca/2020/05/08/mental-health-for-individuals-with-fasd/</a>
    </p>
  </div>;

export default Blurb8_2D;
