import React from 'react';

const Blurb10_6 = () =>
  <div>
    <p>
      Immediate concerns around housing have been identified. Your province may have specific emergency housing
      and/or financial support. It may be helpful to talk to your local FASD clinic or advocacy group to find
      out what resources are available in your area.
    </p>
  </div>;

export default Blurb10_6;
