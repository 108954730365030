import React from 'react';

const Blurb14_8 = () =>
  <div>
    <p>
      The Canadian guidelines for diagnosing FASD highlight 10 brain domains to consider during the
      diagnostic process. Preschool age children’s brains are still in early stages of development
      and so examination of all of these areas is not yet possible. This means that some of your
      client’s areas of strengths and needs may not yet have developed or emerged.
    </p>
    <p>
      Because each person with FASD or PAE is unique, it is not easy to predict where challenges and
      strengths will emerge as they continue to develop – each pathway can be different. Therefore,
      it will be important that your client undergo future assessment when they begin elementary school
      and at other important periods of transition, like high school, to gain a greater understanding
      of their functioning. This will also ensure that your client is receiving appropriate supports
      and intervention to create opportunities for success across the lifespan. The following report
      has been prepared to provide additional details regarding how to best achieve healthy outcomes
      by nurturing your client’s unique areas of strengths and needs identified during the assessment
      process. Recommendations for areas of support and potential services are also included in the report.
    </p>
  </div>;

export default Blurb14_8;
