import React from 'react';

const Blurb2_6D = () =>
  <div>
    <p>
      You reported motor difficulties as an area of challenge. Motor skills are required for our daily
      activities. Gross motor skills help us to walk, play sports, and exercise, while fine motor skills
      help us draw or use a smartphone or tablet. Difficulties with motor skills can impact your ability
      to complete common everyday tasks, like writing information down or playing a sport. Motor skills
      can vary greatly from one person to another. Some tasks may come easily, like running, while others
      may be difficult, painting or doing yoga.
    </p>
    <p>
      Awareness of your own motor needs can help others working with you to identify changes to your
      environments like home and work that will reduce stress and increase your ability to show your
      strengths. Motor functioning can be hard to predict sometimes, thus observing and recording where
      you may struggle can help us to understand and then provide appropriate support. With targeted
      intervention from clinicians, such as occupational therapists and physical therapists, motor
      development and functioning can be supported, and improvement is possible.
    </p>
  </div>;

export default Blurb2_6D;
