/* eslint camelcase: 0 */

const getYears = (initialyear, endYear) => {
  const yearObject = {};

  if (!endYear) {
    // eslint-disable-next-line no-param-reassign
    endYear = new Date().getFullYear();
  }

  for (let i = initialyear; i <= endYear; i++) {
    yearObject[`year${i}`] = i;
  }

  return yearObject;
};

const FORMS = {
  UNIQUE_BRAIN: {
    header: 'My unique brain',
    id: 'UNIQUE_BRAIN_FORM',
    url: 'uniquebrain',
    fields: {
      age: {
        title: 'Age',
        value: '',
        options: {
          1: 'Preschool(0-5)',
          2: 'Child (6-11)',
          3: 'Adolescent (12-17)',
          4: 'Adult (18+)',
          5: 'Universal'
        }
      },
      my_unique_brain: {
        title: 'My Unique Brain Text',
        value: ''
      }
    }
  },
  TEAROFFS_SELECTORS: {
    header: 'Tearoffs',
    id: 'TEAROFFS_SELECTORS_FORM',
    url: 'tearoffs-selection',
    fields: {
      community_tearoff: {
        title: 'Community tearoff',
        value: false
      },
      education_tearoff: {
        title: 'Education tearoff',
        value: false
      },
      clinician_tearoff: {
        title: 'Clinician tearoff',
        value: false
      },
      adult_adolescent_tearoff: {
        title: 'Adult adolescent tearoff',
        value: false
      },
      justice_tearoff: {
        title: 'Justice tearoff',
        value: false
      },
      no_diagnosis_tearoff: {
        title: 'No diagnosis tearoff',
        value: false
      }
    }
  },
  IDENTIFICATION: {
    header: 'Demographic information and client characteristics',
    id: 'IDENTIFICATION_FORM',
    url: 'identification',
    fields: {
      siteid: {
        title: 'Site iD',
        value: ''
      },
      country: {
        title: 'Country',
        value: '',
        options: {
          0: 'Canada',
          1: 'Australia',
          2: 'New Zealand',
          3: 'United States',
          4: 'United Kingdom',
          5: 'France',
          6: 'Other'
        },
        specify_other: {
          id: 'country_other',
          title: 'Please specify',
          value: '',
          condition: '6'
        }
      },
      province: {
        title: 'Province/Territory',
        value: '',
        options: {
          0: 'AB',
          1: 'BC',
          2: 'MB',
          3: 'NB',
          4: 'NS',
          5: 'NL',
          6: 'NWT',
          7: 'NU',
          8: 'ON',
          9: 'QC',
          10: 'SK',
          11: 'YK'
        },
        condition: '0'
      }
    }
  },
  DIAGNOSIS: {
    header: 'Neurobehavioural assessment',
    id: 'DIAGNOSIS_FORM',
    url: 'diagnosis',
    fields: {
      diagnosis: {
        title: 'Diagnosis',
        value: '',
        options: {
          0: 'FASD with sentinel facial features',
          1: 'FASD without sentinel facial features',
          2: 'At risk for neurodevelopmental disorder and FASD, associated with prenatal alcohol exposure',
          3: 'No FASD diagnosis'
        }
      }
    }
  },
  ASSESSMENT: {
    header: 'Demographic information and client characteristics',
    id: 'ASSESSMENT_FORM',
    url: 'assessment',
    fields: {
      assessment_type: {
        title: 'Type of assessment',
        value: '',
        options: {
          0: 'Initial assessment',
          1: 'Re-assessment',
          2: 'Follow up'
        }
      },
      at_risk: {
        title: 'If being re-assessed, was the individual previously given an \'At Risk\' designation?',
        value: '',
        options: {
          1: 'Yes',
          2: 'No',
          3: 'Unknown'
        },
        condition: '1'
      }
    }
  },
  REFERRAL_DATE: {
    header: 'Demographic information and client characteristics',
    id: 'REFERRAL_DATE_FORM',
    url: 'referraldate',
    fields: {
      ref_yr: {
        title: 'Year',
        value: '',
        options: getYears(1990, 2030)
      },
      ref_mth: {
        title: 'Month',
        value: '',
        options: {
          1: 'January',
          2: 'February',
          3: 'March',
          4: 'April',
          5: 'May',
          6: 'June',
          7: 'July',
          8: 'August',
          9: 'September',
          10: 'October',
          11: 'November',
          12: 'December'
        }
      }
    }
  },
  REFERRAL_SOURCE: {
    header: 'Demographic information and client characteristics',
    id: 'REFERRAL_SOURCE_FORM',
    url: 'referralsource',
    fields: {
      ref_source: {
        title: 'Source of referral',
        value: '',
        options: {
          0: 'Social services agency (e.g., child and family services agency, community support agency)',
          1: 'Medical referral',
          2: 'Independence system (e.g., school, daycare)',
          3: 'Legal system',
          4: 'Self',
          5: 'Family referral (e.g., biological, foster, adoptive parent)',
          6: 'Other'
        },
        specify_other: {
          id: 'ref_sourcespecify',
          title: 'Specify',
          value: '',
          condition: '6'
        }
      }
    }
  },
  REFERRAL_REASON: {
    header: 'Demographic Information and Client Characteristics',
    id: 'REFERRAL_REASON_FORM',
    url: 'referralreason',
    fields: {
      ref_reason___0: {
        title: 'Behavioural issues',
        value: false
      },
      ref_reason___1: {
        title: 'Learning difficulties',
        value: false
      },
      ref_reason___2: {
        title: 'Difficulties with the law',
        value: false
      },
      ref_reason___3: {
        title: 'Developmental delays / delay to meet developmental milestones',
        value: false
      },
      ref_reason___4: {
        title: 'Adaptive living problems',
        value: false
      },
      ref_reason___5: {
        title: 'Confirmed prenatal alcohol exposure',
        value: false
      },
      ref_reason___6: {
        title: 'Social skill difficulties',
        value: false
      },
      ref_reason___7: {
        title: 'Self-regulation difficulties (feeding, sleeping, sensory)',
        value: false
      },
      ref_reason___8: {
        title: 'Re-assessment',
        value: false
      },
      ref_reason___9: {
        title: 'Follow-up',
        value: false
      },
      ref_reason___10: {
        title: 'Establish eligibility for supports (e.g., financial or developmental support programs)',
        value: false
      },
      ref_reason___11: {
        title: 'Other',
        value: false,
        specify_other: {
          id: 'ref_reasonspecify',
          title: 'Specify',
          value: '',
          condition: 'ref_reason___11'
        }
      }
    }
  },
  SCREENING_TOOL: {
    header: 'Demographic Information and Client Characteristics',
    id: 'SCREENING_TOOL_FORM',
    url: 'screeningtool',
    fields: {
      screen: {
        title: 'Was a screening tool used for referral?',
        value: '',
        options: {
          0: 'No',
          1: 'Yes'
        }
      },
      screen_tool: {
        id: 'screen_tool',
        title: 'Which tool?',
        value: '',
        condition: '1'
      },
      screen_who: {
        id: 'screen_who',
        title: 'Who did the screen?',
        value: '',
        condition: '1'
      }
    }
  },
  DIAGNOSIS_DATE: {
    header: 'Demographic Information and Client Characteristics',
    id: 'DIAGNOSIS_DATE_FORM',
    url: 'diagnosisdate',
    fields: {
      assessment_yr: {
        title: 'Year',
        value: '',
        options: getYears(1990, 2030)
      },
      assessment_mth: {
        title: 'month',
        value: '',
        options: {
          1: 'January',
          2: 'February',
          3: 'March',
          4: 'April',
          5: 'May',
          6: 'June',
          7: 'July',
          8: 'August',
          9: 'September',
          10: 'October',
          11: 'November',
          12: 'December'
        }
      }
    }
  },
  SEX: {
    id: 'SEX_FORM',
    url: 'sex',
    header: 'Demographic Information and Client Characteristics',
    fields: {
      sex: {
        title: 'Sex',
        value: '',
        options: {
          0: 'Male',
          1: 'Female'
        }
      }
    }
  },
  GENDER_IDENTITY: {
    id: 'GENDER_FORM',
    url: 'genderidentity',
    header: 'Demographic Information and Client Characteristics',
    fields: {
      gender: {
        title: 'Gender identity',
        value: '',
        options: {
          0: 'Male',
          1: 'Female',
          3: 'Other'
        },
        specify_other: {
          id: 'gender_specify',
          title: 'Please specify',
          value: '',
          condition: '3'
        }
      }
    }
  },
  DATE_OF_BIRTH: {
    header: 'Demographic Information and Client Characteristics',
    id: 'DATE_OF_BIRTH_FORM',
    url: 'dateofbirth',
    fields: {
      dob_yr: {
        title: 'Year',
        value: '',
        options: getYears(1970, 2030)
      },
      dob_mth: {
        title: 'month',
        value: '',
        options: {
          1: 'January',
          2: 'February',
          3: 'March',
          4: 'April',
          5: 'May',
          6: 'June',
          7: 'July',
          8: 'August',
          9: 'September',
          10: 'October',
          11: 'November',
          12: 'December'
        }
      }
    }
  },
  ETHNICITY: {
    header: 'Demographic Information and Client Characteristics',
    id: 'ETHNICITY_FORM',
    url: 'ethnicity',
    fields:
        {
          ethnicity___0: {
            title: 'Caucasian',
            value: false
          },
          ethnicity___1: {
            title: 'Indigenous',
            value: false
          },
          ethnicity___2: {
            title: 'African American',
            value: false
          },
          ethnicity___3: {
            title: 'Latin American',
            value: false
          },
          ethnicity___4: {
            title: 'South Asian (e.g. East Indian, Pakistani, Sri Lankan, etc.)',
            value: false
          },
          ethnicity___5: {
            title: 'West Asian (e.g. Iranian, Afghan)',
            value: false
          },
          ethnicity___6: {
            title: 'Chinese',
            value: false
          },
          ethnicity___7: {
            title: 'Filipino',
            value: false
          },
          ethnicity___8: {
            title: 'Korean',
            value: false
          },
          ethnicity___9: {
            title: 'Japanese',
            value: false
          },
          ethnicity___10: {
            title: 'Southeast Asian (e.g. Vietnamese, Cambodian, Laotian, Thai, etc.)',
            value: false
          },
          ethnicity___11: {
            title: 'Arab',
            value: false
          },
          ethnicity___12: {
            title: 'Other',
            value: false,
            specify_other: {
              id: 'ethnicity_specify',
              title: 'Specify',
              value: '',
              condition: 'ethnicity___12'
            }
          },
          ethnicity___9999: {
            title: 'Unknown',
            value: false
          }
        }

  },
  CURRENT_LIVING_SITUATION: {
    header: 'Demographic Information and Client Characteristics',
    id: 'CURRENT_LIVING_SITUATION_FORM',
    url: 'currentlivingsituation',
    fields: {
      living: {
        title: 'Current living situation',
        value: '',
        options: {
          0: 'Independent',
          1: 'With biological mother',
          2: 'With biological father',
          3: 'With other family member(s)',
          4: 'Foster care (non-family member)',
          5: 'Adoptive parent(s)',
          6: 'Group home',
          7: 'Homeless',
          8: 'In custody',
          9: 'Other'
        }
      },
      living_familyspecify: {
        id: 'living_familyspecify',
        title: 'Specify other family member(s)',
        value: '',
        condition: '3'
      },
      living_specify: {
        id: 'living_specify',
        title: 'Specify',
        value: '',
        condition: '9'
      }
    }
  },
  PARENT_DIAGNOSIS: {
    header: 'Demographic Information and Client Characteristics',
    id: 'PARENT_DIAGNOSIS_FORM',
    url: 'parentdiagnosis',
    fields: {
      parent_diagnosis: {
        title: 'Has a biological parent been diagnosed with FASD?',
        value: '',
        options: {
          0: 'No',
          1: 'Yes',
          3: 'Unknown'
        }
      }
    }
  },
  SIBLING_DIAGNOSIS: {
    header: 'Demographic Information and Client Characteristics',
    id: 'SIBLING_DIAGNOSIS_FORM',
    url: 'siblingdiagnosis',
    fields: {
      sibling_diagnosis: {
        title: 'Has a sibling been diagnosed with FASD?',
        value: '',
        options: {
          0: 'No',
          1: 'Yes',
          3: 'Unknown',
          4: 'Not applicable (no siblings)'
        }
      }
    }
  },
  PRENATAL_ALCOHOL_EXPOSURE: {
    header: 'Assessment of Prenatal Alcohol Exposure',
    id: 'PRENATAL_ALCOHOL_EXPOSURE_FORM',
    url: 'prenatalalcoholexposure',
    fields: {
      exposure: {
        title: 'Prenatal alcohol exposure is:',
        value: '',
        options: {
          0: 'Absent (Confirmed)',
          1: 'Present (Confirmed)',
          2: 'Unconfirmed',
          3: 'Unknown'
        },
        specify_other: {
          id: 'exposure_specify',
          title: 'Please specify source, if known',
          value: '',
          condition: '1'
        }
      }
    }
  },
  OTHER_PRENATAL_EXPOSURES: {
    header: 'Assessment of Prenatal Alcohol Exposure',
    id: 'OTHER_PRENATAL_EXPOSURES_FORM',
    url: 'otherprenatalexposures',
    fields: {
      nicotine: {
        title: 'Nicotine',
        value: ''
      },
      opiates: {
        title: 'Opiates',
        value: ''
      },
      marijuana: {
        title: 'Marijuana',
        value: ''
      },
      cocaine: {
        title: 'Cocaine/crack',
        value: ''
      },
      meth: {
        title: 'Methamphetamine',
        value: ''
      },
      prescription_meds: {
        title: 'Presciption medications',
        value: ''
      },
      exposure_other: {
        title: 'Other Exposures',
        value: '',
        specify_other: {
          id: 'exposure_otherspecify',
          title: 'Please specify',
          value: ''
        }
      }
    },
    options: {
      0: 'Absent (confirmed)',
      1: 'Present (confirmed)',
      3: 'Unknown'
    }
  },
  OTHER_FACTORS: {
    header: 'Assessment of Prenatal Alcohol Exposure',
    id: 'OTHER_FACTORS_FORM',
    url: 'otherfactors',
    fields: {
      factors___0: {
        title: 'Post-natal trauma',
        value: false
      },
      factors___1: {
        title: 'Attachment issues',
        value: false
      },
      factors___2: {
        title: 'Sexual or physical abuse',
        value: false
      },
      factors___3: {
        title: 'Other',
        value: false,
        specify_other: {
          id: 'factors_specify',
          title: 'Please specify',
          value: '',
          condition: 'factors___3'
        }
      }
    }
  },
  PALPEBRAL_FISSURE_NORMS: {
    header: 'Sentinel Facial Features',
    id: 'PALPEBRAL_FISSURE_NORMS_FORM',
    url: 'palpebralfissurenorms',
    fields: {
      fissurenorms___0: {
        title: 'Canadian norms',
        value: false
      },
      fissurenorms___1: {
        title: 'Thomas',
        value: false
      },
      fissurenorms___2: {
        title: 'Scandinavian',
        value: false
      },
      fissurenorms___3: {
        title: 'Other',
        value: false,
        specify_other: {
          id: 'fissurenorms',
          title: 'Please specify',
          value: '',
          condition: 'fissurenorms___3'
        }
      }
    }
  },
  PALPEBRAL_FISSURE_LENGTH: {
    header: 'Sentinel Facial Features',
    id: 'PALPEBRAL_FISSURE_LENGTH_FORM',
    url: 'palpebralfissurelength',
    fields: {
      fissurelength: {
        title: 'Palpebral fissure length',
        value: '',
        options: {
          0: '> -1 SD',
          1: '> -2 SD & < -1 SD',
          2: '< -2 SD'
        }
      }
    }
  },
  PHILTRUM_SMOOTHNESS: {
    header: 'Sentinel Facial Features',
    id: 'PHILTRUM_SMOOTHNESS_FORM',
    url: 'philtrumsmoothness',
    fields: {
      philtrum: {
        title: 'Philtrum smoothness (score on lip-philtrum guide)',
        value: '',
        options: {
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5'
        }
      }
    }
  },
  LIP_THINNESS: {
    header: 'Sentinel Facial Features',
    id: 'LIP_THINNESS_FORM',
    url: 'lipthinness',
    fields: {
      upperlip: {
        title: 'Upper lip thinness (score on lip-philtrum guide)',
        value: '',
        options: {
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5'
        }
      }
    }
  },
  SENTINEL_FEATURES: {
    header: 'Sentinel Facial Features',
    id: 'SENTINEL_FEATURES_FORM',
    url: 'sentinelfeatures',
    fields: {
      sentinel: {
        title: 'Total number of sentinel facial features',
        value: '',
        options: {
          0: '0',
          1: '1',
          2: '2',
          3: '3',
          4: 'Inconclusive'
        }
      }
    }
  },
  BRAIN_DOMAIN_ASSESSMENT: {
    header: 'Neurobehavioural Assessment',
    id: 'BRAIN_DOMAIN_ASSESSMENT_FORM',
    url: 'braindomainassessment',
    fields: {
      motorskills_brain: {
        title: 'Motor skills',
        value: ''
      },
      neuroanat_brain: {
        title: 'Neuroanatomy/Neurophysiology',
        value: ''
      },
      cognition_brain: {
        title: 'Cognition',
        value: ''
      },
      language_brain: {
        title: 'Language',
        value: ''
      },
      academic_brain: {
        title: 'Academic achievement',
        value: ''
      },
      memory_brain: {
        title: 'Memory',
        value: ''
      },
      attention_brain: {
        title: 'Attention',
        value: ''
      },
      executive_brain: {
        title: 'Executive function including impulse control',
        value: ''
      },
      regulation_brain: {
        title: 'Affect regulation',
        value: ''
      },
      adaptive_brain: {
        title: 'Adaptive behaviour, social skills, or social communication',
        value: ''
      }
    },
    options: {
      0: 'Not impaired',
      1: 'Significant impairment',
      888: 'Not assessed',
      889: 'Incomplete'
    }
  },
  IQ_SCORE: {
    header: 'Neurobehavioural Assessment',
    id: 'IQ_SCORE_FORM',
    url: 'iqscore',
    fields: {
      iq: {
        title: 'IQ Score',
        value: '',
        options: {
          0: 'Less than 70',
          1: '70',
          2: '71-85',
          3: 'Greater than 85',
          4: 'Unable to calculate'
        }
      }
    }
  },
  DIAGNOSTIC_SCHEMA: {
    header: 'Neurobehavioural Assessment',
    id: 'DIAGNOSTIC_SCHEMA_FORM',
    url: 'diagnosticschema',
    fields: {
      fasdcode: {
        title: 'Do you use another diagnostic schema to record information (i.e. 4-digit code)?',
        value: '',
        options: {
          0: 'No',
          1: 'Yes'
        },
        specify_other: {
          id: 'fasd4digit',
          title: 'Please provide the 4-digit diagnostic code',
          value: '',
          condition: '1',
          type: 'number',
          min: '0',
          max: '9999'
        }
      }
    }
  },
  OTHER_FEATURES: {
    header: 'Neurobehavioural Assessment',
    id: 'OTHER_FEATURES_FORM',
    url: 'otherfeatures',
    fields: {
      associatedfeatures___0: {
        title: 'Sleep problems',
        value: false
      },
      associatedfeatures___1: {
        title: 'Sensory sensitivities',
        value: false
      },
      associatedfeatures___2: {
        title: 'Sensory processing',
        value: false
      },
      associatedfeatures___3: {
        title: 'Trauma',
        value: false
      },
      associatedfeatures___4: {
        title: 'Slower processing speed',
        value: false
      },
      associatedfeatures___5: {
        title: 'Gender identity',
        value: false
      },
      associatedfeatures___6: {
        title: 'Other',
        value: false,
        specify_other: {
          id: 'associatedfeatures_specify',
          title: 'Please specify',
          value: '',
          condition: 'associatedfeatures___6'
        }
      }
    }
  },
  OTHER_DIAGNOSIS: {
    header: 'Neurobehavioural Assessment',
    id: 'OTHER_DIAGNOSIS_FORM',
    url: 'otherdiagnosis',
    fields: {
      congenital: {
        title: 'Congenital malformations',
        value: ''
      },
      intellectual: {
        title: 'Intellectual disability',
        value: ''
      },
      add: {
        title: 'ADD/ADHD',
        value: ''
      },
      attachment: {
        title: 'Attachment disorder',
        value: ''
      },
      dcd: {
        title: 'Developmental coordination disorder',
        value: ''
      },
      language: {
        title: 'Language disorder/Impairment',
        value: ''
      },
      auditory: {
        title: 'Auditory deficit',
        value: ''
      },
      visual: {
        title: 'Visual defect',
        value: ''
      },
      tourette: {
        title: 'Tourette\'s',
        value: ''
      },
      anxiety: {
        title: 'Anxiety disorder',
        value: ''
      },
      autism: {
        title: 'Autism spectrum disorders',
        value: ''
      },
      bipolar: {
        title: 'Bipolar disorder',
        value: ''
      },
      conduct: {
        title: 'Conduct disorder',
        value: ''
      },
      mood: {
        title: 'Mood disorder',
        value: ''
      },
      ocd: {
        title: 'Obsessive compulsive disorder',
        value: ''
      },
      personality: {
        title: 'Personality disorder',
        value: ''
      },
      ptsd: {
        title: 'PTSD',
        value: ''
      },
      schizophrenia: {
        title: 'Schizophrenia',
        value: ''
      },
      substanceabuse: {
        title: 'Substance abuse disorder',
        value: ''
      },
      suicide: {
        title: 'Suicide attempts(s)/Ideation',
        value: ''
      },
      odd: {
        title: 'Oppositional defiant disorder',
        value: ''
      },
      otherdiagnosis: {
        title: 'Other',
        value: '',
        specify_other: {
          id: 'otherdiagnosis_specify',
          title: 'Please specify',
          value: ''
        }
      }
    },
    options: {
      0: 'No (Assessed but not diagnosed)',
      1: 'Yes (Assessed and diagnosed)',
      2: 'Not assessed'
    }
  },
  MEDICAL_HISTORY: {
    header: 'Medical Health History',
    id: 'MEDICAL_HISTORY_FORM',
    url: 'medicalhistory',
    fields: {
      growth: {
        title: 'Growth restriction',
        value: '',
        specify_other: {
          id: 'growth_specify',
          title: 'Please specify height and weight percentiles.',
          value: '',
          condition: false
        },
        options: {
          0: 'No',
          1: 'Yes'
        }
      },
      microcephaly: {
        title: 'Microcephaly',
        value: '',
        options: {
          2: 'No',
          1: 'Yes'
        }
      },
      ftt: {
        title: 'Failure to thrive',
        value: '',
        options: {
          2: 'No',
          1: 'Yes'
        }
      },
      neurological: {
        title: 'Neurological conditions',
        value: '',
        specify_other: {
          id: 'neurological_specify',
          title: 'Please specify',
          value: '',
          condition: '1'
        },
        options: {
          0: 'No',
          1: 'Yes'
        }
      },
      mental: {
        title: 'Mental health',
        value: '',
        specify_other: {
          id: 'mental_specify',
          title: 'Please specify',
          value: '',
          condition: '1'
        },
        options: {
          0: 'No',
          1: 'Yes'
        }
      },
      headneck: {
        title: 'Head and neck issues',
        value: '',
        specify_other: {
          id: 'headneck_specify',
          title: 'Please specify',
          value: '',
          condition: '1'
        },
        options: {
          0: 'No',
          1: 'Yes'
        }
      },
      cleft: {
        title: 'Cleft lip plate',
        value: '',
        options: {
          2: 'No',
          1: 'Yes'
        }
      },
      cardiovascular: {
        title: 'Cardiovascular conditions',
        value: '',
        specify_other: {
          id: 'cardiovascular_specify',
          title: 'Please specify',
          value: '',
          condition: '1'
        },
        options: {
          0: 'No',
          1: 'Yes'
        }
      },
      respiratory: {
        title: 'Respiratory system',
        value: '',
        specify_other: {
          id: 'respiratory_specify',
          title: 'Please specify',
          value: '',
          condition: '1'
        },
        options: {
          0: 'No',
          1: 'Yes'
        }
      },
      endocrine: {
        title: 'Endocrinological conditions',
        value: '',
        specify_other: {
          id: 'endocrine_specify',
          title: 'Please specify',
          value: '',
          condition: '1'
        },
        options: {
          0: 'No',
          1: 'Yes'
        }
      },
      musculoskeletal: {
        title: 'Musculoskeletal',
        value: '',
        specify_other: {
          id: 'musculoskeletal_specify',
          title: 'Please specify',
          value: '',
          condition: '1'
        },
        options: {
          0: 'No',
          1: 'Yes'
        }
      },
      infectiousdiseases: {
        title: 'Infectious diseases',
        value: '',
        specify_other: {
          id: 'infectiousdiseases_specify',
          title: 'Please specify',
          value: '',
          condition: '1'
        },
        options: {
          0: 'No',
          1: 'Yes'
        }
      },
      medicalother: {
        title: 'Other',
        value: '',
        specify_other: {
          id: 'medicalother_specify',
          title: 'Please specify',
          value: '',
          condition: '1'
        },
        options: {
          0: 'No',
          1: 'Yes'
        }
      }
    }
  },
  MEDICATIONS: {
    header: 'Medications',
    id: 'MEDICATIONS_FORM',
    url: 'medications',
    fields: {
      omega3: {
        title: 'Omega-3',
        value: ''
      },
      choline: {
        title: 'Choline',
        value: ''
      },
      glutamine: {
        title: 'Glutamine',
        value: ''
      },
      aripiprazole: {
        title: 'Aripiprazole',
        value: ''
      },
      vortioxetine: {
        title: 'Vortioxetine',
        value: ''
      },
      minocycline: {
        title: 'Minocyline',
        value: ''
      },
      bupropion: {
        title: 'Bupropion',
        value: ''
      },
      buspirone: {
        title: 'Buspirone',
        value: ''
      },
      clozapine: {
        title: 'Clozapine',
        value: ''
      },
      melatonin: {
        title: 'Melatonin',
        value: ''
      }
    },
    options: {
      0: 'No',
      1: 'Yes'
    }
  },
  OTHER_CURRENT_MEDICATION: {
    header: 'Medical Health History',
    id: 'OTHER_CURRENT_MEDICATION_FORM',
    url: 'othercurrentmedication',
    fields: {
      stimulants: {
        title: 'Stimulants',
        value: '',
        precedingIds: ['stimulant1', 'stimulant2', 'stimulant3', 'stimulant4', 'stimulant5',
          'stimulant6', 'stimulant7', 'stimulant8', 'stimulant9', 'stimulant10']
      },
      antiDepressants: {
        title: 'Anti-depressants',
        value: '',
        precedingIds: ['antidepressant1', 'antidepressant2', 'antidepressant3', 'antidepressant4', 'antidepressant5',
          'antidepressant6', 'antidepressant7', 'antidepressant8', 'antidepressant9', 'antidepressant10']
      },
      antiPyschotics: {
        title: 'Anti-psychotics',
        value: '',
        precedingIds: ['antipsycho1', 'antipsycho2', 'antipsycho3', 'antipsycho4', 'antipsycho5',
          'antipsycho6', 'antipsycho7', 'antipsycho8', 'antipsycho9', 'antipsycho10']
      },
      birthControlPills: {
        title: 'Birth control pills',
        value: '',
        precedingIds: ['birthcontrol1', 'birthcontrol2', 'birthcontrol3', 'birthcontrol4', 'birthcontrol5',
          'birthcontrol6', 'birthcontrol7', 'birthcontrol8', 'birthcontrol9', 'birthcontrol10']
      },
      hormoneReplacementTherapy: {
        title: 'Hormone replacement therapy',
        value: '',
        precedingIds: ['hrt1', 'hrt2', 'hrt3', 'hrt4', 'hrt5',
          'hrt6', 'hrt7', 'hrt8', 'hrt9', 'hrt10']
      },
      antiHypertensives: {
        title: 'Anti-hypertensives',
        value: '',
        precedingIds: ['antihypertensive1', 'antihypertensive2', 'antihypertensive3', 'antihypertensive4', 'antihypertensive5',
          'antihypertensive6', 'antihypertensive7', 'antihypertensive8', 'antihypertensive9', 'antihypertensive10']
      },
      antiConvulsants: {
        title: 'Anti-convulsants',
        value: '',
        precedingIds: ['anticonvulsant1', 'anticonvulsant2', 'anticonvulsant3', 'anticonvulsant4', 'anticonvulsant5',
          'anticonvulsant6', 'anticonvulsant7', 'anticonvulsant8', 'anticonvulsant9', 'anticonvulsant10']
      },
      other: {
        title: 'Other',
        value: '',
        precedingIds: ['othermeds1', 'othermeds2', 'othermeds3', 'othermeds4', 'othermeds5',
          'othermeds6', 'othermeds7', 'othermeds8', 'othermeds9', 'othermeds10']
      }
    }
  },
  SUBSTANCE: {
    header: 'Substance Use',
    id: 'SUBSTANCE_FORM',
    url: 'substance',
    fields: {
      alcohol_misuse: {
        title: 'Alcohol',
        value: ''
      },
      tobacco_misuse: {
        title: 'Tobacco',
        value: ''
      },
      marijuana_misuse: {
        title: 'Marijuana/cannabis',
        value: ''
      },
      opiates_misuse: {
        title: 'Opiates',
        value: ''
      },
      solvents_misuse: {
        title: 'Solvents',
        value: ''
      },
      crack_misuse: {
        title: 'Crack/Cocaine',
        value: ''
      },
      other_misuses: {
        title: 'Other',
        value: '',
        specify_other: {
          id: 'other_misuse_specify',
          title: 'Please specify',
          value: '',
          condition: '1'
        }
      }
    },
    options: {
      0: 'No',
      1: 'Yes',
      2: 'Unknown'
    }
  },
  TREATMENT_ACCESS: {
    header: 'Substance Use Treatment Access',
    id: 'TREATMENT_ACCESS_FORM',
    url: 'treatmentaccess',
    fields: {
      alcohol_treat: {
        title: 'Alcohol',
        value: ''
      },
      tobacco_treat: {
        title: 'Tobacco',
        value: ''
      },
      marijuana_treat: {
        title: 'Marijuana/cannabis',
        value: ''
      },
      othersubstances_treat: {
        title: 'Other',
        value: '',
        specify_other: {
          id: 'othersubstances_treat_specify',
          title: 'Please specify',
          value: '',
          condition: '1'
        }
      }
    },
    options: {
      0: 'No',
      1: 'Yes',
      2: 'Unknown'
    }
  },
  EXPERIENCE: {
    header: 'EXPERIENCE',
    id: 'EXPERIENCE_FORM',
    url: 'experience',
    fields: {
      teacherassistant: {
        title: 'Teachers assistants prior to diagnosis',
        value: ''
      },
      expulsion: {
        title: 'School expulsion/suspension',
        value: ''
      },
      employment: {
        title: 'Employment problems',
        value: ''
      },
      helpliving: {
        title: 'Needs help living on own',
        value: ''
      },
      assistedhousing: {
        title: 'Needs assisted or sheltered housing',
        value: ''
      },
      victim: {
        title: 'Legal problems: victim',
        value: ''
      },
      offender: {
        title: 'Legal problems: offender',
        value: ''
      },
      custody: {
        title: 'Custody issues/family court',
        value: ''
      },
      specialcourts: {
        title: 'Special courts jail',
        value: ''
      },
      regularcourts: {
        title: 'Regular courts jail',
        value: ''
      },
      incarceration: {
        title: 'Incarcerated',
        value: ''
      }
    },
    options: {
      0: 'No',
      1: 'Yes',
      2: 'Unknown',
      3: 'To be followed up after clinic'
    }
  },
  RECOMMENDATIONS: {
    header: 'RECOMMENDATIONS',
    id: 'RECOMMENDATIONS_FORM',
    url: 'recommendations',
    fields: {
      coaching: {
        title: 'Coaching',
        value: ''
      },
      support: {
        title: 'Support (individual or group)',
        value: ''
      },
      communication: {
        title: 'FASD education',
        value: ''
      },
      earlyintervention: {
        title: 'FASD early intervention',
        value: ''
      },
      supportgroup: {
        title: 'Counselling support group',
        value: ''
      },
      individualtherapy: {
        title: 'Counselling or individual therapy',
        value: ''
      },
      familycounselling: {
        title: 'Couple/family counselling',
        value: ''
      },
      abusecounselling: {
        title: 'Substance abuse counselling/therapy',
        value: ''
      },
      respite: {
        title: 'Respite',
        value: ''
      },
      sexed: {
        title: 'Sexual health education',
        value: ''
      },
      anger: {
        title: 'Anger management',
        value: ''
      },
      childprotection: {
        title: 'Child protection',
        value: ''
      },
      spousalabuse: {
        title: 'Spousal abuse intervention',
        value: ''
      },
      mentalhealth: {
        title: 'Mental health support',
        value: ''
      },
      incomesupport: {
        title: 'Income support',
        value: ''
      },
      foodbank: {
        title: 'Food bank',
        value: ''
      },
      emergencyhousing: {
        title: 'Emergency housing/shelter',
        value: ''
      },
      daycare: {
        title: 'Day care',
        value: ''
      },
      guardianship: {
        title: 'Guardianship',
        value: ''
      },
      poa: {
        title: 'Power of attorney',
        value: ''
      },
      personaldirective: {
        title: 'Personal directive',
        value: ''
      },
      legalaid: {
        title: 'Legal aid',
        value: ''
      },
      civilcourt: {
        title: 'Services for civil court issues',
        value: ''
      },
      familycourt: {
        title: 'Services for family court issues',
        value: ''
      },
      slp: {
        title: 'Speech and language pathologist',
        value: ''
      },
      aba: {
        title: 'Behaviour therapy services (CBT, ABA, IBI, and other BT supports)',
        value: ''
      },
      meds_rec: {
        title: 'Medication/psychopharmacology',
        value: ''
      },
      ot: {
        title: 'Occupational therapy',
        value: ''
      },
      accommodations: {
        title: 'Accommodations/adaptation in environment, expectations, supports used, or routine',
        value: ''
      },
      guidance: {
        title: 'Anticipatory Guidance/Prevention: for the purpose of increasing awareness and/or decreasing risk of potential future problems',
        value: ''
      },
      safety: {
        title: 'Safety: precautions to be taken or specific measures to deal with safety concerns',
        value: ''
      },
      reassessment: {
        title: 'Reassessment',
        value: ''
      },
      othersubstitute: {
        title: 'Other substitute decision-making options',
        value: ''
      },
      legalservices: {
        title: 'Other legal services',
        value: ''
      },
      medicalreferral: {
        title: 'Medical referral',
        value: ''
      },
      fasdspecific: {
        title: 'FASD-specific intervention',
        value: ''
      }

    },
    options: {
      0: 'No',
      1: 'Yes',
      2: 'Yes, but service not available'
    }
  },
  TEAROFFS: {
    header: 'Tearoffs',
    id: 'TEAROFFS_FORM',
    url: 'tearoffs',
    fields: {
      my_unique_needs: {
        title: 'My unique needs',
        value: ''
      },
      my_unique_brain2: {
        title: 'My unique brain',
        value: ''
      },
      community_tearoff: {
        title: 'Community text',
        value: ''
      },
      education_tearoff: {
        title: 'Education text',
        value: ''
      },
      adult_adolescent_tearoff: {
        title: 'Adult adolescent text',
        value: ''
      },
      justice_tearoff: {
        title: 'Justice text',
        value: ''
      },
      no_diagnosis_tearoff: {
        title: 'No diagnosis text',
        value: ''
      },
      verbal_and_language: {
        title: 'Verbal and language',
        value: '1'
      },
      visual: {
        title: 'Visual',
        value: '1'
      },
      attentional_control: {
        title: 'Attentional control',
        value: '1'
      },
      processing_speed: {
        title: 'Processing speed',
        value: '1'
      },
      adaptive_skills: {
        title: 'Adaptive skills',
        value: '1'
      },
      relational_skills: {
        title: 'Relational skills',
        value: '1'
      }
    }
  }
};

export const APP_RESET = 'APP_RESET';

export default FORMS;
