import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';
import CheckBox from '../../../components/CheckBox';
import instructions from '../../../../locales/instructions';

const TearoffsSelectionForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS_SELECTORS.id]);
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    dispatch(submitForm(FORMS.TEAROFFS_SELECTORS.id, updatedFormData));
  };

  const tearoffs = FORMS.TEAROFFS_SELECTORS.fields;
  return (
    <>
      <h2 className='form-header'>{FORMS.TEAROFFS_SELECTORS.header}</h2>
      <div className='form-content'>
        <form>
          <div className='grid-x grid-margin-x'>
            <div className='cell'>
              <label className='form-field'>Tearoffs</label>
              <p>
                {instructions.tear_offs}
              </p>
            </div>
            <div className='cell medium-12 large-10'>
              <div className='form-field-value-rounded-borders'>
                <div className='padding-bottom-1'>
                  {Object.entries(tearoffs).map(([tearoffKey, tearoffValues]) => <CheckBox
                    checked={formData[tearoffKey]}
                    key={tearoffKey}
                    onChange={() => handleFormChange({...formData, [tearoffKey]: !formData[tearoffKey]})}
                    value={tearoffValues.title}/>)}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <FormNav
        canSubmit
        formIndex={formIndex}/>
    </>
  );
};

TearoffsSelectionForm.propTypes = {
  formIndex: PropTypes.number
};

export default TearoffsSelectionForm;
