import React from 'react';

const Blurb5_15B = () =>
  <div>
    <p>
      Your child has been referred for specialized support related to their behaviour. Behavioural
      interventions can focus on many areas of functioning, including self-regulation, cognition,
      and adaptive functioning. Programming that uses positive behavioural techniques and provides
      skill building for your child while also helping to reframe behaviour for caregiver(s) will
      be useful. It will be important to seek out programming that is FASD-informed.
    </p>
  </div>;

export default Blurb5_15B;
