import React from 'react';

const Blurb2_6B = () =>
  <div>
    <p>
      Motor difficulties were reported as an area of challenge for your child. Motor skills are required
      for our daily activities. Gross motor skills help us to walk, play sports, and exercise, while fine
      motor skills help us tie our shoes, draw, and button our shirts. Difficulties with motor skills can
      impact a child’s ability to complete everyday tasks, like writing their name or playing a sport.
      Motor skills can vary greatly from one person to another. Some tasks may come easily, like running,
      while others may be difficult, like tying shoes or fastening a button.
    </p>
    <p>
      Awareness of your child’s motor needs can also help others working with your child to identify supports
      or accommodations in your child’s environments, such as at home and at school. In this way, stress is
      reduced and access to strengths is increased. Motor functioning can be hard to predict sometimes.
      Observation can help to understand to then provide support. Caregivers can be an excellent source
      of information in describing what their child can and cannot do. With targeted intervention from
      clinicians, such as occupational therapists and physical therapists, motor development and functioning
      can be supported, and improvement is possible.
    </p>
  </div>;

export default Blurb2_6B;
