import React from 'react';
import FORMS from '../../redux/constants';
import PropTypes from 'prop-types';
import instructions from '../../locales/instructions';

const Slider = ({handleFormChange, formData, sliderType}) =>
  <div className='grid-x grid-margin-x align-middle padding-bottom-1'>
    <div className='cell'>
      <label
        className='form-field'>{FORMS.TEAROFFS.fields[sliderType].title}</label>
      <p>
        {instructions[sliderType].text}
      </p>
      <p>
        Suggested Sources: {instructions[sliderType].suggestedSource}
      </p>
    </div>
    <div className='cell'>
      <div className='slidecontainer'>
        <input
          className='slider'
          max='5'
          min='1'
          onChange={e => handleFormChange({...formData, [sliderType]: e.target.value})}
          type='range'
          value={formData[sliderType]}/>
        <div className='ticks'>
          <div className='tick'>1</div>
          <div className='tick'>2</div>
          <div className='tick'>3</div>
          <div className='tick'>4</div>
          <div className='tick'>5</div>
        </div>
      </div>
    </div>
  </div>;

export default Slider;

Slider.propTypes = {
  formData: PropTypes.object.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  sliderType: PropTypes.string.isRequired
};
