import React from 'react';
import PropTypes from 'prop-types';

const SectionTitle = ({children}) =>
  <div className='callout small primary margin-vertical-3'>
    <h2 className='text-center font-bold'>
      {children}
    </h2>
  </div>;

SectionTitle.propTypes = {
  children: PropTypes.any.isRequired
};

export default SectionTitle;
