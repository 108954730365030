import React from 'react';

const Blurb8_13C = () =>
  <div>
    <p>
      Adolescents with FASD, like your teen, require ongoing mental health and regulation support as they
      grow and meet major milestones, like the transition to adulthood. Planning ahead helps us to be proactive
      in providing supports. In turn, this can help us to think about goals we hope to achieve, instead of only
      focusing on problems to be managed. It will be important to continue to monitor your teen’s mental
      well-being, identify areas of potential strengths and needs, and provide appropriate supports and
      accommodations, such as therapy or opportunities for skill building, to ensure your teen’s mental
      health and regulation are supported.
    </p>
  </div>;

export default Blurb8_13C;
