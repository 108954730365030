import React from 'react';

const Blurb7_22D = () =>
  <div>
    <p>
      Your assessment team recommended that accessing community resources such as coaching may be
      helpful for you. Coaching or mentoring is important in adulthood. This allows us to learn
      with others, or alongside others. Learning by doing will be helpful and may draw on social
      strengths commonly described by individuals with FASD. Community engagement through social
      and recreational activities can also support your abilities to create and maintain social
      relationships and regulate your feelings.
    </p>
  </div>;

export default Blurb7_22D;
