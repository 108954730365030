import React from 'react';

const Blurb5_1C = () =>
  <div>
    <p>
      The education system in Canada aims to provide academic and applied supports to inform development
      and prepare children and teens for adulthood. Schools also provide a setting for students to pursue
      many other important future goals, like social and identity development, and employment planning. A
      variety of strengths and difficulties may emerge in school settings for children with FASD, in ways
      that are not always easy to predict or understand. This can make it difficult to get ahead of challenges
      and nurture strengths, and therefore requires individualized, collaborative, and goal-oriented approaches
      to support.
    </p>
    <p>
      Collaboration between school staff, families and caregivers, and other supports is important to establish
      a shared understanding of strengths and difficulties noted throughout this report. Through meaningful
      collaboration, strategies are co-created and geared towards achieving desired goals, success is reinforced
      and replicated, and supports are adapted as needed. Students with FASD will often require much more
      comprehensive planning that connects classroom, school, and home resources in ways that are developmentally
      appropriate and pave the way towards planning for adulthood.
    </p>
  </div>;

export default Blurb5_1C;
