import React from 'react';

const Blurb9_4D = () =>
  <div>
    <p>
      If already employed/or seeking employment, you would likely benefit from coaching and education surrounding
      the expectations of your position. You may want to access supports with the practical components of your job.
      This might include help remembering when you work (e.g., creating a schedule with them), help planning
      transportation to and from your job, or practicing what to do in certain situations. Co-workers and employers
      that work with you would likely benefit from education surrounding FASD to help create a positive and safe
      work environment. This FASD practical guide might be helpful to give to your teen’s employer: <a href='https://drive.google.com/file/d/1MIdXwlXOWOgvPSQwn4TwVHPNbslTLMX3/view'>
      https://drive.google.com/file/d/1MIdXwlXOWOgvPSQwn4TwVHPNbslTLMX3/view</a>
    </p>
  </div>;

export default Blurb9_4D;
