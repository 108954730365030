import React from 'react';

const Blurb5_6B = () =>
  <div>
    <p>
      Academics were identified as an area of difficulty for your child. When academic areas are impacted,
      your child may experience difficulties with problem solving, complex ideas, reasoning, language delays,
      academic achievement, and memory. With appropriate support and intervention, they may experience greater
      success with skills important to learning and responding to challenges within educational (and other)
      settings. Understanding the unique areas of difficulty present for your child is the first step. From
      there working collaboratively with educators and other systems of support may facilitate implementation
      of well suited environmental supports and intervention initiatives. In this way your child may be supported
      in functioning while also growing some of their skills – within reasonable expectations for them.
    </p>
  </div>;

export default Blurb5_6B;
