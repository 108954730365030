import React from 'react';

const Blurb3_1C = () =>
  <div>
    <p>
      Attachment describes the emotional bonds between your teen and the people in their life. When we
      experience safe and stable connections to others, we experience security. We gain confidence that
      we can safely explore and experience the world around us. Early bonding experiences are foundational
      to our development as they affect our later relationships and our ability to trust others, shape our
      beliefs about our own value and affect our self-esteem, and help us manage our emotional responses
      and regulate our emotions more effectively. Knowing this, we can work toward fostering health and
      long-lasting attachments.
    </p>
    <p>
      For your teen with FASD, this may mean supporting family cohesion and identifying individuals in
      their life that may fill an attachment role. This also involves understanding any possible long-term
      impacts of your teen’s early attachment experiences, while also recognizing that later attachment
      experiences can also be impactful.
    </p>
  </div>;

export default Blurb3_1C;
