import React from 'react';

const Blurb8_13E = () =>
  <div>
    <p>
      Individuals with FASD require ongoing mental health and regulation support as they grow and develop.
      Planning ahead helps us to be proactive in providing supports. In turn, this can help us to think about
      goals we hope to achieve, instead of only focusing on problems to be managed. To help with planning ahead,
      it will be important to continue to monitor your/your child’s mental well-being, identify areas of potential
      strengths and needs, and provide appropriate supports and accommodations, such as therapy or opportunities
      for skill building, to ensure your/your child’s mental health and regulation are supported.
    </p>
  </div>;

export default Blurb8_13E;
