import React from 'react';

const Blurb11_1B = () =>
  <div>
    <p>
      Although your child is young, at some point in time they may become a parent. Raising a child through
      parenting can be both rewarding and challenging and requires consistency, strength, and resilience. Many
      parents need support, including parents with FASD. With appropriate supports, all people with FASD can be
      successful and experience rewards in their role as a parent.
    </p>
  </div>;

export default Blurb11_1B;
