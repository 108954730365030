import React from 'react';

const Blurb3_3E = () =>
  <div>
    <p>
      For individuals with FASD attachment bonds may be formed in unexpected ways. When people make sense of
      or interact in the world differently due to brain-based differences, they may not always detect
      interpersonal cues as expected. For this reason, it can be very helpful to have support learning
      to identify their cues and ways to respond that make sense for them. To help with this, accessing
      support that focuses on your/your child’s relationship(s) with the central people in your/their life,
      like a parent, family member, partner, or friend, can improve your/your child’s sense of safety in
      relationships and your/their social environment. For children and teen’s, attachment therapy that
      focus on helping caregivers understand their teen’s unique brain, balance teen and caregiver needs,
      and adjust behaviours can support the development of more secure attachment bonds between children
      or teen’s with FASD and their caregiver(s).
    </p>
    <p>
      Even in circumstances where attachment is disrupted, it is possible to re-establish secure and strong
      relationships at all developmental periods. Intervention is important for moving towards healthy attachment
      and relationship outcomes in adolescence and adulthood.
    </p>
  </div>;

export default Blurb3_3E;
