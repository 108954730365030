import React from 'react';

const Blurb4_4D = () =>
  <div>
    <p>
      It will be important to continue to support your social skills across relationships and social settings,
      including home and work. Identifying other domains of healthy functioning such as community engagement
      where you can try out your skills and foster related abilities will be important to developing and
      building on strengths.
    </p>
  </div>;

export default Blurb4_4D;
