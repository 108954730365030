import React from 'react';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Blurb17_1A from '../../../blurbs/17_NoDiagnosisTearoff/Blurb17_1A';
import Blurb17_1B from '../../../blurbs/17_NoDiagnosisTearoff/Blurb17_1B';
import Blurb17_1C from '../../../blurbs/17_NoDiagnosisTearoff/Blurb17_1C';
import Blurb17_1D from '../../../blurbs/17_NoDiagnosisTearoff/Blurb17_1D';
import Blurb17_1E from '../../../blurbs/17_NoDiagnosisTearoff/Blurb17_1E';
import SectionTitle from '../components/SectionTitle';
import Variables from '../components/Variables';
import Card from '../components/Card';

const NoDiagnosisTearoff = () => {
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const variable = {age, diagnosis};
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS.id]);

  return (
    <section>
      <SectionTitle>No diagnosis Tear Off Sheet</SectionTitle>
      <Variables variableObject={variable}/>
      <Card title='My Unique Needs'>
        {diagnosis === '3' && age === '1' && <Blurb17_1A/>}
        {diagnosis === '3' && age === '2' && <Blurb17_1B/>}
        {diagnosis === '3' && age === '3' && <Blurb17_1C/>}
        {diagnosis === '3' && age === '4' && <Blurb17_1D/>}
        {diagnosis === '3' && age === '5' && <Blurb17_1E/>}
        {
          formData.no_diagnosis_tearoff && <p>{formData.no_diagnosis_tearoff}</p>
        }
      </Card>
    </section>
  );
};

export default NoDiagnosisTearoff;
