import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const OtherPrenatalExposureForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.OTHER_PRENATAL_EXPOSURES.id]);
  const dispatch = useDispatch();
  const otherPrenatalExposures = FORMS.OTHER_PRENATAL_EXPOSURES.fields;
  const fieldOptions = FORMS.OTHER_PRENATAL_EXPOSURES.options;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] !== '');
  const isFormComplete = Object.keys(otherPrenatalExposures).filter(key => key !== 'exposure_other').every(field => checkedFields.includes(field)) &&
    (formData.exposure_other ? formData.exposure_otherspecify.length > 0 : true);

  const handleFormChange = updatedFormData => {
    dispatch(submitForm(FORMS.OTHER_PRENATAL_EXPOSURES.id, updatedFormData));
  };

  return (
    <>
      <h2 className='form-header'>{FORMS.OTHER_PRENATAL_EXPOSURES.header}</h2>
      <div className='form-content'>
        <div className='lead group-name'>Other prenatal exposures:</div>
        <form>
          <div className='grid-x grid-margin-x show-for-medium'>
            <div className='small-12 medium-4 large-3 cell'/>
            {
              Object.values(fieldOptions).map(fieldOption =>
                <div
                  className='auto cell text-center'
                  key={fieldOption}>
                  <label className='form-field'>{fieldOption}</label>
                </div>)
            }
          </div>

          <div className='padding-bottom-1'>
            {Object.keys(otherPrenatalExposures).map(exposureKey =>
              <div
                className='grid-x grid-margin-x align-middle padding-bottom-1'
                key={exposureKey}>
                <div className='small-12 medium-4 large-3 cell'>
                  <label className='form-field'>{otherPrenatalExposures[exposureKey].title}</label>
                </div>
                <div className='cell medium-8 large-9 align-self-stretch'>
                  <div className='form-field-value grid-x small-up-2 medium-up-3 padding-bottom-1 height-100 align-middle round-top-borders round-bottom-borders'>
                    {Object.keys(fieldOptions).map(optionKey =>
                      <div
                        className='auto cell text-center'
                        key={optionKey}>
                        <input
                          checked={formData[exposureKey] === optionKey}
                          className='margin-right-0'
                          id={`answer_${optionKey}`}
                          key={optionKey}
                          name={exposureKey}
                          onChange={event => handleFormChange({...formData, [exposureKey]: event.target.value})}
                          type='radio'
                          value={optionKey}
                        />
                      </div>)}
                  </div>
                </div>
              </div>)}
            {
              formData.exposure_other && <>
                <label
                  className='form-field'
                  htmlFor={FORMS.OTHER_PRENATAL_EXPOSURES.fields.exposure_other.specify_other.id}>{FORMS.OTHER_PRENATAL_EXPOSURES.fields.exposure_other.specify_other.title}</label>
                <input
                  className='margin-bottom-0'
                  id={FORMS.OTHER_PRENATAL_EXPOSURES.fields.exposure_other.specify_other.id}
                  onChange={event => handleFormChange({...formData, [FORMS.OTHER_PRENATAL_EXPOSURES.fields.exposure_other.specify_other.id]: event.target.value})}
                  type='text'
                  value={formData[FORMS.OTHER_PRENATAL_EXPOSURES.fields.exposure_other.specify_other.id]}/>
              </>
            }
          </div>
        </form>
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

OtherPrenatalExposureForm.propTypes = {
  formIndex: PropTypes.number
};

export default OtherPrenatalExposureForm;
