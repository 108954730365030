import React from 'react';

const Blurb7_22E = () =>
  <div>
    <p>
      Your assessment team recommended that accessing community resources, such as coaching may be
      helpful for you/your child. Coaching, can help you/your child to develop a sense of
      community belonging and connection to others, while also continuing to support growth
      of adaptive skills. Learning by doing is often relationally based and can draw on this
      common area of strength among people with FASD.  Community engagement through social and
      recreational activities can also support your/your child’s abilities to create and maintain
      social relationships and regulate their feelings.
    </p>
  </div>;

export default Blurb7_22E;
