import React from 'react';

const Blurb2_1D = () =>
  <div>
    <p>
      Physical health is fundamental to daily living. Awareness and support that promotes physical health
      is a first step towards healthy outcomes across the lifespan.
    </p>
    <p>
      Adulthood is an important period for physical and cognitive development. Ongoing monitoring
      of your physical health, including hearing, vision, mobility, and diet, is important to your
      overall wellbeing. Individuals with FASD may  experience more physical health needs at earlier
      ages than might typically be expected – this means that continuing to look out for even unexpected
      needs is important. It is also important to access evidence-based information about sexual health,
      pregnancy, and healthy relationships.
    </p>
  </div>;

export default Blurb2_1D;
