import React from 'react';

const Blurb16_1D = () =>
  <div>
    <p>
      Fetal Alcohol Spectrum Disorder (FASD) is a diagnostic term that describes the impacts on the brains
      and bodies of people who experienced prenatal alcohol exposure. It is estimated that FASD affects 4%
      of the people living in Canada. People with FASD can experience a range of neurodevelopmental, mental,
      and physical health impacts, and elevated rates of environmental adversity – which may contribute to
      higher rates of justice system involvement.
    </p>
    <p>
      The first step to helping individuals with FASD navigate the justice system is to establish a shared
      understanding of their unique needs. Through a shared understanding of their unique brain we can provide
      individualized, proactive support so that you can experience success. It is important that support
      workers, lawyers, probation officers, police officers, and others who work within the justice system
      understand the brain based differences experienced by individuals with FASD to avoid misunderstandings
      regarding their functioning and behaviours. Information, resources, and training regarding FASD and its
      impacts to behaviour and functioning can be found at: <a href='https://canfasd.ca/online-learners/'/>
    </p>
    <p>
      Many factors can influence a person&apos;s likelihood of encountering and becoming involved in the justice
      system, including antisocial behaviours, adaptive skills, socioeconomic status, high-risk activities,
      and peer groups. Potential supports can include relationships with prosocial peers and supportive others,
      psychoeducation regarding their rights, and guidance regarding decision-making, which may help to offset
      risks experienced by children with FASD within the justice system. The Canada FASD Research Network offers
      further information regarding the rights of individuals with FASD in the justice system: <a href='https://canfasd.ca/topics/justice/'>https://canfasd.ca/topics/justice/</a>
    </p>
  </div>;

export default Blurb16_1D;
