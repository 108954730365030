import React from 'react';

const Blurb1_5D = () =>
  <div>
    <p>
      Tailored intervention is important to support your teen’s daily needs and behaviours.
    </p>
    <p>
      Based on the assessment results, you did not meet diagnostic criteria for Fetal Alcohol Spectrum Disorder
      (FASD) at this time. It will be important to continue to monitor your behaviours and functioning to
      support healthy outcomes throughout the lifespan.
    </p>
  </div>;

export default Blurb1_5D;
