import axios from 'axios';
import convertFormStateToFormData from '../../app/utils/convertFormStateToFormData';
import {config} from '../../config';

export const submitForm = (formType, payload) => ({
  type: formType,
  payload
});

export const saveToRedCap = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const forms = getState().forms;
  const formData = convertFormStateToFormData(forms);

  axios
    .post(config.redcap.proxy.url, formData)
    .then(result => resolve(result))
    .catch(error => reject(error));
});
