import React from 'react';
import Blurb1_3B from '../1_MyUniqueNeeds/Blurb1_3B';

const Blurb14_2B = () =>
  <div>
    <p>
      Fetal Alcohol Spectrum Disorder (FASD) is a diagnostic term that describes the impacts on the brains and
      bodies of people who experienced prenatal alcohol exposure. It is estimated that FASD affects 4% of the
      people living in Canada. People with FASD can experience a range of neurodevelopmental, mental, and
      physical health impacts, and elevated rates of environmental. 90% of individual with FASD
      with mental health difficulties. Consequently this is a unique and diverse population often referred
      to clinical services for help in many areas, such as building developmental skills (e.g. speech,
      language or motor skills) or for support with mental health needs (e.g. psychologist, psychiatrist
      or mental health specialist). As our knowledge grows, researchers are increasing also identifying the
      physical differences individual with FASD experience – which often include high levels of health needs,
      often at ages much younger than expected.
    </p>
    <p>
      For clinicians responding to these diverse and sometimes unique needs, a knowledge of FASD can help
      guide treatment planning and clinical decision making, and facilitate early intervention efforts and
      proactive support implementation. Clinicians can also play a unique role in helping to advance understandings,
      coordinating of support teams or advocating for integrated and cohesive structure and support across settings.
      Optimally clinicians can build relationships with individuals and families, in which lived expertise is
      integrated within planning and support initiatives. With recognition and appropriate supports from clinicians
      like yourself, people with FASD can experience healthy outcomes.
    </p>
    <p>
      Training resources and information is
      available at <a href='https://canfasd.ca/publications/towards-healthy-outcomes/'>
      https://canfasd.ca/publications/towards-healthy-outcomes/</a> and <a href='https://canfasd.ca/online-learners/'>
      https://canfasd.ca/online-learners/</a>
    </p>
    <p>
      Prenatal alcohol exposure (PAE) can affect both the brains and bodies of people in different ways
      for each person. Your client’s needs indicate that they have FASD without Sentinel Facial Features.
      This means that while some individuals may have physical features that are unique to PAE, other
      individuals like your client, do not. These features do not necessarily provide information about
      your client’s unique brain- or body-based needs. Through individualized, ongoing supports, individuals
      with FASD can experience success and achieve healthy outcomes.
    </p>
    <Blurb1_3B/>
  </div>;

export default Blurb14_2B;
