import React from 'react';

const Blurb13_1A = () =>
  <div>
    <p>
      The Canadian education system aims to provide students with the skills needed to transition into adulthood
      to successfully navigate occupational and social settings. Experiences in the school setting not only help
      to build academic and potential employment skills, but are also critical spaces for navigating peer
      interactions and building a sense of personal identity. We learn who we are, what we do well, and build
      social networks that influence our decision making and our trajectories forward.
    </p>
    <p>
      School personal are important to supporting these developmental processes. Teachers, educational assistants,
      administration and support staff all play a role. This is particularly true for children and youth with brain
      based differences such as FASD; for them navigating the school environment and experiencing success may
      require the combined, and even creative, efforts of the entire educational team – often in close collaboration
      with caregivers. FASD is a unique disability, and can be very diverse in presentation. Often, areas of
      strength and difficulty are scattered within individuals’ skills sets – consequently small changes in
      task demands can have big impacts on functioning. Establishing a shared understanding of the unique needs
      and strengths of each student with FASD provides a good starting point for comprehensive planning, in which
      a strengths based, brain-informed understanding of the student is central. As this student progresses through
      pre- and elementary school, it will be important for their teachers to consider their unique strengths, needs,
      and goals when developing an individualized and supportive education plan.
    </p>
    <p>
      Understanding mental health is another important part of student’s learning and education journey. Mental
      health refers to emotional and psychological well-being and plays an important role within classrooms to
      influence learning outcomes. Ensuring that the classroom environment meets this student’s mental health
      needs will be important for their learning.
    </p>
    <p>
      For more information around understanding ways in which observed behaviour may reflect underlying brain
      differences or mental health needs for students with FASD, refer to the “Brain not Blame” handout from CanFASD: <a href='https://www.engagingalllearners.ca/il/supporting-students-with-fasd/documents/brain-not-blame.pdf'>
        https://www.engagingalllearners.ca/il/supporting-students-with-fasd/documents/brain-not-blame.pdf</a>
    </p>
    <p>
      Access to FASD education may also help to build upon educator skills to create well suited environments
      and inform appropriate . The Canada FASD Research Network offers free online FASD resources and training: <a href='https://estore.canfasd.ca/foundations-in-fasd'>https://estore.canfasd.ca/foundations-in-fasd</a>
    </p>
  </div>;

export default Blurb13_1A;
