import React from 'react';

const Blurb3_6C = () =>
  <div>
    <p>
      During the assessment your team made recommendations regarding daycare supports. Daycare includes day-to-day
      support and typically involves early childhood educators or childcare assistants that work in childcare
      programs. Daycare can provide primary caregiver(s) with a break from the daily routine of caregiving but
      also provides opportunities for relationship development and healthy bonding with others for children.
      Daycare programs can vary in structure with different activities or routines. It will be important that
      the daycare provider is FASD informed and can be responsive to individual abilities and needs to foster
      healthy attachment and development.
    </p>
  </div>;

export default Blurb3_6C;
