import React from 'react';

const Blurb7_254 = () =>
  <div>
    <p>
      Decision making is a necessary skill and offering developmentally appropriate opportunities for choice
      helps to foster interdependence. The assessment team recommended exploring the possibility of developing
      a power of attorney, or a legal document that allows another person to act on their behalf in relation
      to specific areas outlined, such as managing finances or making legal decisions. Choosing a trusted person
      who is FASD-informed is important in ensuring appropriate planning for future decisions.
    </p>
  </div>;

export default Blurb7_254;
