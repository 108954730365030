import {configureStore} from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import createRootReducer from './reducers';
import {routerMiddleware} from 'connected-react-router';
import googleAnalyticsMiddleware from './middleware/googleanalytics';
import {createBrowserHistory} from 'history';

export const history = createBrowserHistory();
const middlewares = [
  routerMiddleware(history),
  googleAnalyticsMiddleware
];
const persistConfig = {key: 'root', storage, blacklist: ['router']};
const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

export default () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }).concat(middlewares)
  });

  const persistor = persistStore(store);

  // persistor.purge();

  return {store, persistor};
};
