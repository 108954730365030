import React from 'react';

const Blurb7_253 = () =>
  <div>
    <p>
      Decision making is a necessary skill and offering developmentally appropriate opportunities for
      choice helps to foster interdependence. The assessment team recommended exploring the possibility
      of appointing a substitute decision maker, or a person recognized by law to make decisions on
      another’s behalf, such as in relation to finances or personal care. This person is usually appointed
      through a legal process, such as a guardianship, or power of attorney, and has the authority to make
      important decisions. Choosing a trusted person who is FASD-informed is important in ensuring appropriate
      planning for future decisions.
    </p>
  </div>;

export default Blurb7_253;
