import React from 'react';

const Blurb4_4A = () =>
  <div>
    <p>
      It will be important to continue to monitor and support the development of your child’s social skills,
      particularly as they begin to transition to school age and expectations for social interactions grow.
      Planning for social skill building opportunities during later life stages such as adolescence is encouraged.
      For instance, mentorship programming has been identified as one way to assist with social skill development
      with teens and adults with FASD, in a relational way, often while engaging in desired activities.
    </p>
    <p>
      Identifying other domains of healthy functioning such as community engagement where your child can practice
      these skills and foster related abilities will be important to developing and building on strengths.
    </p>
  </div>;

export default Blurb4_4A;
