import React from 'react';
import SectionTitle from '../components/SectionTitle';
import '../../../../assets/stylesheets/pages/report.scss';

const Cover = () =>
  <section className='margin-horizontal-2'>
    <div
      className='button hide-for-print'
      onClick={() => window.print()}>Print/Save in PDF</div>
    <div className='text-uppercase'>
      <SectionTitle>Compass Report</SectionTitle>
    </div>
    <div className='grid-x align-center'>
      <div className='small-10 medium-6 cell'>
        <div className='small text-center margin-top-3 font-bold'>
            Client-Oriented Mapping Point of Care access to Support and Services
          <div className='margin-vertical-3'>
            <img
              alt='Compass FASD Logo'
              src='https://via.placeholder.com/250'/>
          </div>
        </div>
      </div>
    </div>
    <div className='grid-x margin-top-3 margin-bottom-1'>
      <div className='cell text-left'>
              Date: 2022
      </div>
    </div>
    <div className='font-italic text-center'>
      Clinicians: Please <span className='font-bold'>PRINT</span> this document and give to your patient.
    </div>
  </section>;

export default Cover;
