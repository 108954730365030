import React from 'react';

const Blurb6_1E = () =>
  <div>
    <p>
      Identity is an important aspect of our self-concept that distinguishes a person’s unique qualities, values,
      and goals. Identity develops over the lifespan, but times of important life changes and transitions,
      such as adolescence, can particularly inform identity development. Identity is informed and shaped
      by many factors, including education/career goals, disabilities, spiritual experiences, and
      self-expression including gender and sexual identities. This can result in a broad spectrum of
      potential identity strengths and needs that are often woven together in unique ways for each person.
    </p>
    <p>
      It is important to support healthy development of identity because it plays a role in many aspects of
      our life, including social and adaptive functioning. Talking about FASD in a balanced way, to reflect
      the presence of both challenges and strengths, demonstrates that you/your child are/is accepted and
      valued for who you/they are. By highlighting personal value and remaining aware of stigmatizing or
      demeaning views about FASD, together we may help to prevent the development of negative self-perceptions
      about FASD and help you/your child appreciate your/their inherent value and potential for contributing
      meaningfully to the community.
    </p>
  </div>;

export default Blurb6_1E;
