import React from 'react';

const Blurb10_1D = () =>
  <div>
    <p>
      Access to safe, affordable housing is a basic human right, along with access to food and water that
      must be met to create conditions necessary for further development and growth.
    </p>
    <p>
      Obtaining safe, consistent housing for individuals with FASD can sometimes be complex due to a range
      of barriers, including affordability, access to housing supports, quality of housing, and mental
      health needs. Addressing these factors requires a coordinated approach including governmental and
      other community organizations working together to find solutions. The following housing guide can
      be used by housing workers, landlords, and other housing supports to help individuals with FASD achieve success
      related to housing. <a href='https://canfasd.ca/wp-content/uploads/2019/10/FASD-X-Housing-Pei-2018_Amended-March-04-2019-dl.pdf'>
      https://canfasd.ca/wp-content/uploads/2019/10/FASD-X-Housing-Pei-2018_Amended-March-04-2019-dl.pdf</a>
    </p>
  </div>;

export default Blurb10_1D;
