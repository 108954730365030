import React from 'react';

const Blurb8_6A = () =>
  <div>
    <p>
      Along with FASD, your child has been diagnosed with autism spectrum disorder. Autism is a neurodevelopmental
      disorder that  is related to social emotional and behavioural functioning. Just like individuals with FASD
      every child with autism is unique. Researchers are still learning how autism and FASD are related and interact.
      The first step to understanding your child with FASD and autism is to recognize their unique patterns of
      strengths and needs to best support their functioning. You may also find it helpful to connect with local
      autism support services in your community.
    </p>
  </div>;

export default Blurb8_6A;
