import React from 'react';

const Blurb10_1A = () =>
  <div>
    <p>
      Although it may seem a long time away, there will come a time when you and your child will be considering
      their best housing options. Access to safe, affordable housing is a basic human right that must be met to
      create conditions necessary for further development and growth. Finding housing that meets your child’s
      unique needs also allows them to establish an effective system of interdependency. Even when your child
      is young, we can consider ways in which we might support success and how your child’s individual and
      environmental characteristics may interact. You may ask yourself, when the time comes, ‘what will look
      like a good fit for my child when it comes to housing?” This will change and evolve as your child develops,
      but watching for the goodness of fit elements, in a productive way, may help to imagine how later supports
      may unfold.
    </p>
    <p>
      Accessing safe, consistent housing for individuals with FASD can sometimes be complex due to a range of
      barriers, including affordability, access to housing supports, quality of housing, and mental health needs.
      Addressing these factors requires a coordinated approach including governmental and other community
      organizations working together to find solutions. The following housing guide can be used by housing
      workers, landlords, and other housing supports to help individuals with FASD achieve success related to housing. <a href='https://canfasd.ca/wp-content/uploads/2019/10/FASD-X-Housing-Pei-2018_Amended-March-04-2019-dl.pdf'>
      https://canfasd.ca/wp-content/uploads/2019/10/FASD-X-Housing-Pei-2018_Amended-March-04-2019-dl.pdf</a>
    </p>
  </div>;

export default Blurb10_1A;
