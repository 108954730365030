import React from 'react';

const Blurb5_16A = () =>
  <div>
    <p>
      Education is an important part of your child’s development that informs goal planning and achievement
      throughout childhood, adolescence, and early adulthood. As your child continues their education
      journey and enters elementary school, it will be important for educators to consider your child’s
      unique strengths, needs, and goals when developing an individualized and supportive education plan
      for them. Your child may benefit from adaptations to programming that allow for more active
      participation in “hands-on experiences,” time to process information and think about content,
      and opportunities to share their knowledge with others. In this way your child can remain engaged
      at school and build skills to support their transition to elementary school and beyond.
    </p>
  </div>;

export default Blurb5_16A;
