import React from 'react';

const Blurb5_6E = () =>
  <div>
    Academics were identified as an area of difficulty for this individual. When academics are difficult,
    they  may experience difficulties with problem solving, complex ideas, reasoning, language delays,
    academic achievement, and difficulty with memory. With appropriate support and intervention, they
    may experience greater success with, skills important to learning and responding to challenges
    within educational (and other) settings. Understanding the unique areas of difficulty present
    for this individual is the first step. Individualized classroom intervention, such as a teacher&apos;s
    assistant, FASD education, and/or early intervention, can help to address challenges and build on
    strengths to support learning and academic achievement. From there, working collaboratively with
    educators and other systems of support may facilitate implementation of well suited environmental
    supports as well as exploration of intervention initiatives.  In this way you/your child may be
    supported in functioning at your/their best while simultaneously being supported in growing skills – within
    reasonable expectations for you/them.
  </div>;

export default Blurb5_6E;
