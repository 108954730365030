import React from 'react';

const Blurb9_2D = () =>
  <div>
    <p>
      Preparing for, obtaining, and maintaining employment is an intensive process for anyone - and adults with
      FASD have experienced success with supported employment. The Canada FASD Research Network offers a list of
      employment programs and services available by geographical region to support individuals with FASD as they
      seek to prepare for, obtain, and maintain employment: <a href='https://canfasd.ca/2022/01/21/employment-services-for-people-with-fasd/'>
      https://canfasd.ca/2022/01/21/employment-services-for-people-with-fasd/</a>
    </p>
    <p>
      Determining whether to disclose a diagnosis such as FASD with employers is a serious decision that each person
      with FASD needs to make for themselves. Receiving appropriate supports and guidance regarding such a decision
      will be helpful in supporting your employment. Sharing an FASD diagnosis with employers can be important for
      receiving proper support and accommodations in the workplace and can help to increase empathy and reduce stigma.
    </p>
    <p>
      Providing employment support workers with FASD informed resources can help them to better support people
      with FASD like you seek employment. The Canada FASD Research Network offers an Enhanced Employment Guide
      for Employment Professionals Working with Individuals with FASD: <a href='https://canfasd.ca/wp-content/uploads/2018/09/Guide-and-Final-Report-Supporting-Employment-Success-in-FASD.pdf'>
      https://canfasd.ca/wp-content/uploads/2018/09/Guide-and-Final-Report-Supporting-Employment-Success-in-FASD.pdf</a>
    </p>
  </div>;

export default Blurb9_2D;
