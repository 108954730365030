import React from 'react';

const Blurb7_3A = () =>
  <div>
    <p>
      Interdependence is an integral part of  daily functioning throughout the lifespan and is composed of
      skills related to community engagement and adaptive functioning. There are very few people who do not,
      in some capacity, receive supports from others in their day-to-day life. Skills including decision-making,
      self-care, hygiene, money management, and social functioning all inform the level of interdependence an
      individual requires. Individuals with FASD, like your child, demonstrate a wide range of strengths and
      needs related to interdependence throughout the lifespan. Supporting your child’s ability to navigate
      their community and adaptive functioning are important to fostering interdependence and leveraging strengths.
    </p>
    <p>
      As children grow and develop, they begin to seek greater autonomy and independence. Ongoing support,
      including coaching, peer mentorship, and decision-making guides will be necessary to foster your child’s
      need for greater choice and autonomy while still providing developmentally appropriate assistance.
    </p>
  </div>;

export default Blurb7_3A;
