import React from 'react';

const Blurb3_2D = () =>
  <div>
    <p>
      Stable and consistent interactions that foster positive attachment with caregivers, peers, colleagues,
      and supportive others are important for developing healthy relationships. Connecting with FASD-informed
      supports and community agencies may provide a helpful step in developing positive and supportive relationships,
      through peer groups, mentorship programs, and other social opportunities.
    </p>
  </div>;

export default Blurb3_2D;
