import React from 'react';

const Blurb5_5D = () =>
  <div>
    <p>
      Your processing speed has been assessed and identified as an area of need. Processing speed is the ability
      to quickly and efficiently take in, interpret, and act on new information. Difficulties in processing speed
      can have impacts on your ability to learn, think, or act quickly. It is also possible you’re your processing
      speed may be variable – for many individuals with FASD slight changes to task expectations can have a much
      bigger than expected impact on their functioning, and in particular their ability to think and work rapidly.
      Strategies to support processing speed can include more time to complete tasks, simplifying the number and
      nature of inputs, repeated instructions, and/or pairing verbal and visual instructions.
    </p>
  </div>;

export default Blurb5_5D;
