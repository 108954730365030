import React from 'react';

const Blurb11_2D = () =>
  <div>
    <p>
      Parenting requires a wide range of skills, including problem-solving, empathy, patience, stress management,
      communication, flexibility, autonomy, and adaptability. These skills help parents raise children with love
      and care, promote their development, and provide them with safe and supportive environments to thrive in.
      Promoting healthy parents is an important ingredient to family stability and positive relationships among
      family members.
    </p>
    <p>
      If the time comes and you become a parent, it will be important to consider which supports will best fit
      your needs. Supports can allow you to experience success throughout your parenting journey. Potential
      supports to consider include in-home services, advocacy, counselling, education, and pregnancy programs
      that leverage and support your unique strengths and needs.
    </p>
  </div>;

export default Blurb11_2D;
