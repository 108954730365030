import React from 'react';

const Blurb7_23E = () =>
  <div>
    <p>
      The assessment team recommended that supports to increase your/your child’s safety may be helpful.
    </p>
    <p>
      Safety is essential for both physical and mental well-being. It will be important to help you/your child
      foster a sense of safety through trusting, consistent relationships and safety supports in their
      daily life. Creating a stable, caring, and dependable environment will enhance their sense of safety,
      connectedness, and autonomy as they grow and develop over time.
    </p>
  </div>;

export default Blurb7_23E;
