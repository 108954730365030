import React from 'react';

const Blurb5_12C = () =>
  <div>
    <p>
      Your assessment team has recommended that your teen receive educational re-assessment as they progress
      through the education system. Targeted intervention support to address challenges with learning and
      academic achievement is possible with up to date understanding of your teen’s areas of strengths and needs.
    </p>
  </div>;

export default Blurb5_12C;
