import React from 'react';

const Blurb7_24B = () =>
  <div>
    <p>
      Food security was identified as an area of need for you and your child and it may be helpful to
      contact community organizations or your support team about accessing your local foodbank or other services.
    </p>
  </div>;

export default Blurb7_24B;
