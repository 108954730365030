import React from 'react';

const Blurb2_5D = () =>
  <div>
    <p>
      Sensory abilities include the five senses of smell, sight, taste, touch, and sound. Our senses help
      us all to regulate and respond to sensory information, like pain, temperature, and textures. You may
      have noticed that you experience sensory input differently. This may be due to the brain-based
      differences present in FASD. Lights may be too bright, sounds too loud, and textures uncomfortable.
      You may not experience pain in the same ways – sometimes pain may hurt more than expected and other
      times not as much as expected.
      This can even create safety concerns for you – especially in cold weather.
      Awareness of your motor and sensory needs can also help us to increase understanding
      for important people in your life. Strategies and accommodations in your environments
      can be put in place to reduce your stress and increase access to your strengths.
    </p>
  </div>;

export default Blurb2_5D;
