import React, {useEffect} from 'react';
import ROUTES, {FORM_ROUTES} from '../../../routes';
import PropTypes from 'prop-types';
import NoRoute from '../404';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import FORMS from '../../../redux/constants';
import Timeout from '../../components/timeout';
import MetaHelmet from '../../components/MetaHelmet';

export const BaseForm = ({remaining}) => {
  const {url} = useParams();
  const navigate = useNavigate();
  const formIndex = FORM_ROUTES.findIndex(route => route.url === url);
  const Component = FORM_ROUTES[formIndex]?.component;
  const title = FORM_ROUTES[formIndex]?.title;
  const progressData = useSelector(state => state.formProgress);
  const formProgress = Object.entries(progressData);
  const formKey = Object.keys(FORMS)[formIndex];

  useEffect(() => {
    if (!progressData[formKey]) {
      const incompleteFormsArray = formProgress.find(form => !form[1]);
      const nextForm = FORMS[incompleteFormsArray[0]];
      navigate(generatePath(ROUTES.FORMS, {url: nextForm.url}));
    }
  }, []);

  return formIndex >= 0
    ? <>
      <Timeout remaining={remaining}/>
      <div className='form-wrapper'>
        <MetaHelmet title={title}/>
        <Component
          formIndex={formIndex}
          formUrl={url}
        />
      </div>
    </>
    : <NoRoute/>;
};

BaseForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      url: PropTypes.string
    })
  }),
  remaining: PropTypes.number.isRequired
};
