import React from 'react';

const Blurb2_12C = () =>
  <div>
    <p>
      Adolescents with FASD, like your child, require ongoing health support as they transition into high
      school and adulthood. Planning ahead helps us be proactive and focus on providing supports and
      achieving goals, rather than just managing problems. To help with proactive planning ahead,
      Dr. Hanlon-Dearman and colleagues created an anticipatory guide for primary health care
      providers working with individuals with FASD: <a href='https://jptcp.com/index.php/jptcp/article/view/275/228'>https://jptcp.com/index.php/jptcp/article/view/275/228</a>
    </p>
  </div>;

export default Blurb2_12C;
