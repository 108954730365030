import React from 'react';

const Blurb5_6C = () =>
  <div>
    <p>
      Academics were identified as an area of difficulty for your teen. When academics are difficult, your teen
      may experience difficulties with problem solving, complex ideas, reasoning, language delays, academic
      achievement, and memory. With appropriate support and intervention, they may experience greater success
      with skills in these domains can be improved. In other words, skills important to learning and responding
      to challenges within educational (and other) settings. Understanding the unique areas of difficulty present
      for your teen is the first step. From there working collaboratively with educators and other systems of
      support may facilitate implementation of well suited environmental supports as well as exploration of
      intervention initiatives. In this way your child may be supported in functioning at their best while
      simultaneously being supporting in growing some of their skills – within reasonable expectations for them.
    </p>
  </div>;

export default Blurb5_6C;
