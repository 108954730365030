import React from 'react';

const Blurb3_3C = () =>
  <div>
    <p>
      Your teen has encountered one or more disrupted attachment experiences. Intervention and can foster
      healthy attachment and relationship outcomes through adolescence and into adulthood. Even in
      circumstances where attachment is disrupted, it is possible to re-establish secure and strong
      relationships at all developmental periods.
    </p>
    <p>
      Providing support that specifically focuses on your teen’s relationship with their caregiver(s)
      may be an effective way to help them learn to read and respond to cues. This targeted support
      can also help identify appropriate ways to respond to your child’s cues based on their individual
      needs and in ways that make sense to them. Attachment therapy that focus on helping caregivers
      understand their teen’s unique brain, balance teen and caregiver needs, and adjust behaviours
      can support the development of more secure attachment bonds between teen’s with FASD and their caregiver(s).
    </p>
  </div>;

export default Blurb3_3C;
