import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = ({value, onChange, checked, className}) =>
  <div className={className}>
    <div className='grid-x align-middle'>
      <div className='shrink cell'>
        <input
          checked={checked}
          id={`answer_${value}`}
          onChange={onChange}
          type='checkbox'
          value={value}
        />
      </div>
      <div className='auto cell'>
        <label htmlFor={`answer_${value}`}>{value}</label>
      </div>
    </div>
  </div>;

CheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default CheckBox;
