import React from 'react';

const Blurb3_5E = () =>
  <div>
    <p>
      Feedback from your/your child’s assessment suggested that respite may be a helpful support. Respite
      involves short term, temporary out of home care that provides caregiver(s) with time away from the
      daily routine of caring for others. Respite can support caregiver well-being, family functioning,
      and relationships between the caregiver(s) and child. Respite can be provided by different people,
      including a worker, family member, or a friend, and can vary from a few hours to a few weeks. Community
      or government organizations or your/your child’s  support team can be helpful resources to access
      respite supports.
    </p>
  </div>;

export default Blurb3_5E;
