import React from 'react';

const Blurb5_3A = () =>
  <div>
    <p>
      Your child’s intellectual functioning has been assessed and identified as an area of need. This means
      that compared with other children their age, how your child learns, reasons, uses language, and solves
      problems may be different, and this may in turn impact their experience of success in school. To support
      your child’s academic achievement, early intervention, modified programming, appropriate accommodations
      and strategies, and ongoing monitoring of their development will be important. Identified needs in this
      area may also be part of an individual education plan (IEP) to support their unique learning needs at school.
    </p>
  </div>;

export default Blurb5_3A;
