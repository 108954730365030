import React from 'react';

const Blurb10_1C = () =>
  <div>
    <p>
      Soon, if not already, there will come a time when you and your teen will be considering their best
      housing options. Access to safe, affordable housing is a basic human right that must be met to create
      conditions necessary for further development and growth. Finding housing that meets your teen’s
      unique needs also allows them to establish an effective system of interdependency. We can consider
      ways in which we might support success and how your teen’s individual and environmental characteristics
      may interact. You and your teen may ask yourselves, ‘what will look like a good fit when it comes to housing?”
    </p>
    <p>
      Accessing safe, consistent housing for individuals with FASD can sometimes be complex due to a range of
      barriers, including affordability, access to housing supports, quality of housing, and mental health
      needs. Addressing these factors requires a coordinated approach including governmental and other
      community organizations working together to find solutions. The following housing guide can be used
      by housing workers, landlords, and other housing supports to help individuals with FASD achieve success
      related to housing. <a href='https://canfasd.ca/wp-content/uploads/2019/10/FASD-X-Housing-Pei-2018_Amended-March-04-2019-dl.pdf'>
      https://canfasd.ca/wp-content/uploads/2019/10/FASD-X-Housing-Pei-2018_Amended-March-04-2019-dl.pdf</a>
    </p>
  </div>;

export default Blurb10_1C;
