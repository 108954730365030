import React from 'react';

const Blurb10_9B = () =>
  <div>
    <p>
      Although a long way away for your child, housing is a lifelong need, with related supports, accommodations,
      and strategies changing as we continue to grow and develop. To help your child one day prepare for obtaining
      and maintain safe and affordable housing, it is important to identify their unique strengths and needs,
      recognize when they need supports, and create opportunities to safely practice necessary skills in a
      safe environment.
    </p>
  </div>;

export default Blurb10_9B;
