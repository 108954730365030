import React from 'react';

const Blurb7_2E = () =>
  <div>
    <p>
      Individuals with FASD may experience variable skills, with challenges potentially including
      difficulties setting healthy personal boundaries, reading social cues, completing activities
      of daily living, and both home and community safety concerns. Supportive adults can assist
      them in developing their skills through intentional community engagement and directive skill
      building activities. Providing structured and supportive routines may boost skill growth and
      help with functioning. Proactive and intentional supervision in the community can help support
      safe opportunities to build skills and experience success. Collaboration between support teams
      is very important to create the balance between learning and support, characteristics of
      interdependence that will change with age and stage.
    </p>
  </div>;

export default Blurb7_2E;
