import React from 'react';

const Blurb2_1E = () =>
  <div>
    <p>
      Physical health is fundamental to daily living. Awareness and support that promotes physical health
      is a first step towards healthy outcomes across the lifespan.
    </p>
    <p>
      Physical and cognitive development is an ongoing process throughout the lifespan. Ongoing monitoring
      of your child’s/yours physical health, including their hearing, vision, mobility, and diet, is important
      to support overall wellbeing. Individuals with FASD may experience more physical health needs at earlier
      ages than might typically be expected – this means that ongoing vigilance even for unexpected needs is
      important. It is also important to access age and developmentally appropriate evidence-based information
      about sexual health, pregnancy, and  healthy relationships.
    </p>
  </div>;

export default Blurb2_1E;
