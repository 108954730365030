import React from 'react';

const Blurb9_1D = () =>
  <div>
    <p>
      People with FASD can bring many strengths to the workplace such as curiosity, eagerness to work with and
      help others, creativity, and kindness. They can also experience barriers on the pathway to successful
      employment. People with FASD may experience brain-based differences that can impact how they work and
      traditional work environments are often not set up to support their success. Employers may not understand
      FASD, leading to possible stigma. With the right supports, people with FASD can obtain and maintain
      employment. Being meaningfully employed can support healthy development in adulthood, foster a greater
      sense of purpose, self-identity, self-esteem, and self-worth, feelings of connection to community and
      others, and increased understanding of strengths and abilities. Fostering skills and strengths related
      to employment will be important to support achieving your employment goals.
    </p>
  </div>;

export default Blurb9_1D;
