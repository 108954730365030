import React from 'react';

const Blurb4_2C = () =>
  <div>
    <p>
      Adolescents with FASD often have difficulties with social skills because brain-based differences can
      make it more challenging to regulate emotions, read social cues, and interact positively with others
      in social settings. This can be stressful for adolescents with FASD who often value connections and
      relationships, particularly during the teen years when peers become more important to developing youth.
      With appropriate interventions and skill building opportunities, adolescents can further develop their
      social skills and improve their interactions with peers, make and maintain friendships, and begin to regulate
      their emotions across social contexts. Your teen’s relational strengths which may include the ability to
      reciprocate affection, friendliness, and the desire to please others may be leveraged in the development
      of social skills.
    </p>
  </div>;

export default Blurb4_2C;
