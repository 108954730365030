import React from 'react';

const Blurb3_3B = () =>
  <div>
    <p>
      Your child has encountered one or more disrupted attachment experiences. Early childhood is an important
      time for intervention and can foster healthy attachment and relationship outcomes in adolescence and
      adulthood. Even in circumstances where attachment is disrupted, it is possible to re-establish secure
      and strong relationships at all developmental periods.
    </p>
    <p>
      Early behavioural interventions, such as parent-child interaction therapy (PCIT), that focus on helping
      caregivers understand their child’s unique brain, balance child and caregiver needs, and adjust behaviours
      can support the development of more secure attachment bonds between children with FASD and their caregiver(s).
    </p>
  </div>;

export default Blurb3_3B;
