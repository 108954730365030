import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import '../../../../assets/stylesheets/pages/report.scss';
import {useSelector} from 'react-redux';
import Blurb12_1A from '../../../blurbs/12_CommunityTearoffs/Blurb12_1A';
import Blurb12_1B from '../../../blurbs/12_CommunityTearoffs/Blurb12_1B';
import Blurb12_1C from '../../../blurbs/12_CommunityTearoffs/Blurb12_1C';
import Blurb12_1D from '../../../blurbs/12_CommunityTearoffs/Blurb12_1D';
import Blurb12_1E from '../../../blurbs/12_CommunityTearoffs/Blurb12_1E';
import Variables from '../components/Variables';
import FORMS from '../../../../redux/constants';

const CommunityTearoff = () => {
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const variables = {age};
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS.id]);

  return <section>
    <SectionTitle>Community Tear Off Sheet</SectionTitle>
    <Variables variableObject={variables}/>
    <Card
      title='My Community Needs'>
      {age === '1' && <Blurb12_1A/>}
      {age === '2' && <Blurb12_1B/>}
      {age === '3' && <Blurb12_1C/>}
      {age === '4' && <Blurb12_1D/>}
      {age === '5' && <Blurb12_1E/>}
      {
        formData.community_tearoff && <p>{formData.community_tearoff}</p>
      }
    </Card>
  </section>;
};

export default CommunityTearoff;
