import React from 'react';

const Blurb1_5E = () =>
  <div>
    <p>
      Early and tailored intervention is important to support you/your child’s daily needs and behaviours.
    </p>
    <p>
      Based on the assessment results, you/your child did not meet diagnostic criteria for Fetal Alcohol
      Spectrum Disorder (FASD) at this time. It will be important to continue to monitor your/your child’s
      behaviours and functioning to support healthy outcomes throughout the lifespan.
    </p>
  </div>;

export default Blurb1_5E;
