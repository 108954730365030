import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const PrenatalAlcoholForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.PRENATAL_ALCOHOL_EXPOSURE.id]);
  const isFormComplete = formData.exposure !== '' &&
    (formData.exposure === FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.condition
      ? formData.exposure_specify.length > 0 : true);
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    if (formData.exposure !== updatedFormData.exposure) {
      updatedFormData[FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.id] = FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.value;
    }

    dispatch(submitForm(FORMS.PRENATAL_ALCOHOL_EXPOSURE.id, updatedFormData));
  };

  const prenatalAlcoholExposureOptions = FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.options;

  return (

    <>
      <h2 className='form-header'>{FORMS.PRENATAL_ALCOHOL_EXPOSURE.header}</h2>
      <div className='form-content'>
        <form>
          <div className='grid-x grid-margin-x'>
            <div className='small-12 medium-4 large-3 cell'>
              <label className='form-field'>{FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.title}</label>
            </div>
            <div className='cell medium-8 large-9'>
              <div className='form-field-value-rounded-borders'>
                <div className='grid-x small-up-1 medium-up-2'>
                  {Object.keys(prenatalAlcoholExposureOptions).map(optionKey =>
                    <RadioButton
                      checked={formData.exposure === optionKey}
                      className='cell'
                      key={optionKey}
                      label={prenatalAlcoholExposureOptions[optionKey]}
                      onChange={event => handleFormChange({...formData, exposure: event.target.value})}
                      value={optionKey}/>)}
                </div>
                {
                  formData.exposure === FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.condition && <>
                    <label
                      htmlFor={FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.id}>{FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.title}</label>
                    <input
                      id={FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.id}
                      onChange={event => handleFormChange({...formData, [FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.id]: event.target.value})}
                      type='text'
                      value={formData[FORMS.PRENATAL_ALCOHOL_EXPOSURE.fields.exposure.specify_other.id]}/>
                  </>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

PrenatalAlcoholForm.propTypes = {
  formIndex: PropTypes.number
};

export default PrenatalAlcoholForm;
