import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import '../../../../assets/stylesheets/pages/report.scss';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Variables from '../components/Variables';
import Blurb5_1A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_1A';
import Blurb5_1B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_1B';
import Blurb5_1C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_1C';
import Blurb5_1D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_1D';
import Blurb5_1E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_1E';
import Blurb5_2A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_2A';
import Blurb5_2B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_2B';
import Blurb5_2C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_2C';
import Blurb5_2D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_2D';
import Blurb5_2E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_2E';
import Blurb5_3A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_3A';
import Blurb5_3B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_3B';
import Blurb5_3C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_3C';
import Blurb5_3D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_3D';
import Blurb5_3E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_3E';
import Blurb5_4A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_4A';
import Blurb5_4B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_4B';
import Blurb5_4C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_4C';
import Blurb5_4D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_4D';
import Blurb5_4E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_4E';
import Blurb5_5A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_5A';
import Blurb5_5B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_5B';
import Blurb5_5C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_5C';
import Blurb5_5E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_5E';
import Blurb5_5D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_5D';
import Blurb5_6A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_6A';
import Blurb5_6B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_6B';
import Blurb5_6C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_6C';
import Blurb5_6D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_6D';
import Blurb5_6E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_6E';
import Blurb5_7A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_7A';
import Blurb5_7B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_7B';
import Blurb5_7C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_7C';
import Blurb5_7D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_7D';
import Blurb5_7E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_7E';
import Blurb5_8A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_8A';
import Blurb5_8B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_8B';
import Blurb5_8C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_8C';
import Blurb5_8D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_8D';
import Blurb5_8E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_8E';
import Blurb5_9 from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_9';
import Blurb5_10 from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_10';
import Blurb5_11 from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_11';
import Blurb5_12A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_12A';
import Blurb5_12B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_12B';
import Blurb5_12C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_12C';
import Blurb5_12D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_12D';
import Blurb5_12E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_12E';
import Blurb5_13A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_13A';
import Blurb5_13B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_13B';
import Blurb5_13D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_13D';
import Blurb5_13E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_13E';
import Blurb5_14A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_14A';
import Blurb5_14B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_14B';
import Blurb5_14C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_14C';
import Blurb5_14D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_14D';
import Blurb5_14E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_14E';
import Blurb5_15A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_15A';
import Blurb5_15B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_15B';
import Blurb5_15C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_15C';
import Blurb5_15D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_15D';
import Blurb5_15E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_15E';
import Blurb5_16C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_16C';
import Blurb5_16B from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_16B';
import Blurb5_16A from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_16A';
import Blurb5_16D from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_16D';
import Blurb5_16E from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_16E';
import Blurb5_13C from '../../../blurbs/5_EducationAndSkillBuilding/Blurb5_13C';

const EducationAndSkillbuilding = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {ref_reason___1, ref_reason___3} = useSelector(state => state.forms[FORMS.REFERRAL_REASON.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const {iq} = useSelector(state => state.forms[FORMS.IQ_SCORE.id]);
  const {intellectual, language} = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);
  const {language_brain, academic_brain} = useSelector(state => state.forms[FORMS.BRAIN_DOMAIN_ASSESSMENT.id]);
  const {associatedfeatures___4} = useSelector(state => state.forms[FORMS.OTHER_FEATURES.id]);
  const {teacherassistant, expulsion} = useSelector(state => state.forms[FORMS.EXPERIENCE.id]);
  const {reassessment, slp, ot, aba} = useSelector(state => state.forms[FORMS.RECOMMENDATIONS.id]);

  const variables = {diagnosis, age, ref_reason___3, ref_reason___1, iq, intellectual, language,
    language_brain, associatedfeatures___4, academic_brain, teacherassistant, expulsion, reassessment, slp, ot, aba};

  return <section>
    <SectionTitle>Education And Skill building </SectionTitle>
    <Variables variableObject={variables}/>
    <Card
      title='Things To Know'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_1A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_1B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_1C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_1D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_1E/>}
      </div>
    </Card>
    <Card
      title='Things To Do Now'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_2A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_2B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_2C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_2D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_2E/>}
      </div>
      <div>
        {(intellectual === '1' || iq === '0') && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_3A/>}
        {(intellectual === '1' || iq === '0') && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_3B/>}
        {(intellectual === '1' || iq === '0') && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_3C/>}
        {(intellectual === '1' || iq === '0') && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_3D/>}
        {(intellectual === '1' || iq === '0') && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_3E/>}
      </div>
      <div>
        {(language_brain === '1' || language === '1') && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_4A/>}
        {(language_brain === '1' || language === '1') && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_4B/>}
        {(language_brain === '1' || language === '1') && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_4C/>}
        {(language_brain === '1' || language === '1') && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_4D/>}
        {(language_brain === '1' || language === '1') && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_4E/>}
      </div>
      <div>
        {associatedfeatures___4 === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_5A/>}
        {associatedfeatures___4 === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_5B/>}
        {associatedfeatures___4 === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_5C/>}
        {associatedfeatures___4 === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_5D/>}
        {associatedfeatures___4 === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_5E/>}
      </div>
      <div>
        {academic_brain === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_6A/>}
        {academic_brain === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_6B/>}
        {academic_brain === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_6C/>}
        {academic_brain === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_6D/>}
        {academic_brain === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_6E/>}
      </div>
      <div>
        {ref_reason___1 === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_7A/>}
        {ref_reason___1 === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_7B/>}
        {ref_reason___1 === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_7C/>}
        {ref_reason___1 === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_7D/>}
        {ref_reason___1 === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_7E/>}
      </div>
      <div>
        {ref_reason___3 === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_8A/>}
        {ref_reason___3 === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_8B/>}
        {ref_reason___3 === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_8C/>}
        {ref_reason___3 === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_8D/>}
        {ref_reason___3 === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_8E/>}
      </div>
      <div>
        {teacherassistant === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_9/>}
        {teacherassistant === '0' && (academic_brain === '1' || intellectual === '1' || iq === '0') &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_10/>}
      </div>
      <div>
        {expulsion === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_11/>}
      </div>
      <div>
        {reassessment === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_12A/>}
        {reassessment === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_12B/>}
        {reassessment === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_12C/>}
        {reassessment === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_12D/>}
        {reassessment === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_12E/>}
      </div>
      <div>
        {slp === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_13A/>}
        {slp === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_13B/>}
        {slp === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_13C/>}
        {slp === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_13D/>}
        {slp === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_13E/>}
      </div>
      <div>
        {ot === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_14A/>}
        {ot === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_14B/>}
        {ot === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_14C/>}
        {ot === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_14D/>}
        {ot === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_14E/>}
      </div>
      <div>
        {aba === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_15A/>}
        {aba === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_15B/>}
        {aba === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_15C/>}
        {aba === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_15D/>}
        {aba === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_15E/>}
      </div>
    </Card>
    <Card
      title='Things To Plan For'>
      {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_16A/>}
      {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_16B/>}
      {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_16C/>}
      {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_16D/>}
      {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb5_16E/>}
    </Card>
  </section>;
};

export default EducationAndSkillbuilding;
