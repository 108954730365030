import React from 'react';

const Blurb5_16C = () =>
  <div>
    <p>
      Education is an important part of your teen’s development that informs goal planning and achievement
      throughout childhood, adolescence, and early adulthood. As your teen continues their education
      journey through high school and prepares to transition to adulthood, it will be important for
      educators to consider your teen’s unique strengths, needs, and goals when developing an individualized
      and supportive education plan for them. Your teen may benefit from adaptations to programming that
      allow for more active participation in “hands on experiences,” time to process information and think
      about content, and opportunities to share their knowledge with others. In this way your teen can remain
      engaged at school and build skills to support their transition to adulthood and beyond.
    </p>
  </div>;

export default Blurb5_16C;
