import React from 'react';
import PropTypes from 'prop-types';

const Variables = ({variableObject}) =>
  <div className={`${process.env.NODE_ENV === 'production' ? 'is-hidden' : ''} grid-x small-up-4 bordered shadow padding-2 margin-bottom-1 hide-for-print`}>
    {variableObject && Object.keys(variableObject).map(item => <div
      className='cell'
      key={item}>
      <span className='font-bold'>{item}: </span>
      &quot;{variableObject[item]}&quot;</div>)}
  </div>;

export default Variables;

Variables.propTypes = {
  variableObject: PropTypes.object.isRequired
};
