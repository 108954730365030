import React from 'react';

const Blurb7_25 = () =>
  <div>
    <p>
      Decision making is a necessary skill across the lifespan. Offering developmentally appropriate
      opportunities for choice with support helps to foster interdependence. The assessment team
      recommended exploring the possibility of guardianship. A guardian is given the legal authority
      and responsibility to make decisions on behalf of another person, such as about their healthcare
      or living arrangements. Some individuals with FASD, can benefit from proper guardianships with
      caregivers and trusted others to support their decision-making abilities. Choosing a trusted
      person who is FASD-informed is important in ensuring appropriate planning for future decisions.
    </p>
  </div>;

export default Blurb7_25;
