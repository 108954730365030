import React from 'react';

const Blurb3_4D = () =>
  <div>
    <p>
      During the assessment there was indication that you may have experienced trauma. Exposure to traumatic
      experiences can influence your attachment to others, including how you perceive yourself and others,
      and your relationships with caregivers and other people in your life. Exploring mental health supports
      to foster healthy coping skills and support attachment relationships, family cohesion, and overall
      well-being, may be helpful.
    </p>
  </div>;

export default Blurb3_4D;
