import React from 'react';

const Blurb5_3E = () =>
  <div>
    <p>
      Intellectual functioning has been assessed and identified as an area of need. This means that compared
      with others your/your child’s age, how you learn, reason, use language, and solve problems may be different,
      and this may in turn impact experiences of success in learning. To support academic achievement ongoing
      intervention, modified programming, appropriate accommodations and strategies, and ongoing monitoring of
      development will be important.
    </p>
  </div>;

export default Blurb5_3E;
