import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const PalpebralFissureLengthForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.PALPEBRAL_FISSURE_LENGTH.id]);
  const isFormComplete = formData.fissurelength !== '';
  const dispatch = useDispatch();
  const palpebralFissureLengthOptions = FORMS.PALPEBRAL_FISSURE_LENGTH.fields.fissurelength.options;

  const handleFormChange = updatedFormData => {
    dispatch(submitForm(FORMS.PALPEBRAL_FISSURE_LENGTH.id, updatedFormData));
  };

  return (
    <>
      <h2 className='form-header'>{FORMS.PALPEBRAL_FISSURE_LENGTH.header}</h2>
      <div className='form-content'>
        <form>
          <div className='grid-x grid-margin-x'>
            <div className='small-12 medium-4 large-3 cell'>
              <label className='form-field'>{FORMS.PALPEBRAL_FISSURE_LENGTH.fields.fissurelength.title}</label>
            </div>
            <div className='medium-8 large-9 cell'>
              <div className='form-field-value-rounded-borders grid-x small-up-1 medium-up-3'>
                {Object.keys(palpebralFissureLengthOptions).map(optionKey =>
                  <RadioButton
                    checked={formData.fissurelength === optionKey}
                    className='cell'
                    key={optionKey}
                    label={palpebralFissureLengthOptions[optionKey]}
                    onChange={event => handleFormChange({...formData, fissurelength: event.target.value})}
                    value={optionKey}/>)}
              </div>
            </div>
          </div>
        </form>
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

PalpebralFissureLengthForm.propTypes = {
  formIndex: PropTypes.number
};

export default PalpebralFissureLengthForm;
