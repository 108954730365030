import React from 'react';
import CheckBox from '../../../components/CheckBox';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const EthnicityForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.ETHNICITY.id]);
  const dispatch = useDispatch();
  const ethnicities = FORMS.ETHNICITY.fields;
  const fields = Object.keys(formData);
  const checkedFields = fields.filter(field => formData[field] === true);
  const isFormComplete = checkedFields.length > 0 &&
    (formData.ethnicity___12 ? formData.ethnicity_specify.length > 0 : true);

  const handleFormChange = updatedFormData => {
    if (!updatedFormData.ethnicity___12) {
      updatedFormData[ethnicities.ethnicity___12.specify_other.id] = ethnicities.ethnicity___12.specify_other.value;
    }

    dispatch(submitForm(FORMS.ETHNICITY.id, updatedFormData));
  };

  return (
    <>
      <h2 className='form-header'>{FORMS.ETHNICITY.header}</h2>
      <div className='form-content'>
        <form>
          <div className='grid-x grid-margin-x'>
            <div className='small-12 medium-4 large-3 cell'>
              <label className='form-field'>Which ethnic group(s) does this person most identify with?</label>
            </div>
            <div className='cell medium-8 large-9'>
              <div className='form-field-value-rounded-borders padding-bottom-1'>
                {Object.entries(ethnicities).map(([ethnicityKey, ethnicityValues]) =>
                  <CheckBox
                    checked={formData[ethnicityKey]}
                    key={ethnicityKey}
                    onChange={() => handleFormChange({...formData, [ethnicityKey]: !formData[ethnicityKey]})}
                    value={ethnicityValues.title}/>)}
                {
                  formData.ethnicity___12 && <>
                    <label
                      htmlFor={ethnicities.ethnicity___12.specify_other.id}>{ethnicities.ethnicity___12.specify_other.title}</label>
                    <input
                      id={ethnicities.ethnicity___12.specify_other.id}
                      onChange={event => handleFormChange({...formData, [ethnicities.ethnicity___12.specify_other.id]: event.target.value})}
                      type='text'
                      value={formData[ethnicities.ethnicity___12.specify_other.id]}/>
                  </>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

EthnicityForm.propTypes = {
  formIndex: PropTypes.number
};

export default EthnicityForm;
