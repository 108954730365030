import React from 'react';
import RadioButton from '../../../components/RadioButton';
import {FormNav} from '../../../components/FormNav';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import PropTypes from 'prop-types';

const SentinelFeaturesForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.SENTINEL_FEATURES.id]);
  const isFormComplete = formData.sentinel !== '';
  const dispatch = useDispatch();

  const handleFormChange = updatedFormData => {
    dispatch(submitForm(FORMS.SENTINEL_FEATURES.id, updatedFormData));
  };

  const totalSentinelFacialFeaturesOptions = FORMS.SENTINEL_FEATURES.fields.sentinel.options;

  return (

    <>
      <h2 className='form-header'>{FORMS.SENTINEL_FEATURES.header}</h2>
      <div className='form-content'>
        <form>
          <div className='grid-x grid-margin-x'>
            <div className='small-12 medium-4 large-3 cell'>
              <label className='form-field'>{FORMS.SENTINEL_FEATURES.fields.sentinel.title}</label>
            </div>
            <div className='cell medium-8 large-9'>
              <div className='form-field-value-rounded-borders grid-x small-up-2 medium-up-3 large-up-5'>
                {Object.keys(totalSentinelFacialFeaturesOptions).map(optionKey =>
                  <RadioButton
                    checked={formData.sentinel === optionKey}
                    className='cell'
                    key={optionKey}
                    label={totalSentinelFacialFeaturesOptions[optionKey]}
                    onChange={event => handleFormChange({...formData, sentinel: event.target.value})}
                    value={optionKey}/>)}
              </div>
            </div>
          </div>
        </form>
      </div>
      <FormNav
        canSubmit={isFormComplete}
        formIndex={formIndex}/>
    </>
  );
};

SentinelFeaturesForm.propTypes = {
  formIndex: PropTypes.number
};

export default SentinelFeaturesForm;
