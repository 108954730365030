import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import '../../../../assets/stylesheets/pages/report.scss';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Variables from '../components/Variables';
import Blurb8_1A from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_1A';
import Blurb8_1B from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_1B';
import Blurb8_1C from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_1C';
import Blurb8_1D from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_1D';
import Blurb8_1E from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_1E';
import Blurb8_2A from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_2A';
import Blurb8_2B from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_2B';
import Blurb8_2C from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_2C';
import Blurb8_2D from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_2D';
import Blurb8_2E from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_2E';
import Blurb8_3A from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_3A';
import Blurb8_3B from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_3B';
import Blurb8_3C from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_3C';
import Blurb8_3D from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_3D';
import Blurb8_3E from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_3E';
import Blurb8_4A from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_4A';
import Blurb8_4B from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_4B';
import Blurb8_4C from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_4C';
import Blurb8_4D from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_4D';
import Blurb8_4E from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_4E';
import Blurb8_5A from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_5A';
import Blurb8_5B from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_5B';
import Blurb8_5C from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_5C';
import Blurb8_5D from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_5D';
import Blurb8_5E from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_5E';
import Blurb8_7A from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_7A';
import Blurb8_7B from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_7B';
import Blurb8_7C from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_7C';
import Blurb8_7D from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_7D';
import Blurb8_7E from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_7E';
import Blurb8_8A from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_8A';
import Blurb8_8B from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_8B';
import Blurb8_8C from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_8C';
import Blurb8_8D from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_8D';
import Blurb8_8E from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_8E';
import Blurb8_9A from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_9A';
import Blurb8_9B from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_9B';
import Blurb8_9C from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_9C';
import Blurb8_9D from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_9D';
import Blurb8_9E from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_9E';
import Blurb8_10 from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_10';
import Blurb8_11 from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_11';
import Blurb8_12 from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_12';
import Blurb8_13A from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_13A';
import Blurb8_13B from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_13B';
import Blurb8_13C from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_13C';
import Blurb8_13D from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_13D';
import Blurb8_13E from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_13E';
import Blurb8_6A from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_6A';
import Blurb8_6B from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_6B';
import Blurb8_6C from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_6C';
import Blurb8_6D from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_6D';
import Blurb8_6E from '../../../blurbs/8_MentalHealthAndRegulation/Blurb8_6E';

const MentalHealthAndRegulation = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {factors___0, factors___2} = useSelector(state => state.forms[FORMS.OTHER_FACTORS.id]);
  const {alcohol_treat, tobacco_treat, marijuana_treat, othersubstances_treat} = useSelector(state => state.forms[FORMS.TREATMENT_ACCESS.id]);
  const {alcohol_misuse, tobacco_misuse, marijuana_misuse, opiates_misuse, crack_misuse, other_misuses} = useSelector(state => state.forms[FORMS.SUBSTANCE.id]);
  const {add, suicide, attachment, odd, ptsd, conduct, personality, schizophrenia, anxiety, mood, bipolar, autism} = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);
  const {mentalhealth, individualtherapy, supportgroup, abusecounselling} = useSelector(state => state.forms[FORMS.RECOMMENDATIONS.id]);
  const {attention_brain, executive_brain, regulation_brain} = useSelector(state => state.forms[FORMS.BRAIN_DOMAIN_ASSESSMENT.id]);
  const {ref_reason___7} = useSelector(state => state.forms[FORMS.REFERRAL_REASON.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const variables = {diagnosis, age, factors___0, factors___2, alcohol_treat, tobacco_treat, marijuana_treat,
    othersubstances_treat, alcohol_misuse, tobacco_misuse, marijuana_misuse, opiates_misuse, crack_misuse, other_misuses, add,
    suicide, attachment, odd, ptsd, conduct, personality, schizophrenia, anxiety, mentalhealth, mood, bipolar,
    individualtherapy, supportgroup, abusecounselling, attention_brain, executive_brain, regulation_brain, ref_reason___7};

  return <section>
    <SectionTitle>Mental Health And Regulation</SectionTitle>
    <Variables variableObject={variables}/>
    <Card
      title='Things To Know'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_1A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_1B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_1C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_1D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_1E/>}
      </div>
    </Card>
    <Card
      title='Things To Do Now'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_2A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_2B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_2C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_2D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_2E/>}
      </div>
      <div>
        {(ref_reason___7 === '1' || attention_brain === '1' || executive_brain === '1' || regulation_brain === '1') &&
          age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_3A/>}
        {(ref_reason___7 === '1' || attention_brain === '1' || executive_brain === '1' || regulation_brain === '1') &&
          age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_3B/>}
        {(ref_reason___7 === '1' || attention_brain === '1' || executive_brain === '1' || regulation_brain === '1') &&
          age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_3C/>}
        {(ref_reason___7 === '1' || attention_brain === '1' || executive_brain === '1' || regulation_brain === '1') &&
          age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_3D/>}
        {(ref_reason___7 === '1' || attention_brain === '1' || executive_brain === '1' || regulation_brain === '1') &&
          age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_3E/>}
      </div>
      <div>
        {(mentalhealth === '1' || individualtherapy === '1' || supportgroup === '1') &&
          age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_4A/>}
        {(mentalhealth === '1' || individualtherapy === '1' || supportgroup === '1') &&
          age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_4B/>}
        {(mentalhealth === '1' || individualtherapy === '1' || supportgroup === '1') &&
          age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_4C/>}
        {(mentalhealth === '1' || individualtherapy === '1' || supportgroup === '1') &&
          age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_4D/>}
        {(mentalhealth === '1' || individualtherapy === '1' || supportgroup === '1') &&
          age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_4E/>}
      </div>
      <div>
        {(ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' ||
            anxiety === '1' || mood === '1' || bipolar === '1' || odd === '1' || add === '1') && age === 1 &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_5A/>}
        {(ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' ||
            anxiety === '1' || mood === '1' || bipolar === '1' || odd === '1' || add === '1') && age === 1 &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_5B/>}
        {(ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' ||
            anxiety === '1' || mood === '1' || bipolar === '1' || odd === '1' || add === '1') && age === 1 &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_5C/>}
        {(ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' ||
            anxiety === '1' || mood === '1' || bipolar === '1' || odd === '1' || add === '1') && age === 1 &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_5D/>}
        {(ptsd === '1' || conduct === '1' || personality === '1' || schizophrenia === '1' ||
            anxiety === '1' || mood === '1' || bipolar === '1' || odd === '1' || add === '1') && age === 1 &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_5E/>}
      </div>
      <div>
        {autism === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_6A/>}
        {autism === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_6B/>}
        {autism === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_6C/>}
        {autism === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_6D/>}
        {autism === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_6E/>}
      </div>
      <div>
        {add === '1' && age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_7A/>}
        {add === '1' && age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_7B/>}
        {add === '1' && age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_7C/>}
        {add === '1' && age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_7D/>}
        {add === '1' && age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_7E/>}
      </div>
      <div>
        {(ptsd === '1' || factors___2 === '1' || attachment === '1' || factors___0 === '1') &&
          age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_8A/>}
        {(ptsd === '1' || factors___2 === '1' || attachment === '1' || factors___0 === '1') &&
          age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_8B/>}
        {(ptsd === '1' || factors___2 === '1' || attachment === '1' || factors___0 === '1') &&
          age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_8C/>}
        {(ptsd === '1' || factors___2 === '1' || attachment === '1' || factors___0 === '1') &&
          age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_8D/>}
        {(ptsd === '1' || factors___2 === '1' || attachment === '1' || factors___0 === '1') &&
          age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_8E/>}
      </div>
      <div>
        {suicide === '1' || age === '1' || (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_9A/>}
        {suicide === '1' || age === '2' || (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_9B/>}
        {suicide === '1' || age === '3' || (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_9C/>}
        {suicide === '1' || age === '4' || (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_9D/>}
        {suicide === '1' || age === '5' || (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_9E/>}
      </div>
      <div>
        {(alcohol_misuse === '1' || tobacco_misuse === '1' || marijuana_misuse === '1' || opiates_misuse === '1' ||
          crack_misuse === '1' || other_misuses === '1') &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_10/>}
        {(alcohol_treat === '1' || tobacco_treat === '1' || marijuana_treat === '1' || othersubstances_treat === '1') &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_11/>}
        {abusecounselling === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_12/>}
      </div>
    </Card>
    <Card
      title='Things To Plan For'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_13A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_13B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_13C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_13D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb8_13E/>}
      </div>
    </Card>
  </section>;
};

export default MentalHealthAndRegulation;
