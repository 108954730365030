import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import {submitForm} from '../../../../redux/actions/forms';
import {FormNav} from '../../../components/FormNav';
import PropTypes from 'prop-types';

const OtherCurrentMedicationForm = ({formIndex}) => {
  const formData = useSelector(state => state.forms[FORMS.OTHER_CURRENT_MEDICATION.id]);
  const dispatch = useDispatch();
  const otherCurrentMedications = FORMS.OTHER_CURRENT_MEDICATION.fields;

  const handleFormChange = (updatedFormData, id, index) => {
    if (updatedFormData[otherCurrentMedications[id].precedingIds[index]] === '') {
      for (let i = index + 1; i < otherCurrentMedications[id].precedingIds.length; i++) {
        updatedFormData[otherCurrentMedications[id].precedingIds[i]] = '';
      }
    }

    dispatch(submitForm(FORMS.OTHER_CURRENT_MEDICATION.id, updatedFormData));
  };

  const isFieldDisplayed = (idList, index) => {
    for (let i = 0; i < index; i++) {
      if (!formData[idList[i]] || formData[idList[i]].length <= 0) {
        return false;
      }
    }

    return true;
  };

  return (
    <>
      <h2 className='form-header'>{FORMS.OTHER_CURRENT_MEDICATION.header}</h2>
      <div className='form-content'>
        <form className='padding-bottom-1'>
          <label className='form-field padding-bottom-2 note'>Note: Please list all other current medications</label>
          {
            Object.entries(otherCurrentMedications).map(([medicationKey, medicationValues]) =>
              medicationValues.precedingIds && medicationValues.precedingIds.map((id, index) => {
                if (isFieldDisplayed(medicationValues.precedingIds, index)) {
                  return <div
                    className='grid-x grid-margin-x margin-bottom-1 align-middle'
                    key={id}>
                    <div className='small-12 medium-4 large-3 cell'>
                      <label
                        className='form-field'
                        htmlFor={id}>{medicationValues.title}{index + 1}:</label>
                    </div>
                    <div className='cell medium-8 large-9'>
                      <input
                        className='margin-bottom-0'
                        id={id}
                        onChange={event => handleFormChange({...formData, [id]: event.target.value}, medicationKey, index)}
                        placeholder={medicationValues.precedingIds[index]}
                        type='text'
                        value={formData[id]}/>
                    </div>
                  </div>;
                }

                return null;
              }))
          }
        </form>
      </div>
      <FormNav
        canSubmit
        formIndex={formIndex}/>
    </>
  );
};

OtherCurrentMedicationForm.propTypes = {
  formIndex: PropTypes.number
};

export default OtherCurrentMedicationForm;
