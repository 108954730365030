import React from 'react';

const Blurb4_1A = () =>
  <div>
    <p>
      Social interactions are an integral part of our daily functioning. Our social skills can influence our
      success in school and work, the number and types of friends we have, and the way we see ourselves. We
      often imagine that social functioning develops ‘naturally,’ particularly if we have experienced success
      in this area ourselves or as caregivers. Yet the development of social skills is complex, with many
      more moving parts than we may realize! Success requires the application of cognitive, behavioral, and
      emotional skills and knowledge, and is further impacted by environmental factors. This can result in
      a broad spectrum of potential social strengths and needs that are often woven together in unique ways
      for each person.
    </p>
    <p>
      It is important to support the development of early social skills because they can contribute to
      successful communication, interpersonal functioning, and adaptive behaviors throughout the lifespan.
      This means that your child’s skills in these domains can be used to support their functioning in
      other, related domains.
    </p>
  </div>;

export default Blurb4_1A;
