import React from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import {useSelector} from 'react-redux';
import FORMS from '../../redux/constants';

export default function App() {
  const formData = useSelector(state => state.forms[FORMS.TEAROFFS.id]);
  const data = [
    {
      name: 'Verbal and language',
      value: formData.verbal_and_language
    },
    {
      name: 'Visual',
      value: formData.visual
    },
    {
      name: 'Attentional control',
      value: formData.attentional_control
    },
    {
      name: 'Processing speed',
      value: formData.processing_speed
    },
    {
      name: 'Adaptive skills',
      value: formData.adaptive_skills
    },
    {
      name: 'Relational skills',
      value: formData.relational_skills
    }
  ];
  return (
    <div className='margin-2'>
      <ResponsiveContainer
        height={400}
        width='100%'>
        <ComposedChart data={data}>
          <CartesianGrid
            stroke='#ececec'
            vertical={false}/>
          <XAxis dataKey='name'/>
          <YAxis
            domain={[0, 6]}
            ticks={[1, 2, 3, 4, 5]}
            width={15}
          />
          <Bar
            barSize={50}
            dataKey='value'
            fill='#646bd9'/>
          <Line
            dataKey='value'
            dot={{stroke: '#ff7309', strokeWidth: 18}}
            stroke='#ff7300'
            strokeWidth={3}
            type='linear'/>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
