import React from 'react';

const Blurb2_6E = () =>
  <div>
    <p>
      Motor difficulties were reported as an area of challenge. Motor skills are required for our daily
      activities. Gross motor skills help us to walk, play sports, and exercise, while fine motor skills
      help us button our shirts, draw, or use a tablet. Difficulties with motor skills can impact an
      individual’s ability to complete common everyday tasks, like writing their name or playing a sport.
      Motor skills can vary greatly from one person to another. Some tasks may come easily, like running,
      while others may be difficult, like tying shoes.
    </p>
    <p>
      Awareness of your/your child’s motor needs can help others they work with to identify changes
      environments, like home, school, and work, that can reduce stress and increase your/your child’s
      ability to access their strengths. Motor functioning can be hard to predict sometimes,
      thus observation can help us to understand and then provide appropriate support. With targeted
      intervention from clinicians, such as occupational therapists and physical therapists, motor
      development and functioning can be supported, and improvement is possible.
    </p>
  </div>;

export default Blurb2_6E;
