import React from 'react';

const Blurb2_1C = () =>
  <div>
    <p>
      Physical health is fundamental to daily living. Awareness and support that promotes physical health
      is a first step towards healthy outcomes across the lifespan.
    </p>
    <p>
      Adolescence is an important period for physical and cognitive development. Ongoing monitoring of your
      teen’s physical health, including their hearing, vision, mobility, and diet, is important to their
      overall wellbeing, as well as unique considerations around puberty during this developmental period.
      Adolescents with FASD may experience more physical health needs at earlier ages than might typically
      be expected – this means that continuing to look out for even unexpected needs is important. As your
      adolescent continues to grow and develop, it will also be important they be provided with developmentally
      appropriate information on sexual health education, pregnancy, and healthy relationships.
    </p>
  </div>;

export default Blurb2_1C;
