import React from 'react';

const Blurb8_1E = () =>
  <div>
    <p>
      Mental health is fundamental to daily living. Mental health refers to emotional and psychological
      well-being, as well as emotion regulation. Researchers have reported that individuals with FASD are
      more likely to experience mental health needs than might typically be expected - this means that
      ongoing monitoring of mental health is important.
    </p>
  </div>;

export default Blurb8_1E;
