import React from 'react';
import SectionTitle from '../components/SectionTitle';
import '../../../../assets/stylesheets/pages/report.scss';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Variables from '../components/Variables';
import Blurb2_1A from '../../../blurbs/2_Health/Blurb2_1A';
import Blurb2_1B from '../../../blurbs/2_Health/Blurb2_1B';
import Blurb2_1C from '../../../blurbs/2_Health/Blurb2_1C';
import Blurb2_1D from '../../../blurbs/2_Health/Blurb2_1D';
import Blurb2_1E from '../../../blurbs/2_Health/Blurb2_1E';
import Blurb2_2A from '../../../blurbs/2_Health/Blurb2_2A';
import Blurb2_2B from '../../../blurbs/2_Health/Blurb2_2B';
import Blurb2_2C from '../../../blurbs/2_Health/Blurb2_2C';
import Blurb2_2D from '../../../blurbs/2_Health/Blurb2_2D';
import Blurb2_2E from '../../../blurbs/2_Health/Blurb2_2E';
import Blurb2_3 from '../../../blurbs/2_Health/Blurb2_3';
import Blurb2_4 from '../../../blurbs/2_Health/Blurb2_4';
import Blurb2_5A from '../../../blurbs/2_Health/Blurb2_5A';
import Blurb2_5B from '../../../blurbs/2_Health/Blurb2_5B';
import Blurb2_5C from '../../../blurbs/2_Health/Blurb2_5C';
import Blurb2_5D from '../../../blurbs/2_Health/Blurb2_5D';
import Blurb2_5E from '../../../blurbs/2_Health/Blurb2_5E';
import Blurb2_6A from '../../../blurbs/2_Health/Blurb2_6A';
import Blurb2_6B from '../../../blurbs/2_Health/Blurb2_6B';
import Blurb2_6C from '../../../blurbs/2_Health/Blurb2_6C';
import Blurb2_6D from '../../../blurbs/2_Health/Blurb2_6D';
import Blurb2_6E from '../../../blurbs/2_Health/Blurb2_6E';
import Blurb2_7A from '../../../blurbs/2_Health/Blurb2_7A';
import Blurb2_7B from '../../../blurbs/2_Health/Blurb2_7B';
import Blurb2_7C from '../../../blurbs/2_Health/Blurb2_7C';
import Blurb2_7D from '../../../blurbs/2_Health/Blurb2_7D';
import Blurb2_7E from '../../../blurbs/2_Health/Blurb2_7E';
import Blurb2_9 from '../../../blurbs/2_Health/Blurb2_9';
import Blurb2_11 from '../../../blurbs/2_Health/Blurb2_11';
import Blurb2_10 from '../../../blurbs/2_Health/Blurb2_10';
import Blurb2_12A from '../../../blurbs/2_Health/Blurb2_12A';
import Blurb2_12B from '../../../blurbs/2_Health/Blurb2_12B';
import Blurb2_12C from '../../../blurbs/2_Health/Blurb2_12C';
import Blurb2_12D from '../../../blurbs/2_Health/Blurb2_12D';
import Blurb2_12E from '../../../blurbs/2_Health/Blurb2_12E';
import Card from '../components/Card';

const Health = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {meds_rec, medicalreferral} = useSelector(state => state.forms[FORMS.RECOMMENDATIONS.id]);
  const {motorskills_brain} = useSelector(state => state.forms[FORMS.BRAIN_DOMAIN_ASSESSMENT.id]);
  const {dcd, congenital, auditory, visual} = useSelector(state => state.forms[FORMS.OTHER_DIAGNOSIS.id]);
  const {associatedfeatures___0, associatedfeatures___1, associatedfeatures___2} = useSelector(state => state.forms[FORMS.OTHER_FEATURES.id]);
  const {stimulant1, antidepressant1, antipsycho1, birthcontrol1, hrt1, antihypertensive1, anticonvulsant1} = useSelector(state => state.forms[FORMS.OTHER_CURRENT_MEDICATION.id]);
  const {omega3, choline, glutamine, aripiprazole, vortioxetine, minocycline, bupropion, buspirone, clozapine, melatonin} = useSelector(state => state.forms[FORMS.MEDICATIONS.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);

  const variables = {diagnosis, meds_rec, motorskills_brain, dcd, congenital, auditory, visual,
    associatedfeatures___0, associatedfeatures___1, associatedfeatures___2, stimulant1, antidepressant1,
    antipsycho1, birthcontrol1, hrt1, antihypertensive1, anticonvulsant1, omega3, choline,
    glutamine, aripiprazole, vortioxetine, minocycline, bupropion, buspirone, clozapine, melatonin, age};

  return <section>
    <SectionTitle>Physical Wellbeing</SectionTitle>
    <Variables variableObject={variables}/>
    <Card title='Things to Know'>
      {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_1A/>}
      {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_1B/>}
      {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_1C/>}
      {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_1D/>}
      {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_1E/>}
    </Card>

    <Card title='Things to Do Now'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_2A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_2B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_2C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_2D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_2E/>}
      </div>
      <div>
        {
          (meds_rec === '1' || omega3 === '1' || choline === '1' || glutamine === '1' || aripiprazole === '1' ||
          vortioxetine === '1' || minocycline === '1' || bupropion === '1' || buspirone === '1' || clozapine === '1' ||
          melatonin === '1' || stimulant1 !== '' || antidepressant1 !== '' || antipsycho1 !== '' ||
          birthcontrol1 !== '' || hrt1 !== '' || antihypertensive1 !== '' || anticonvulsant1 !== '') &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_3/>
        }
        {
          medicalreferral === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_4/>
        }
      </div>
      <div>
        {
          (associatedfeatures___1 === '1' || associatedfeatures___2 === '1') && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_5A/>
        }
        {
          (associatedfeatures___1 === '1' || associatedfeatures___2 === '1') && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_5B/>
        }
        {
          (associatedfeatures___1 === '1' || associatedfeatures___2 === '1') && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_5C/>
        }
        {
          (associatedfeatures___1 === '1' || associatedfeatures___2 === '1') && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_5D/>
        }
        {
          (associatedfeatures___1 === '1' || associatedfeatures___2 === '1') && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_5E/>
        }
      </div>
      <div>
        {
          motorskills_brain === '1' || dcd === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_6A/>
        }
        {
          motorskills_brain === '1' || dcd === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_6B/>
        }
        {
          motorskills_brain === '1' || dcd === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_6C/>
        }
        {
          motorskills_brain === '1' || dcd === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_6D/>
        }
        {
          motorskills_brain === '1' || dcd === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_6E/>
        }
      </div>
      <div>
        {
          associatedfeatures___0 === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_7A/>
        }
        {
          associatedfeatures___0 === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_7B/>
        }
        {
          associatedfeatures___0 === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_7C/>
        }
        {
          associatedfeatures___0 === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_7D/>
        }
        {
          associatedfeatures___0 === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') &&
          <Blurb2_7E/>
        }
      </div>
      <div>
        {congenital === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_9/>}
        {auditory === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_10/>}
        {visual === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_11/>}
      </div>
    </Card>

    <Card title='Things to Plan for'>
      {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_12A/>}
      {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_12B/>}
      {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_12C/>}
      {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_12D/>}
      {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb2_12E/>}
    </Card>
  </section>;
};

export default Health;
