import React from 'react';

const SlidingScale = () =>
  <table>
    <thead>
      <tr>
        <th width='200'>Sliding Scale Value</th>
        <th width='200'>Descriptor Ranges</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>5</td>
        <td>High Average and above</td>
      </tr>
      <tr>
        <td>4</td>
        <td>Average</td>
      </tr>
      <tr>
        <td>3</td>
        <td>Low Average to Average</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Borderline to Low Average</td>
      </tr>
      <tr>
        <td>1</td>
        <td>Extremely Low</td>
      </tr>
    </tbody>
  </table>;

export default SlidingScale;
