import React from 'react';

const Blurb7_252 = () =>
  <div>
    <p>
      Decision making is a necessary skill across the lifespan. Offering developmentally appropriate
      opportunities for choice with support helps to foster interdependence. The assessment team recommended
      exploring the possibility of additional guidance from caregivers and/or trusted others in supporting
      your/your child’s decision-making abilities. Choosing a trusted person who is FASD-informed is important
      in ensuring appropriate planning for future decisions.
    </p>
  </div>;

export default Blurb7_252;
