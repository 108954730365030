import React from 'react';

const Blurb3_2C = () =>
  <div>
    <p>
      Stable and consistent interactions that foster positive attachment with caregivers, peers, teachers,
      and supportive others are important for developing healthy relationships. Here are some resources for
      caregivers of individuals with FASD: <a href='https://canfasd.ca/wp-content/uploads/2018/03/Caregiver-Resource-Guide-FASD-March-2018.pdf'>
        https://canfasd.ca/wp-content/uploads/2018/03/Caregiver-Resource-Guide-FASD-March-2018.pdf
      </a>
    </p>
  </div>;

export default Blurb3_2C;
