import React from 'react';

const Blurb1_5B = () =>
  <div>
    <p>
      Early and tailored intervention is important to support your child’s daily needs and behaviours.
    </p>
    <p>
      Based on the assessment results, your child did not meet diagnostic criteria for Fetal Alcohol Spectrum
      Disorder (FASD) at this time.  It will be important to continue to monitor your child’s development,
      behaviours and daily functioning to support healthy outcomes as they transition to junior high, high
      school, and beyond.
    </p>
  </div>;

export default Blurb1_5B;
