import React from 'react';
import App from './App';
import configureStore from './redux/store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import {Provider as RollbarProvider, ErrorBoundary} from '@rollbar/react';
import './assets/stylesheets/application.scss';
import {HelmetProvider} from 'react-helmet-async';
import {createRoot} from 'react-dom/client';
import {config} from './config';
import EnvironmentRibbon from './app/components/EnvironmentRibbon';

const {store, persistor} = configureStore();

const rollbarConfig = {
  enabled: config.app.environment !== 'development',
  accessToken: config.rollbar.accessToken,
  captureUncaught: true,
  captureIp: false,
  captureUnhandledRejections: true,
  payload: {
    environment: config.app.environment,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: config.app.version,
        guess_uncaught_frames: true
      }
    }
  }
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<React.StrictMode>
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
        >
          <HelmetProvider>
            <App/>
            {config.app.environment !== 'production' && <EnvironmentRibbon/>}
          </HelmetProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>
</React.StrictMode>);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
