import React from 'react';

const Blurb5_4D = () =>
  <div>
    <p>
      Your language and communication skills have been assessed and identified as an area of need. This means
      that you may struggle to express yourself effectively and/or may have difficulty consistently understanding
      others – especially when verbal exchanges are fast paced, which is typical within social interactions and
      school-based or training and work environments. Sometimes individuals with FASD can effectively mask these
      challenges by repeating familiar language, controlling conversations, and simply agreeing with others and
      following along with peers – particularly as an adult. You may also sometimes feel overwhelmed by verbal
      communication and quickly dysregulate, become angry, or avoid others.
    </p>
    <p>
      It will be important for you to work with those professionals you encounter in your school-based training
      or work environments. Advocating for your communication needs will help others to understand how to best
      work with you to make sure your needs and learning goals are understood. Trying out different communication
      strategies can be helpful to find what works for you. For example, for some adults, receiving short concrete
      texts are easier to understand than having a long phone conversation where information may be missed.
    </p>
  </div>;

export default Blurb5_4D;
