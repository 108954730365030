import React from 'react';

const Blurb8_10 = () =>
  <div>
    <p>
      The assessment team noted possible concerns related to substance use. Individuals with FASD are at a
      higher risk for difficulties related to substance use and may use substances for many different reasons.
      For some people, substance use can lead to problems with physical and mental health, as well as difficulties
      in daily living. Supporting healthy connections and proactive mental health interventions can sometimes help
      to prevent difficulties with substance misuse. When difficulties arise, people with FASD can be supported by
      having open conversations with key support people in their lives, safety planning to reduce risk further
      serious problems, and helping them to access FASD-informed substance use treatment services, which may
      include support treating withdrawal symptoms, progressing through treatment, and continuing care afterwards.
      Evidence-based practices to support people with FASD in treatment for substance use can be found in this CanFASD
      guide: <a href='https://canfasd.ca/wp-content/uploads/publications/Moving-Towards-FASD-Informed-Care-in-Substance-Use-Treatment.pdf'>
      https://canfasd.ca/wp-content/uploads/publications/Moving-Towards-FASD-Informed-Care-in-Substance-Use-Treatment.pdf</a>
    </p>
  </div>;

export default Blurb8_10;
