import React from 'react';

const Blurb5_4E = () =>
  <div>
    <p>
      Language and communication skills have been assessed and identified as an area of need. This means that they
      may struggle to express themselves effectively and/or may have difficulty consistently understanding others
      – especially when verbal exchanges are fast paced, which is typical within social interactions and classroom
      environments. Sometimes individuals with FASD can effectively mask these challenges by repeating familiar
      language, controlling conversations, and simply agreeing with others and following along with peers –
      particularly as they get older. They may also simply feel overwhelmed by verbal communication and
      quickly dysregulate, become angry, or avoid others.
    </p>
    <p>
      Ensuring supports are aware of language differences may create space for acceptance and support. as well
      as identification of alternative communication and learning strategies.
    </p>
  </div>;

export default Blurb5_4E;
