import React from 'react';

const Blurb14_7B = () =>
  <div>
    <p>
      Some individuals with FASD share similarities in the areas where further supports would be helpful.
      Findings from your client’s assessment suggest that these needs are characterized by difficulties
      across most areas of neurodevelopmental functioning. This includes their overall ability to think,
      reason, learn, and solve problems using cognitive skills, including attention, memory, language and
      executive functioning. Difficulties with academic skills and adaptive/social functioning were
      also identified. Taken together, individuals who share this pattern may struggle with higher
      level thinking, communicating clearly, remembering and learning new information, paying attention,
      and making decisions. These difficulties can also lead to challenges related to education and training,
      peer interactions, and accomplishing goals.
    </p>
    <p>
      Many people with FASD also experience quite a lot of scatter in their skills. This means that although
      a high level of needs has been identified, they may experience times of success during specific tasks.
      However, with slight changes to task expectations this may rapidly shift to failure. Such sudden and
      seemingly inexplicable changes can be confusing to all and can lead to questions regarding effort or
      motivation. Instead, we are increasingly understanding that the unique impact of prenatal alcohol exposure
      results in an uneven skill set – and therefore sometimes it will be hard to set appropriate expectations.
      When we recognize the brain-based nature of these broad set of difficulties we can adapt supports and
      meaningfully tailor interventions to accommodate these needs. The first step is understanding, so that
      support and acceptance is experienced from others. We will need to be flexible and responsive.
    </p>
    <p>
      People with FASD also commonly share important strengths. Some examples may include perseverance, willingness
      to accept help, and a desire for connection to others. Such strengths can create opportunities for support.
      They can help identify opportunities for making meaningful and purposeful contributions to family and community,
      either by highlighting existing contributions or by uncovering new possibilities.
    </p>
  </div>;

export default Blurb14_7B;
