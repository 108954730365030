import React from 'react';

const Blurb10_4C = () =>
  <div>
    <p>
      When it is time for your teen to find and keep safe and affordable housing, it will be important for your teen,
      their family/caregiver(s), and service providers to work together. This collaboration helps identify and
      prioritize their unique housing needs, preferences, and goals. Also consider your teen’s unique strengths
      and needs, life experiences, and social supports, when planning for safe, secure, and supportive housing.
    </p>
  </div>;

export default Blurb10_4C;
