import React from 'react';

const Blurb6_2C = () =>
  <div>
    <p>
      To help foster your teen’s identity development, provide them with opportunities to explore their
      unique interests, beliefs, and values. Conversations, modeling, and relationships with peers and
      mentors can all be important facets of identity exploration in adolescence that can lead to greater
      self-awareness, insight, and understanding. A shared understanding of your teen’s unique identity
      among supports can ensure they are well informed and equipped to address challenges and build on
      strengths as they continue to grow and develop throughout adolescence and into adulthood.
    </p>
  </div>;

export default Blurb6_2C;
