import React from 'react';

const Blurb8_8E = () =>
  <div>
    <p>
      Trauma occurs when an individual experiences a distressing or disturbing event. Traumatic events or
      experiences can exist in many forms, including physical, emotional, or psychological and can include
      an actual or perceived threat to a person’s safety and wellbeing. Trauma can also include intergenerational
      experiences that are passed down through family systems. Everyone experiences trauma differently, but the
      impact of trauma can be significant and long-lasting and affects a person’s mental, emotional, and
      physical health.
    </p>
    <p>
      It is understood that some past experiences have led this individual with FASD to experience trauma.
      Trauma informed therapy can be helpful for you to navigate the impact that trauma can have on your overall
      mental wellness.   Experiencing trauma can have long term effects including impacts to mental health, social
      functioning, academic achievement, self-regulation, and caregiver relationships.
    </p>
  </div>;

export default Blurb8_8E;
