import React from 'react';

const Blurb2_2D = () =>
  <div>
    <p>
      It will be important to monitor and follow-up with any primary health care providers (e.g., doctor,
      nurse practitioner) as you grow through developmental stages and reach major milestones.
    </p>
  </div>;

export default Blurb2_2D;
