import React from 'react';

const Blurb3_10A = () =>
  <div>
    <p>
      Healthy attachment and relationships are an important ingredient for supporting intervention efforts for
      individuals with FASD across the lifespan. Attachment and relationships are informed by skills, abilities,
      and experiences across many different areas of life. Supporting families to navigate stress and areas of
      need, listening to caregivers, and engaging family, are all important in leveraging and maintaining stability
      while fostering your child’s strengths and supporting their needs. Families and caregivers are excellent
      sources of information related to strengths and resiliency factors that may be incorporated in success
      planning with your child.
    </p>
    <p>
      To help your child’s attachment and relationships, continuous and proactive support, coupled with
      ongoing monitoring, will be important. Establishing an understanding of the role that family and
      natural networks of support will play in your child’s life as they get older and transition to
      elementary school age and beyond will also be helpful. One important question for families to
      consider is: What are some key goals for continued interactions or support? A conversation
      around this question will encourage familial cohesion and support that is best suited for
      your child and their family.
    </p>
  </div>;

export default Blurb3_10A;
