export const config = {
  app: {
    environment: process.env.REACT_APP_ENVIRONMENT || 'development',
    version: process.env.REACT_APP_VERSION || new Date().toISOString()
  },
  google: {
    analytics: {
      measurementID: process.env.REACT_APP_GA_MEASUREMENT_ID || 'ga-measurement-id'
    }
  },
  redcap: {
    proxy: {
      url: process.env.REACT_APP_REDCAP_PROXY_URL || 'https://compass-fasd.pages.dev/proxy/redcap'
    },
    api: {
      url: process.env.REACT_APP_REDCAP_API_URL || 'https://redcap.ualberta.ca/api/',
      token: process.env.REACT_APP_REDCAP_API_TOKEN || 'redcap-api-token'
    }
  },
  rollbar: {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || 'rollbar-access-token'
  }
};
