import React from 'react';

const Blurb10_5 = () =>
  <div>
    <p>
      Assessment findings suggested that you/your child may experience challenges living independently. Living
      independently can be seen as a spectrum, with individuals at one end requiring directive and assisted
      living environments, and those at the other end needing less or no support to live independently. Different
      housing programs offer structured support based on the needs of the individual. Accessing appropriate housing
      programs FASD-informed independent living supports can help to ensure needs are met. Accepting supports for
      independently living can support success and healthy outcomes.
    </p>
    <p>
      Achieving independence is a long developmental process and looks different for everyone. For some,
      independence may include living with others, receiving financial support, or finding supportive housing
      programs. Individuals with FASD may require increased support to achieve success in this area.
    </p>
  </div>;

export default Blurb10_5;
