import React from 'react';

const Blurb2_4 = () =>
  <div>
    <p>
      It is recommended that you/your child be referred for additional medical follow-up to gain more
      insight and support for physical wellbeing. Sharing information from this report can show how
      individuals with FASD may have unique physical health needs that start early or develop differently.
      This can help with planning and monitoring their physical health needs proactively.
    </p>
  </div>;

export default Blurb2_4;
