import React from 'react';

const Blurb5_14C = () =>
  <div>
    <p>
      Your teen has been referred for specialized support related to occupational therapy. Occupational
      therapists can work with your teen, their caregiver(s), and family to identify and address functioning
      with day-to-day activities and support their success in school, home, and community contexts.
    </p>
  </div>;

export default Blurb5_14C;
