import TIMEOUT from '../constants/timeout';

const values = {
  welcome1: 'Welcome to COMPASS! Please enter information related to your client as you have done in the past using DataForm and all DataForm variables that are entered will be sent to the Database (i.e., RedCap). Age and ID are mandatory fields for the purpose of COMPASS, otherwise the remaining fields are not mandatory as per DataForm protocol.',
  welcome2: `If you step away from your computer a timer clock will appear, and the data will be wiped after ${TIMEOUT.INTERVAL / 60} minutes. Additional instructions will appear on your screen and prompt you to decide whether to enter additional information into an open text box that is not captured by the Database variables. For example, you may choose to enter client-specific information or resources /services that are unique to your area that may be helpful for your client.`,
  age: 'Please select from the following age group that best defines your client. The universal age group is intended for unique circumstances, and general information for each of the domains within the report will be generated that is not specific to age.',
  my_unique_brain: 'This text box is optional. You may choose to enter information here that is client-specific and unique to their assessment results.',
  tear_offs: 'Please select the tear-off sheets that you would like to be generated for your client. Please note that all tear-offs can allow you to enter the community or client-specific information in an open text box. The open-text boxes are optional. If you choose not to enter text, the tear-off sheet will still be generated if you check the box.',
  diagnosis: 'Please select the diagnostic category that best applies to your client. It is important to note that only the first page (i.e., my unique needs/brain) will be the only page that populates for the no-diagnosis category. There is the option to select the no-diagnosis tear-off if you would like additional information to be provided to your client. This tear-off also has the option for an open textbox.',
  sliding_scale: 'Below are six sliding scales that cover different domains or skills of functioning that were assessed by your team. A graph will populate, highlighting your client’s unique areas of strengths and needs according to these domains. The domains are defined as follows, with the guidance of tests and/or additional information that you can use to inform your ratings on the sliding scales. We have also included a guide for rating your clients’ strengths versus areas of need. The guide includes suggested descriptor ranges based on the sliding scale marker values. These are suggestions and clinical judgment can be used to further inform your ratings on the scale.',
  verbal_and_language: {
    text: 'Skills related to verbal reasoning, verbal concept formation, and acquired verbal knowledge or language use.',
    suggestedSource: 'VCI, Expressive/Receptive abilities, Adaptive Communication (ABAS, Vineland)'
  },
  visual: {
    text: 'Skills related to visual-spatial processing, visual-motor integration, and visual problem-solving.',
    suggestedSource: 'VSI, PRI'
  },
  attentional_control: {
    text: 'Skills related to maintaining concentration and shifting between tasks.',
    suggestedSource: 'WMI, DKEFS, NEPSY'
  },
  processing_speed: {
    text: 'Skills related to one’s ability to process cognitive information quickly and efficiently.',
    suggestedSource: 'PSI'
  },
  adaptive_skills: {
    text: ' The practical and conceptual skills associated with',
    suggestedSource: 'ABAS, Vineland'
  },
  relational_skills: {
    text: 'The adaptive skills required to navigate relationships and environments to form connectedness with others.',
    suggestedSource: 'ABAS (Social); Vineland (Social); Information reported during intake.'
  },
  community_tearoff: 'You may find it helpful to use the open text box below to provide your client and/or their caregiver(s) with more information about local community organizations, services, activities, or supports that may provide helpful opportunities to foster their community engagement. This can also provide you with an opportunity to collaboratively plan for success in this area and identify FASD-informed programs and services.',
  education_tearoff: 'You may find it helpful to use the open text box below to provide your client and/or caregivers and educators additional information regarding education opportunities or particular areas of need that were identified within the assessment.',
  my_unique_needs: 'This open text box may be used to communicate specific areas of need based on the assessment results to other clinicians who may follow-up with your client.',
  my_unique_brain2: 'For this open text box, you may choose to include information related to the brain domains and the assessment results. The information could be helpful for other clinicians who may follow-up with your client.',
  adult_adolescent_tearoff: 'You may find it helpful to use the open text box below to provide your client with useful information regarding their diagnosis and unique areas of strength and need. For adolescent or adult clients it may be helpful to provide additional information on what a diagnosis of FASD means and which supports will be helpful moving forward.',
  justice_tearoff: 'You may find it helpful to include justice specific information for your clients in the open text box below.',
  no_diagnosis_tearoff: 'You may find it helpful to use this open text box to provide other recommendations or resources for your client who has not received an FASD diagnosis.'
};

export default values;
