import React from 'react';

const Blurb5_15E = () =>
  <div>
    You/your child has been referred for specialized support related to their behaviour. Behavioural interventions
    can focus on many areas of functioning, including self-regulation, neurocognition, and adaptive functioning.
    Programming that uses positive behavioural techniques and provides skill building while also helping to
    reframe behaviour for caregiver(s) and supportive others will be useful. It will be important to seek out
    programming that is FASD-informed.
  </div>;

export default Blurb5_15E;
