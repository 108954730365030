import React from 'react';
import SectionTitle from '../components/SectionTitle';
import Card from '../components/Card';
import '../../../../assets/stylesheets/pages/report.scss';
import Variables from '../components/Variables';
import {useSelector} from 'react-redux';
import FORMS from '../../../../redux/constants';
import Blurb7_1A from '../../../blurbs/7_Interdependence/Blurb7_1A';
import Blurb7_1B from '../../../blurbs/7_Interdependence/Blurb7_1B';
import Blurb7_1C from '../../../blurbs/7_Interdependence/Blurb7_1C';
import Blurb7_1D from '../../../blurbs/7_Interdependence/Blurb7_1D';
import Blurb7_1E from '../../../blurbs/7_Interdependence/Blurb7_1E';
import Blurb7_2A from '../../../blurbs/7_Interdependence/Blurb7_2A';
import Blurb7_2B from '../../../blurbs/7_Interdependence/Blurb7_2B';
import Blurb7_2C from '../../../blurbs/7_Interdependence/Blurb7_2C';
import Blurb7_2D from '../../../blurbs/7_Interdependence/Blurb7_2D';
import Blurb7_2E from '../../../blurbs/7_Interdependence/Blurb7_2E';
import Blurb7_21A from '../../../blurbs/7_Interdependence/Blurb7_21A';
import Blurb7_21B from '../../../blurbs/7_Interdependence/Blurb7_21B';
import Blurb7_21C from '../../../blurbs/7_Interdependence/Blurb7_21C';
import Blurb7_21D from '../../../blurbs/7_Interdependence/Blurb7_21D';
import Blurb7_21E from '../../../blurbs/7_Interdependence/Blurb7_21E';
import Blurb7_22A from '../../../blurbs/7_Interdependence/Blurb7_22A';
import Blurb7_22B from '../../../blurbs/7_Interdependence/Blurb7_22B';
import Blurb7_22C from '../../../blurbs/7_Interdependence/Blurb7_22C';
import Blurb7_22D from '../../../blurbs/7_Interdependence/Blurb7_22D';
import Blurb7_22E from '../../../blurbs/7_Interdependence/Blurb7_22E';
import Blurb7_23A from '../../../blurbs/7_Interdependence/Blurb7_23A';
import Blurb7_23C from '../../../blurbs/7_Interdependence/Blurb7_23C';
import Blurb7_23B from '../../../blurbs/7_Interdependence/Blurb7_23B';
import Blurb7_23D from '../../../blurbs/7_Interdependence/Blurb7_23D';
import Blurb7_23E from '../../../blurbs/7_Interdependence/Blurb7_23E';
import Blurb7_24A from '../../../blurbs/7_Interdependence/Blurb7_24A';
import Blurb7_24B from '../../../blurbs/7_Interdependence/Blurb7_24B';
import Blurb7_24C from '../../../blurbs/7_Interdependence/Blurb7_24C';
import Blurb7_24D from '../../../blurbs/7_Interdependence/Blurb7_24D';
import Blurb7_24E from '../../../blurbs/7_Interdependence/Blurb7_24E';
import Blurb7_25 from '../../../blurbs/7_Interdependence/Blurb7_25';
import Blurb7_251 from '../../../blurbs/7_Interdependence/Blurb7_251';
import Blurb7_252 from '../../../blurbs/7_Interdependence/Blurb7_252';
import Blurb7_254 from '../../../blurbs/7_Interdependence/Blurb7_254';
import Blurb7_253 from '../../../blurbs/7_Interdependence/Blurb7_253';
import Blurb7_3A from '../../../blurbs/7_Interdependence/Blurb7_3A';
import Blurb7_3B from '../../../blurbs/7_Interdependence/Blurb7_3B';
import Blurb7_3C from '../../../blurbs/7_Interdependence/Blurb7_3C';
import Blurb7_3D from '../../../blurbs/7_Interdependence/Blurb7_3D';
import Blurb7_3E from '../../../blurbs/7_Interdependence/Blurb7_3E';

const Independence = () => {
  const {diagnosis} = useSelector(state => state.forms[FORMS.DIAGNOSIS.id]);
  const {adaptive_brain} = useSelector(state => state.forms[FORMS.BRAIN_DOMAIN_ASSESSMENT.id]);
  const {coaching, safety, foodbank, guardianship, personaldirective, guidance, othersubstitute, poa} = useSelector(state => state.forms[FORMS.RECOMMENDATIONS.id]);
  const {age} = useSelector(state => state.forms[FORMS.UNIQUE_BRAIN.id]);
  const variables = {diagnosis, age, adaptive_brain, coaching, safety, foodbank, guardianship, personaldirective, guidance, othersubstitute, poa};

  return <section>
    <SectionTitle>Interdependence</SectionTitle>
    <Variables variableObject={variables}/>
    <Card
      title='Things To Know'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_1A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_1B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_1C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_1D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_1E/>}
      </div>
    </Card>
    <Card
      title='Things To Do Now'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_2A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_2B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_2C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_2D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_2E/>}
      </div>
      <div>
        {adaptive_brain === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_21A/>}
        {adaptive_brain === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_21B/>}
        {adaptive_brain === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_21C/>}
        {adaptive_brain === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_21D/>}
        {adaptive_brain === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_21E/>}
      </div>
      <div>
        {coaching === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_22A/>}
        {coaching === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_22B/>}
        {coaching === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_22C/>}
        {coaching === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_22D/>}
        {coaching === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_22E/>}
      </div>
      <div>
        {safety === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_23A/>}
        {safety === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_23B/>}
        {safety === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_23C/>}
        {safety === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_23D/>}
        {safety === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_23E/>}
      </div>
      <div>
        {foodbank === '1' && age === '1' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_24A/>}
        {foodbank === '1' && age === '2' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_24B/>}
        {foodbank === '1' && age === '3' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_24C/>}
        {foodbank === '1' && age === '4' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_24D/>}
        {foodbank === '1' && age === '5' &&
          (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_24E/>}
      </div>
      <div>
        {guardianship === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_25/>}
        {personaldirective === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_251/>}
        {guidance === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_252/>}
        {othersubstitute === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_253/>}
        {poa === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_254/>}
      </div>
    </Card>
    <Card
      title='Things To Plan For'>
      <div>
        {age === '1' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_3A/>}
        {age === '2' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_3B/>}
        {age === '3' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_3C/>}
        {age === '4' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_3D/>}
        {age === '5' && (diagnosis === '0' || diagnosis === '1' || diagnosis === '2') && <Blurb7_3E/>}
      </div>
    </Card>
  </section>;
};

export default Independence;
