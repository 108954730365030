import React from 'react';

const Blurb5_4A = () =>
  <div>
    <p>
      Your child’s language and communication skills have been assessed and identified as an area of need.
      This means that your child may struggle to express themselves effectively and/or may have difficulty
      consistently understanding others – especially when verbal exchanges are fast paced, which is typical
      within social interactions and classroom environments. Sometimes individuals with FASD can effectively
      mask these challenges by repeating familiar language, controlling conversations, and simply agreeing
      with others and following along with peers – particularly as they get older. They may also simply feel
      overwhelmed by verbal communication and quickly dysregulate, become angry, or avoid others.
    </p>
    <p>
      We can support your child in building these basic skills by providing them with support from a Speech
      Language Pathologist who is familiar with FASD based-brain differences. Additionally, ensuring supports
      are aware of these differences may create space for acceptance and support, as well as identification
      of alternative communication and learning strategies.
    </p>
  </div>;

export default Blurb5_4A;
