import React from 'react';
import TIMEOUT from '../../constants/timeout';
import PropTypes from 'prop-types';

const Timeout = ({remaining, isReport}) => {
  if (remaining <= 0) {
    return null;
  }

  else if (isReport && remaining <= TIMEOUT.DISPLAY_AFTER) {
    return <div className='callout alert hide-for-print'>
      Warning: The PDF report should be downloaded or
      printed or it will be lost in {remaining} seconds.
    </div>;
  }

  return (
    remaining <= TIMEOUT.DISPLAY_AFTER && <div>
      <div className='callout alert'>
        Warning: All form data will be deleted due to inactivity in {remaining} seconds.
      </div>
    </div>
  );
};

export default Timeout;

Timeout.defaultValues = {
  isReport: false
};

Timeout.propTypes = {
  isReport: PropTypes.bool,
  remaining: PropTypes.number.isRequired
};
