import React from 'react';

const Blurb8_4D = () =>
  <div>
    <p>
      Your assessment team recommended accessing mental health services. Mental health services can include
      individualized therapy, group therapy, medication, and/or family intervention. It will be important
      for you to work with your support networks to find a mental health care provider who is registered
      with local professional bodies and is knowledgeable in FASD informed interventions that will work
      for you based on your strengths and needs.
    </p>
  </div>;

export default Blurb8_4D;
