import React from 'react';

const Blurb6_1A = () =>
  <div>
    <p>
      Identity is an important aspect of our self-concept that distinguishes a person’s unique qualities,
      values, and goals. Identity develops over the lifespan with early foundations laid in childhood.
      Identity is informed and shaped by many factors, including education/career goals, disabilities,
      spiritual experiences, and self-expression including gender and sexual identities. This can result
      in a broad spectrum of potential identity strengths and needs that are often woven together in
      unique ways for each person.
    </p>
    <p>
      It is important to support early development of identity because it plays a role in many aspects
      of our life, including social and adaptive functioning. When you talk to your child about FASD
      telling a story that is balanced to reflect the presence of both challenges and strengths, demonstrates
      that they are accepted and valued for who they are. By highlighting the value of your child as an
      individual and remaining aware of stigmatizing or demeaning views about FASD, you may help to prevent
      them from developing negative self-perceptions about their diagnosis of FASD and help them to appreciate
      their inherent value and potential for contributing meaningfully to their community.
    </p>
  </div>;

export default Blurb6_1A;
